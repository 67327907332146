<template>
  <div class="solution">
	  <div class="header wow animate__slideInLeft">
		  <img src="../../assets/image/solution/bg_solution_banner.png" alt="">
	  </div>
	  <div class="solution_head wow animate__slideInUp">
	    <!-- <div class="dot"></div> -->
	    <h2 class="title">解决方案</h2>
	    <p class="modifier">SOLUTION</p>
	  </div>
	   <!-- 文保 -->
	   <div class="culturalRelics wow animate__slideInUp" @click="skipPage('/culturalRelics')">
		   <div class="content">
			   <div class="text wow animate__bounceIn">
			   		<div>文物安全监督解决方案</div>
			   		<p>文保单位安全综合管理云平台采用基于云端的移动化办公管理软件，利用互联网、云计算、物联网等技术，实现安全管理云平台、PC端、移动端（小程序、公众号）已经各安全子系统之间的互联互通，打造智慧化的文保单位安全管理方式。文物安全监管系统由文物安全监管云平台、智慧文保小程序组成。</p>
			   </div>
			   <div class="image wow animate__slideInRight">
			   		<img src="../../assets/image/solution/bg_wenwuImg.png" alt="">
			   </div>
			   
		   </div>
	   </div>
	   <!-- 消防 -->
	   <div class="fireProtection wow animate__slideInUp" @click="skipPage('/fireProtection')">
	   		<div class="content">
			   <div class="image wow animate__slideInLeft">
					<img src="../../assets/image/solution/bg_fire.png" alt="">
			   </div>
			   <div class="text wow animate__bounceIn">
					<div>智慧消防解决方案</div>
					<p>智慧消防安全管理平台是针对智慧消防领域，借助先进的移动互联网、物联网、传感器、大数据、云计算、人工智能等技术，充分利用现有安防系统，基于运营商成熟网络，服务于政府主管部门、一线工作人员。社会单位以及社会群众。</p>
			   </div>
	   		</div>
	   </div>
	   <!-- 基站 -->
	   <div class="baseStation wow animate__slideInUp" @click="skipPage('/baseStation')">
		   <div class="content " >
			   <div class="text wow animate__bounceIn">
					<div>通讯机房、基站节能解决方案</div>
					<p>通信系统基站、机房的能耗问题已经成为通信运营商发展的难题，基站、机房的节能减排工作是目前关注焦点。本方案将通过对基站机构进行优化，使用新技术降低基站的能源消耗，促进通信系统可持续发展。</p>
			   </div>
			   <div class="image wow animate__slideInRight">
					<img src="../../assets/image/solution/bg_jifnagImage.png" alt="">
			   </div>
					   
		   </div>
	   </div>
	   <!-- 养老 -->
	   <div class="elderlyCare wow animate__slideInUp" @click="skipPage('/elderlyCare')">
	   		<div class="content">
	   		   <div class="image wow animate__slideInLeft">
	   				<img src="../../assets/image/solution/bg_yanglao.png" alt="">
	   		   </div>
	   		   <div class="text wow animate__bounceIn">
	   				<div>智慧养老解决方案</div>
	   				<p>智慧消防安全管理平台是针对智慧消防领域，借助先进的移动互联网、物联网、传感器、大数据、云计算、人工智能等技术，充分利用现有安防系统，基于运营商成熟网络，服务于政府主管部门、一线工作人员。社会单位以及社会群众。</p>
	   		   </div>
	   		</div>   
	   </div>

  </div>
</template>

<script>

import { WOW } from "wowjs"

export default {
  components: {
  },
  data(){
    return {
      wow: null,
      activeProductNav: "安防产品",
    }
  },
  created(){
    
  },
  mounted(){
    this.wow = new WOW({
      boxClass: 'wow', // 添加动画的元素的标识
      animateClass: 'animate__animated', // 动画时长
      offset: 0, // 与可视区域底部的距离显示
      mobile: true, // 移动端是否可用
      live: true // 对后面添加的新元素是否有效
    }).init();
  },
  methods: {
	  // 跳转页面
	  skipPage(path){
	    this.$router.push({path:path})
	  },
  }
}
</script>

<style lang="scss">

.solution {
	margin-bottom: 60px;
	.header{
		width: 1903px;
		img{
			width: 100%;
		}
	}
  .solution_head {
    .title {
      margin: 70px 0 15px 0;
      font-size: 40px;
      color: #333;
  		  
    }
    .modifier {
      font-size: 24px;
      color: #666;
    }
  }
  .culturalRelics,.baseStation{
	  cursor: pointer;
	  height: 480px;
	  margin-top: 70px;
	  background: url('../../assets/image/solution/bg_wenwu.png') repeat fixed center;
	  .content{
		  margin: 0 auto;
		  padding-top: 90px;
		  width: 1200px;
		  display: flex;
		  .text{
			  display: block;
			  margin-top: 14px;
			  margin-right: 20px;
			  width: 622px;
			  text-align: left;
			  color: #FFFFFF;
			  div{
				  margin-bottom: 27px;
				  font-size: 36px;
				  font-weight: 600;
			  }
		  }
	  }
  }
  .fireProtection,.elderlyCare{
	  cursor: pointer;
	  margin-top: 110px;
	  .content{
		  margin: 0 auto;
		  width: 1200px;
		  display: flex;
		  .text{
			  margin-top: 14px;
			  margin-left: 20px;
			  width: 622px;
			  text-align: left;
			  div{
				  margin-bottom: 27px;
				  font-size: 36px;
				  color: #333;
				  font-weight: 600;
			  }
		  }
			  
	  }
  }
  .baseStation{
	  background: url('../../assets/image/solution/bg_jifnag.png') repeat fixed center;
  }
 }
 @media only screen and (max-width: 1200px) {
 	.solution{
 		width: 1200px;
 		.header{
 			width: 1200px;
 		}
 	}
 }
</style>