<template>
  <div class="elderlyCare">
	  <div class="header wow animate__slideInLeft">
	  	<img src="../../assets/image/solution/elderlyCare/bg_yanglao_banner.png" alt="">
		<div @click="goBack">返回上一页</div>
	  </div>
    <div class="elderlyCare wow animate__slideInUp">
      <div class="elderlyCareTop" id="elderlyCareTop">
		  <div class="elderlyCare_body wow animate__slideInUp">
		    <div class="nav">
		      <div class="nav_item" :class="activeProductNav == item.name ? 'activeProductNav' : ''" v-for="item in enterpriseQualificationNavList" :key="item.name" @click="isProductNav(item.name)">
		       <a @click.prevent="anchor(item.position)" class="elderlyCareItemName"> {{item.name}}</a>
		      </div>
		    </div>
		  </div>
	  </div>
	  <div class="elderlyCareBottom">
		  <!-- 平台简介 -->
		  <div class="platformIntroduction">
			  <a id="PLATFORM" style="position: relative;top: -80px;"></a>
			  <div class="elderlyCare_head wow animate__slideInUp">
			    <h2 class="title">平台简介</h2>
			    <p class="modifier">PLATFORM INTRODUCTION</p>
			  </div>
			  <div class="platformIntroductionContent">
				  <p>
					  智慧养老服务平台提供社区养老、居家养老、机构养老、民政监管、智慧康养、医养结合等“互联网+养老”智慧应用。
					  平台以数据为核心，通过数据共享使所有参与方全面掌握老人情况，形成规范且连续的养老大数据，通过服务机构、社区、养老机构、医疗资源与政府监管平台的有机结合，服务区域养老服务生态发展。
				  </p>
				 <!-- <div>
					  <img src="../../assets/image/solution/elderlyCare/platformIntroduction.png" alt="">
				  </div> -->
			  </div>
			  <!-- 平台架构 -->
			  <div class="networkArchitecture">
			  			   <a id="NETWORK" style="position: relative;top: -80px;"></a>
			  			  <div class="elderlyCare_head wow animate__slideInUp">
			  				<h2 class="title" style="padding-top: 26px;">平台架构</h2>
			  				<p class="modifier">INTERNET INFIRMARY</p>
			  			  </div>
			  			  <div class="networkArchitectureContent">
			  				  <div >
			  					  <img src="../../assets/image/solution/elderlyCare/yanglaoJiagou.png" alt="">
			  				  </div>
			  			  </div>
			  </div>
		  </div>
		  <!-- 医养结合 -->
		  <div class="platformArchitecture">
			   <a id="ARCHITECTURE" style="position: relative;top: -80px;"></a>
			  <div class="elderlyCare_head wow animate__slideInUp">
				<h2 class="title" style="padding-top: 26px;">医养结合——互联网医务室</h2>
				<p class="modifier">PLATFORM ARCHITECTURE</p>
			  </div>
			  <div class="platformArchitectureContent">
				  <div >
					  <img src="../../assets/image/solution/elderlyCare/bg_yanglao_internet.png" alt="">
				  </div>
			  </div>
		  </div>
		  <!-- 平台功能 -->
		  <div class="platformFunction">
			  <a id="FUNCTION" style="position: relative;top: -80px;"></a>
			  <div class="elderlyCare_head wow animate__slideInUp">
				<h2 class="title" style="padding-top: 26px;">平台功能</h2>
				<p class="modifier">PLATFORM FUNCTION</p>
			  </div>
			  <div class="platformFunctionContent">
				  <div >
				  	 <img src="../../assets/image/solution/elderlyCare/yanglaoInternet.png" alt="">
					 <div>政府监管功能</div>
				  	 <img src="../../assets/image/solution/elderlyCare/yanglaoJigou.png" alt="">
					 <div>机构养老功能</div>
				  	 <img src="../../assets/image/solution/elderlyCare/yanglaoJujia.png" alt="">
					 <div>社区居家养老功能</div>
				  </div>
			  </div>
		  </div>
	  </div>
    </div>

  </div>
</template>

<script>
import { WOW } from "wowjs"
export default {
  data(){
    return {
      wow: null,
      activeProductNav: "平台简介",
      enterpriseQualificationNavList: [
		{
		  name: '平台简介',
		  position:'PLATFORM'
		},
		{
		  name: '平台架构',
		  position:'NETWORK'
		},
		{
		  name:'医养结合',
		  position:'ARCHITECTURE',
		},
        {
          name: '平台功能',
		  position:'FUNCTION'
        }
      ]
    }
  },
  created(){
    
  },
  mounted(){
    this.wow = new WOW({
      boxClass: 'wow', // 添加动画的元素的标识
      animateClass: 'animate__animated', // 动画时长
      offset: 0, // 与可视区域底部的距离显示
      mobile: true, // 移动端是否可用
      live: true // 对后面添加的新元素是否有效
    }).init();
	window.addEventListener('scroll', () => {
	  // 页面滚动停止100毫秒后才会执行下面的函数。
	  this.scrollToTop();
	} , true);
  },
  destroy(){
	  window.removeEventListener('scroll', this.scrollToTop)
  },
  methods: {
    isProductNav(name){
      this.activeProductNav = name
    },
	 /*锚链接跳转*/
	  anchor(anchorName) {
	    /*找到锚点*/
	    let anchorElement = document.getElementById(anchorName);
	    /*如果对应id的锚点存在，就跳转到锚点*/
	    if(anchorElement) {
	      anchorElement.scrollIntoView();
	    }
	  },
	  goBack(){
	  		  this.$router.back()
	  },
	  scrollToTop(){
			  if(!document.getElementById('elderlyCareTop')){
			  				  return
			  }
	  		  var offsetTop = document.getElementById('elderlyCareTop').getBoundingClientRect().top;
	  		  // var scrollTop = document.getElementById('culturalRelics').scrollTop;
	  		  if(window.pageYOffset>=492){
	  			  document.getElementById('elderlyCareTop').style.position = 'fixed'
	  			  document.getElementById('elderlyCareTop').style.top = '67px'
	  			  document.getElementById('elderlyCareTop').style.backgroundColor = '#fff'
				  document.getElementById('PLATFORM').style.top = '-140px'
				  document.getElementById('ARCHITECTURE').style.top = '-140px'
				  document.getElementById('FUNCTION').style.top = '-140px'
	  		  }else{
	  			  document.getElementById('elderlyCareTop').style.position = 'static'
	  			  // document.getElementById('culturalRelicsTop').style.top = '75px'
				  document.getElementById('PLATFORM').style.top = '-80px'
				  document.getElementById('ARCHITECTURE').style.top = '-80px'
				  document.getElementById('FUNCTION').style.top = '-80px'
	  		  }
	  		  // console.log(offsetTop,window.pageYOffset)
	  }
  }
}
</script>

<style lang="scss">
.elderlyCare {
.header{
		width: 1903px;
		img{
			width: 100%;
		}
		position: relative;
		div{
			cursor: pointer;
			position: absolute;
			bottom: 4px;
			left: 360px;
			width: 120px;
			height: 40px;
			background: #4495FF;
			line-height: 40px;
			color: #fff;
			font-size: 16px;
		}
	}
  margin: 0 auto;
  .elderlyCareTop{
	  width: 100%;
	  border-bottom: 1px solid #C2C2C2;
	  .elderlyCare_body {
		  margin: 0 auto;
		  width: 1200px;
		  padding: 0 289px;
	    .nav {
	      display: flex;
	      justify-content: space-between;
	      flex-wrap: wrap;
	      font-size: 22px;
	      color: #333333;
	      .nav_item {
			.elderlyCareItemName{
				display: inline-block;
				text-decoration: none;
				margin: 22px 0 22px 0;
				color: #333333;
			}
	        cursor: pointer;
	        &:hover {
	          color: #0155C4;
	          &::after {
	            width: 100%;
	          }
	        }
	      }
	      .nav_item::after {
	        content: '';
	        display: block;
	        margin: -2px auto 0;
	        height: 2px;
	        width: 0%;
	        background-color: #0155C4;
	        border-radius: 2px;
	        transition: all .3s;
	      }
	      .activeProductNav {
			  .elderlyCareItemName{
			  	 color: #0155C4;
			  }
	        color: #0155C4;
	        &::after {
	          width: 100%;
	        }
	      }
	    }
	  
	  }
  }

  .elderlyCareBottom{
	  padding-bottom: 60px;
	  background-color: #F5F6F6;
	  .elderlyCare_head {
		  margin-bottom: 45px;
	    .title {
	      margin: 0 0 15px 0;
	      font-size: 40px;
	      color: #333;
	  	  padding-top: 70px;  
	    }
	    .modifier {
	      font-size: 24px;
	      color: #666;
	    }
	  }
	 .platformIntroduction{
	 		  background: url("../../assets/image/solution/elderlyCare/bg_yanglao_platform.png") no-repeat;
	 		  padding-bottom:70px;
	 		  .platformIntroductionContent{
	 			  width: 1200px;
	 			  margin: 0 auto;
	 			  text-align:left;
	 			  P{
	 				  color: #191F25;
	 				  font-size: 20px;
	 				  line-height: 28px;
	 				  margin-bottom: 31px;
	 				  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
	 				  letter-spacing: 1px;
	 			  }
	 		  }
			  .networkArchitecture{
			  		  padding-bottom:70px;
			  }
	 }
	 .platformArchitecture{
		 padding-bottom:70px;
	 }
	  .platformFunction{
		 .platformFunctionContent{
		 		width: 1200px;
		 		margin: 0 auto;  
				text-align:left;
				>div{
					div{
						text-align: center;
						font-size: 22px;
						margin: 23px 0 55px 0;
					}
				}
		 } 
	  }
	  
	  
  }
}
 @media only screen and (max-width: 1200px) {
 	.elderlyCare{
 		width: 1200px;
 		.header{
 			width: 1200px;
 		}
 	}
 }
</style>