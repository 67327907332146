<template>
  <div class="newsDetail">
	  <div class="header wow animate__slideInLeft">
	  	<img src="../../assets/image/newsDynamic/newsDetail/bg_news_banner.png" alt="" >
		<div @click="goBack">返回上一页</div>
	  </div>
	<div class="newContent">
		<div class="newBox">
			<div class="title">{{news.title}}</div>
			<div class="time">
				<span>发布时间：{{news.date}}</span>
				<span>发布时间：浏览次数：41</span>
			</div>
			<div class="article">
				<div class="image" v-if="news.contentImg">
					<img :src="news.contentImg" alt="">
				</div>
				<div v-html="news.contenDetail">
					
				</div>
			</div>
			<div class="newsBottom">
				<!-- <p>CMMI全称是Capability Maturity Model Integration, 即软件能力成熟度模型集成模型，是由美国国防部与卡内基-梅隆大学和美国国防工业协会共同开发和研制的。CMMI是一套融合多学科的、可扩充的产品集合，其研制的初步动机是为了利用两个或多个单一学科的模型实现一个组织的集成化过程改进。
				</p> -->
			</div>
		</div>
	</div>
  </div>
</template>

<script>
import { WOW } from "wowjs"

export default {
  components: {
  },
  data(){
    return {
      wow: null,
      news:{},
    }
  },
  created(){
    
  },
  mounted(){
    this.wow = new WOW({
      boxClass: 'wow', // 添加动画的元素的标识
      animateClass: 'animate__animated', // 动画时长
      offset: 0, // 与可视区域底部的距离显示
      mobile: true, // 移动端是否可用
      live: true // 对后面添加的新元素是否有效
    }).init();
	this.news = this.$route.params
  },
  methods: {
	  goBack(){
	  		  this.$router.back()
	  },
  }
}
</script>

<style lang="scss">
@media only screen and (max-width: 1200px) {
  .w {
    width: 100%;
    margin: 0 auto;
  }
  .scheme .depth {
    width: 100% !important;
    .scheme_content {
      justify-content: center !important;
      .scheme_item {
        // width: 98% !important;
      }
    }
  }
  .product .product_body {
    .nav {
      .nav_item {
        transition: all .3s; 
        font-size: 12px !important;
      }
    }
    .product_content {
      // justify-content: center !important;
      .product_item {
        margin-bottom: 20px;
      }
    }
  }
}
@media only screen and (max-width: 780px) {
  .partner .partner_content {
    justify-content: center !important;
  }
  .partner .partner_content .partner_item {
    // width: 98% !important;
    margin-bottom: 20px;
  }
  .product .product_body {
    .product_content {
      justify-content: center !important;
      .product_item {
        width: 90% !important;
        margin-bottom: 20px;
      }
    }
  }
}
.newsDetail {
  margin: 0 auto;
  .header{
  		width: 1903px;
  		img{
  			width: 100%;
  		}
		position: relative;
		div{
			cursor: pointer;
			position: absolute;
			bottom: 4px;
			left: 360px;
			width: 120px;
			height: 40px;
			background: #4495FF;
			line-height: 40px;
			color: #fff;
			font-size: 16px;
		}
  	}
	.newsDynamic_head {
	  margin-bottom: 42px;
	  .title {
	    margin: 0 0 15px 0;
	    font-size: 40px;
	    color: #333;
		  padding-top: 70px;  
	  }
	  .modifier {
	    font-size: 24px;
	    color: #666;
	  }
	}
	.newContent{
		margin-top: 68px;
		text-align: left;
		.newBox{
			width: 1200px;
			margin: 0 auto;
			.title{
				text-align: center;
				font-size: 30px;
				font-weight: 600;
				color: #333333;
			}
			.time{
				margin: 22px 0 42px 0;
				color: #999999;
				line-height: 28px;
				text-align: center;
				line-height: 28px;
				font-size: 16px;
				>span{
					&:first-child{
						margin-right: 80px;
					}
				}
			}
			.article{
				p{
					text-indent: 2em;
					line-height: 32px;
					margin-bottom: 30px;
				}
			}
			.image{
				text-align: center;
				margin: 57px 0;
				img{
				  width: 100%;
				}
			}
			.newsBottom{
				margin-bottom: 60px;
				p{
					text-indent: 2em;
					line-height: 32px;
				}
			}
		}
		
	}
}
</style>