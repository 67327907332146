<template>
  <div id="app">
	<!-- 导航 -->
	<top/>
    <router-view/>
	<!-- 底部 -->
	<footerEl/>
  </div>
</template>

<script>

	import top from "@/views/components/top.vue"
	import footerEl from "@/views/components/footer.vue"
	export default {
		data(){
			return {
				
			}
		},
		components:{
			top,
			footerEl
		},
		mounted () {
		    this.rem()
		    // 动态监听屏幕尺寸变化从而修改font-size
		    window.onresize = () => {
		      this.rem()
		    }
		  },
		methods: {
			// 动态修改font-size
		    rem () {
		      // const docEl = document.documentElement
		      // window.rem = docEl.getBoundingClientRect().width / 24
		      // docEl.style.fontSize = window.rem + 'px'
		    }
		}
	}
</script>
<style lang="scss">
@import "./assets/style/index.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  scrollbar-color: rgba(144, 147, 153, 0.3) transparent; /* 滑块颜色  滚动条背景颜色 */
  scrollbar-width: thin; /* 滚动条宽度有三种：thin、auto、none */
  &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
  }
  // 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
  &::-webkit-scrollbar-button {
      display: none;
  }
  // 滚动条的轨道（里面装有Thumb）
  &::-webkit-scrollbar-track {
      background: transparent;
  }
  // 滚动条的轨道（里面装有Thumb）
  &::-webkit-scrollbar-track-piece {
      background-color: transparent;
  }
  // 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
  &::-webkit-scrollbar-thumb {
      background: rgba(144, 147, 153, 0.3);
      cursor: pointer;
      border-radius: 4px;
  }
  // 边角，即两个滚动条的交汇处
  &::-webkit-scrollbar-corner {
      display: none;
  }
  // 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
  &::-webkit-resizer {
      display: none;
  }
}

.w {
  width: 1200px;
  margin: 0 auto;
}
</style>
