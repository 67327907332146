<template>
  <div class="fireProtection">
	  <div class="header wow animate__slideInLeft">
	  	<img src="../../assets/image/solution/fireProtection/bg_fire_banner.png" alt="">
		<div @click="goBack">返回上一页</div>
	  </div>
    <div class="fireProtection wow animate__slideInUp">
      <div class="fireProtectionTop" id="fireProtectionTop">
		  <div class="fireProtection_body wow animate__slideInUp">
		    <div class="nav">
		      <div class="nav_item" :class="activeProductNav == item.name ? 'activeProductNav' : ''" v-for="item in enterpriseQualificationNavList" :key="item.name" @click="isProductNav(item.name)">
		       <a @click.prevent="anchor(item.position)" class="fireProtectionItemName"> {{item.name}}</a>
		      </div>
		    </div>
		  </div>
	  </div>
	  <div class="fireProtectionBottom">
		  <!-- 平台简介 -->
		  <div class="platformIntroduction">
			  <a id="PLATFORM" style="position: relative;top: -80px;"></a>
			  <div class="fireProtection_head wow animate__slideInUp">
			    <h2 class="title">平台简介</h2>
			    <p class="modifier">PLATFORM INTRODUCTION</p>
			  </div>
			  <div class="platformIntroductionContent">
				  <p>
					  智慧乡镇消防管理平台运用大数据、物联网、移动互联网、GIS、视频等技术手段，对下辖乡镇/街道消防站、社区/行政村微型消防站的消防人员和车辆、设备器材、日常工作监管、火灾防控、救援指挥调度实现一体化管理，将快速提升乡镇消防队伍管理能力和水平，主动掌控本地区的消防安全态势，加强火灾隐患管理和早期预警，有效降低火灾风险。
				  </p>
				  <div>
					  <img src="../../assets/image/solution/fireProtection/platformIntroduction.png" alt="">
				  </div>
			  </div>
		  </div>
		  <!-- 网络架构 -->
		  <div class="networkArchitecture">
			   <a id="NETWORK" style="position: relative;top: -80px;"></a>
			  <div class="fireProtection_head wow animate__slideInUp">
				<h2 class="title" style="padding-top: 26px;">网络架构</h2>
				<p class="modifier">NETWORK ARCHITECTURE</p>
			  </div>
			  <div class="networkArchitectureContent">
				  <div >
					  <img src="../../assets/image/solution/fireProtection/networkArchitecture.png" alt="">
				  </div>
			  </div>
		  </div>
		  <!-- 平台功能 -->
		  <div class="platformFunction">
			  <a id="FUNCTION" style="position: relative;top: -80px;"></a>
			  <div class="fireProtection_head wow animate__slideInUp">
				<h2 class="title" style="padding-top: 26px;">平台功能</h2>
				<p class="modifier">PLATFORM FUNCTION</p>
			  </div>
			  <div class="platformFunctionContent">
				  <div >
				  	 <img src="../../assets/image/solution/fireProtection/platformFunction1.png" alt="">
				  	 <img src="../../assets/image/solution/fireProtection/platformFunction2.png" alt="">
				  	 <img src="../../assets/image/solution/fireProtection/platformFunction3.png" alt="">
				  	 <img src="../../assets/image/solution/fireProtection/platformFunction4.png" alt="">
				  </div>
			  </div>
		  </div>
	  </div>
    </div>

  </div>
</template>

<script>
import { WOW } from "wowjs"
export default {
  data(){
    return {
      wow: null,
      activeProductNav: "平台简介",
      enterpriseQualificationNavList: [
		{
		  name: '平台简介',
		  position:'PLATFORM'
		},
		{
		  name: '网络架构',
		  position:'NETWORK'
		},
        {
          name: '平台功能',
		  position:'FUNCTION'
        }
      ]
    }
  },
  created(){
    
  },
  mounted(){
    this.wow = new WOW({
      boxClass: 'wow', // 添加动画的元素的标识
      animateClass: 'animate__animated', // 动画时长
      offset: 0, // 与可视区域底部的距离显示
      mobile: true, // 移动端是否可用
      live: true // 对后面添加的新元素是否有效
    }).init();
	window.addEventListener('scroll', () => {
	  // 页面滚动停止100毫秒后才会执行下面的函数。
	  this.scrollToTop();
	} , true);
  },
  destroy(){
  	  window.removeEventListener('scroll', this.scrollToTop)
  },
  methods: {
    isProductNav(name){
      this.activeProductNav = name
    },
	 /*锚链接跳转*/
	  anchor(anchorName) {
	    /*找到锚点*/
	    let anchorElement = document.getElementById(anchorName);
	    /*如果对应id的锚点存在，就跳转到锚点*/
	    if(anchorElement) {
	      anchorElement.scrollIntoView();
	    }
	  },
	  goBack(){
	  		  this.$router.back()
	  },
	  scrollToTop(){
			  if(!document.getElementById('fireProtectionTop')){
				  return
			  }
	  		  var offsetTop = document.getElementById('fireProtectionTop').getBoundingClientRect().top;
	  		  // var scrollTop = document.getElementById('culturalRelics').scrollTop;
	  		  if(window.pageYOffset>=492){
	  			  document.getElementById('fireProtectionTop').style.position = 'fixed'
	  			  document.getElementById('fireProtectionTop').style.top = '67px'
	  			  document.getElementById('fireProtectionTop').style.backgroundColor = '#fff'
				  document.getElementById('PLATFORM').style.top = '-140px'
				  document.getElementById('NETWORK').style.top = '-140px'
				  document.getElementById('FUNCTION').style.top = '-140px'
	  		  }else{
	  			  document.getElementById('fireProtectionTop').style.position = 'static'
	  			  // document.getElementById('culturalRelicsTop').style.top = '75px'
				  document.getElementById('PLATFORM').style.top = '-80px'
				  document.getElementById('NETWORK').style.top = '-80px'
				  document.getElementById('FUNCTION').style.top = '-80px'
	  		  }
	  		  // console.log(offsetTop,window.pageYOffset)
	  }
  }
}
</script>

<style lang="scss">
.fireProtection {
.header{
		width: 1903px;
		img{
			width: 100%;
		}
		position: relative;
		div{
			cursor: pointer;
			position: absolute;
			bottom: 4px;
			left: 360px;
			width: 120px;
			height: 40px;
			background: #4495FF;
			line-height: 40px;
			color: #fff;
			font-size: 16px;
		}
	}
  margin: 0 auto;
  .fireProtectionTop{
	  width: 100%;
	  border-bottom: 1px solid #C2C2C2;
	  .fireProtection_body {
		  margin: 0 auto;
		  width: 1200px;
		  padding: 0 367px;
	    .nav {
	      display: flex;
	      justify-content: space-between;
	      flex-wrap: wrap;
	      font-size: 22px;
	      color: #333333;
	      .nav_item {
			.fireProtectionItemName{
				display: inline-block;
				text-decoration: none;
				margin: 22px 0 22px 0;
				color: #333333;
			}
	        cursor: pointer;
	        &:hover {
	          color: #0155C4;
	          &::after {
	            width: 100%;
	          }
	        }
	      }
	      .nav_item::after {
	        content: '';
	        display: block;
	        margin: -2px auto 0;
	        height: 2px;
	        width: 0%;
	        background-color: #0155C4;
	        border-radius: 2px;
	        transition: all .3s;
	      }
	      .activeProductNav {
			  .fireProtectionItemName{
			  	 color: #0155C4;
			  }
	        color: #0155C4;
	        &::after {
	          width: 100%;
	        }
	      }
	    }
	  
	  }
  }

  .fireProtectionBottom{
	  padding-bottom: 60px;
	  background-color: #F5F6F6;
	  .fireProtection_head {
		  margin-bottom: 45px;
	    .title {
	      margin: 0 0 15px 0;
	      font-size: 40px;
	      color: #333;
	  	  padding-top: 70px;  
	    }
	    .modifier {
	      font-size: 24px;
	      color: #666;
	    }
	  }
	  .platformIntroduction{
		  background: url("../../assets/image/solution/fireProtection/bg_fire_platform.png") no-repeat;
		  padding-bottom:70px;
		  .platformIntroductionContent{
			  width: 1200px;
			  margin: 0 auto;
			  text-align:left;
			  P{
				  color: #191F25;
				  font-size: 20px;
				  line-height: 28px;
				  margin-bottom: 31px;
				  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
				  letter-spacing: 1px;
			  }
		  }
	  }
	  .networkArchitecture{
		  padding-bottom:70px;
	  }
	  .platformFunction{
		 .platformFunctionContent{
		 		width: 1200px;
		 		margin: 0 auto;  
				text-align:left;
				>div{
					img{
						margin: 0 18px 26px 0;
						&:nth-child(2n){
							margin-right: 0;
						}
					}
				}
		 } 
	  }
	  
	  
  }
}
 @media only screen and (max-width: 1200px) {
 	.fireProtection{
 		width: 1200px;
 		.header{
 			width: 1200px;
 		}
 	}
 }
</style>