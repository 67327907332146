var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solution"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"culturalRelics wow animate__slideInUp",on:{"click":function($event){return _vm.skipPage('/culturalRelics')}}},[_vm._m(2)]),_c('div',{staticClass:"fireProtection wow animate__slideInUp",on:{"click":function($event){return _vm.skipPage('/fireProtection')}}},[_vm._m(3)]),_c('div',{staticClass:"baseStation wow animate__slideInUp",on:{"click":function($event){return _vm.skipPage('/baseStation')}}},[_vm._m(4)]),_c('div',{staticClass:"elderlyCare wow animate__slideInUp",on:{"click":function($event){return _vm.skipPage('/elderlyCare')}}},[_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header wow animate__slideInLeft"},[_c('img',{attrs:{"src":require("../../assets/image/solution/bg_solution_banner.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solution_head wow animate__slideInUp"},[_c('h2',{staticClass:"title"},[_vm._v("解决方案")]),_c('p',{staticClass:"modifier"},[_vm._v("SOLUTION")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"text wow animate__bounceIn"},[_c('div',[_vm._v("文物安全监督解决方案")]),_c('p',[_vm._v("文保单位安全综合管理云平台采用基于云端的移动化办公管理软件，利用互联网、云计算、物联网等技术，实现安全管理云平台、PC端、移动端（小程序、公众号）已经各安全子系统之间的互联互通，打造智慧化的文保单位安全管理方式。文物安全监管系统由文物安全监管云平台、智慧文保小程序组成。")])]),_c('div',{staticClass:"image wow animate__slideInRight"},[_c('img',{attrs:{"src":require("../../assets/image/solution/bg_wenwuImg.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"image wow animate__slideInLeft"},[_c('img',{attrs:{"src":require("../../assets/image/solution/bg_fire.png"),"alt":""}})]),_c('div',{staticClass:"text wow animate__bounceIn"},[_c('div',[_vm._v("智慧消防解决方案")]),_c('p',[_vm._v("智慧消防安全管理平台是针对智慧消防领域，借助先进的移动互联网、物联网、传感器、大数据、云计算、人工智能等技术，充分利用现有安防系统，基于运营商成熟网络，服务于政府主管部门、一线工作人员。社会单位以及社会群众。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"text wow animate__bounceIn"},[_c('div',[_vm._v("通讯机房、基站节能解决方案")]),_c('p',[_vm._v("通信系统基站、机房的能耗问题已经成为通信运营商发展的难题，基站、机房的节能减排工作是目前关注焦点。本方案将通过对基站机构进行优化，使用新技术降低基站的能源消耗，促进通信系统可持续发展。")])]),_c('div',{staticClass:"image wow animate__slideInRight"},[_c('img',{attrs:{"src":require("../../assets/image/solution/bg_jifnagImage.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"image wow animate__slideInLeft"},[_c('img',{attrs:{"src":require("../../assets/image/solution/bg_yanglao.png"),"alt":""}})]),_c('div',{staticClass:"text wow animate__bounceIn"},[_c('div',[_vm._v("智慧养老解决方案")]),_c('p',[_vm._v("智慧消防安全管理平台是针对智慧消防领域，借助先进的移动互联网、物联网、传感器、大数据、云计算、人工智能等技术，充分利用现有安防系统，基于运营商成熟网络，服务于政府主管部门、一线工作人员。社会单位以及社会群众。")])])])
}]

export { render, staticRenderFns }