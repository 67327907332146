<template>
	<div class="NCE4">
		<div class="header wow animate__slideInLeft">
			<img src="../../../assets/image/productCenter/product/networkCommunicationEquipment/bg_detail_banner.png" alt="">
			<div @click="goBack">返回上一页</div>
		</div>
		<div class="detailTop">
			<div class="productName">网闸设备</div>
			<div class="productItems">
				<div class="productItem">
					<div class="productImg">
						<img src="../../../assets/image/productCenter/product/networkCommunicationEquipment/4-A.png" alt="">
					</div>
					<!-- <div class="name">
						<div>NM21NM1</div>
						<div>尺寸：275*44mm*174</div>
					</div> -->
				</div>
				<div class="productItem">
					<div class="productImg">
						<img src="../../../assets/image/productCenter/product/networkCommunicationEquipment/4-B.png" alt="">
					</div>
					<!-- <div class="name">
						<div>NM45</div>
						<div>尺寸：180*28*110mm</div>
					</div> -->
				</div>
			</div>
		</div>
		<div class="detailMiddle">
			<div>
				<div class="title">产品特点</div>
				<div class="characteristicsList">
					<div class="characteristicsItem" v-for="item,index in characteristicsList" :key="index">
						<svg-icon :icon-class="item.svg" ></svg-icon>
						<span>{{item.text}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="detailBottom">
			<div>
				<div class="title">产品指标</div>
				<div class="productIndex">
					<div class="indexHeader">
						<div>类别</div>
						<div>参数</div>
						<div>内容</div>
						<div>备注</div>
					</div>
					<div>
						<div class="indexRow">
								<div class="indexItem">主板</div>
								
								<div class="indexItem">
									<div class="indexChildrow">
										<div class="indexChildItem">配置</div>
										<div class="indexChildItem">6电1扩</div>
										<div class="indexChildItem"></div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">CPU</div>
										<div class="indexChildItem">C3338R/C3558/C3758</div>
										<div class="indexChildItem"></div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">网口指示灯</div>
										<div class="indexChildItem">左上LINK/ACT LED(绿色)，右上GE LINK LED(橙色)</div>
										<div class="indexChildItem"></div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">系统指示灯</div>
										<div class="indexChildItem">BYPASS/PWROK/HDD/ALARM/HA/PWR2/PWR1/4G/5G/WIFI上电时ALARM/HA交替闪烁</div>
										<div class="indexChildItem">开机后可自定义</div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">自定义指示灯</div>
										<div class="indexChildItem">5个（ALARM/HA/4G/5G/WIFI）</div>
										<div class="indexChildItem"></div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">双电源指示灯</div>
										<div class="indexChildItem">有，PWR2/PWR1</div>
										<div class="indexChildItem"></div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">按键</div>
										<div class="indexChildItem">开关机/强制BYPASS/双电源消音/复位(可选)</div>
										<div class="indexChildItem">可设置自动开机</div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">风扇</div>
										<div class="indexChildItem">CPU FAN x1(可选)/SYS FAN x1(可选)</div>
										<div class="indexChildItem"></div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">BEEP</div>
										<div class="indexChildItem">短鸣-正常启动；长鸣-内存故障</div>
										<div class="indexChildItem"></div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">SUPERIO</div>
										<div class="indexChildItem">无</div>
										<div class="indexChildItem"></div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">MCU</div>
										<div class="indexChildItem">BYPASS控制/指示灯控制/BYPASS看门狗</div>
										<div class="indexChildItem"></div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">TPM</div>
										<div class="indexChildItem">20PIN插针</div>
										<div class="indexChildItem"></div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">BMC</div>
										<div class="indexChildItem">无</div>
										<div class="indexChildItem"></div>
									</div>
								</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">内存</div>
								
								<div class="indexItem">
									<div class="indexChildrow">
										<div class="indexChildItem">类型</div>
										<div class="indexChildItem">1个通道为DDR4落板设计</div>
										<div class="indexChildItem"></div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">容量速率</div>
										<div class="indexChildItem">落板最大容量8GB DDR4-2400</div>
										<div class="indexChildItem"></div>
									</div>
								</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">存储</div>
								
								<div class="indexItem">
									<div class="indexChildrow">
										<div class="indexChildItem">eMMC</div>
										<div class="indexChildItem">8G/16G/32G/64G(选配)</div>
										<div class="indexChildItem"></div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">SATA</div>
										<div class="indexChildItem">2个SATA3.0（支持2.5寸/3.5寸硬盘） 支持机械盘1T-20T所有规格 支持SSD 64G-3.6T所有规格</div>
										<div class="indexChildItem"></div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">mSATA</div>
										<div class="indexChildItem">1个mSATA插座，SATA3.0</div>
										<div class="indexChildItem"></div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">M.2</div>
										<div class="indexChildItem">无</div>
										<div class="indexChildItem"></div>
									</div>
								</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">网络连接</div>
								
								<div class="indexItem">
									<div class="indexChildrow">
										<div class="indexChildItem">管理电口</div>
										<div class="indexChildItem">无</div>
										<div class="indexChildItem"></div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">网口</div>
										<div class="indexChildItem">6电</div>
										<div class="indexChildItem"></div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">BYPASS</div>
										<div class="indexChildItem">1组(三代BYPASS)(可选)</div>
										<div class="indexChildItem"></div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">POE</div>
										<div class="indexChildItem">不支持</div>
										<div class="indexChildItem"></div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">4G/5G</div>
										<div class="indexChildItem">支持2个推弹式SIM卡（4G和5G独立，各1个SIM卡）</div>
										<div class="indexChildItem">机箱不支持</div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">Mini-PCIE</div>
										<div class="indexChildItem">PCIE3.0 x1/SATA，可支持WIFI或加密卡</div>
										<div class="indexChildItem">支持WIFI6</div>
									</div>
								</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">接口</div>
								
								<div class="indexItem">
									<div class="indexChildrow">
										<div class="indexChildItem">串口</div>
										<div class="indexChildItem">RJ45*1(标准CISCO定义)</div>
										<div class="indexChildItem"></div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">USB</div>
										<div class="indexChildItem">USB2.0*2</div>
										<div class="indexChildItem"></div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">扩展槽</div>
										<div class="indexChildItem">PCIE 3.0*2(可选)</div>
										<div class="indexChildItem"></div>
									</div>
								</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">机箱</div>
								
								<div class="indexItem">
									<div class="indexChildrow">
										<div class="indexChildItem">尺寸（HxWxD）</div>
										<div class="indexChildItem">415mm(L)*440mm(W)*88mm(H) 2U机架式(1扩)</div>
										<div class="indexChildItem"></div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">接地孔</div>
										<div class="indexChildItem">支持</div>
										<div class="indexChildItem"></div>
									</div>
									
								</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">电源</div>
								
								<div class="indexItem">
									<div class="indexChildrow">
										<div class="indexChildItem">冗余电源</div>
										<div class="indexChildItem">150W</div>
										<div class="indexChildItem"></div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">单电/双电</div>
										<div class="indexChildItem">100W</div>
										<div class="indexChildItem"></div>
									</div>
									
								</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">环境</div>
								
								<div class="indexItem">
									<div class="indexChildrow">
										<div class="indexChildItem">整机工作条件</div>
										<div class="indexChildItem">0〜50℃   5%〜90%非凝露</div>
										<div class="indexChildItem"></div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">整机存储条件</div>
										<div class="indexChildItem">-40〜70℃ 5%〜90%非凝露</div>
										<div class="indexChildItem"></div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">海拔</div>
										<div class="indexChildItem">工作：0-5000米；非工作：0-5000米；</div>
										<div class="indexChildItem"></div>
									</div>
								</div>
							</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		WOW
	} from "wowjs"
	export default {
		data() {
			return {
				wow: null,
				characteristicsList:[{
					svg:'ic_tedian_1',
					text:"Intel® Denverton™ C3000, 2/4/8 Cores，支持QAT"
				},
				{
					svg:'ic_tedian_2',
					text:"板载DDR4颗粒， 支持4GB/8GB"

				},
				{
					svg:'ic_tedian_3',
					text:"板载miniPCIe接口1，支持WIFI/SATA"
				},
				{
					svg:'ic_tedian_4',
					text:"板载miniPCIe接口2，支持WIFI/SATA"
				},
				{
					svg:'ic_tedian_5',
					text:"板载miniPCIe接口3，支持USB，用于4G模块，板贴SIM卡槽"
				},
				{
					svg:'ic_tedian_7',
					text:"板载M.2 B-KEY接口，支持USB3.0，用于5G模块，板贴SIM卡槽"
				},
				{
					svg:'ic_tedian_8',
					text:"预留PCIe Gen3 x4 Slot可扩展1个PCIe Gen3 x4或2个PCIe Gen3 x2"
				},
				{
					svg:'ic_tedian_9',
					text:"支持单电/双电/冗余电源等多种产品形态"
				},
				{
					svg:'ic_tedian_10',
					text:'采用"2/1"的系统架构,即"内网主机"/"交换隔离卡"/"外网主机'
				},
				{
					svg:'ic_tedian_5',
					text:"专用安全隔离和交换芯片,双摆渡传输技术"
				},
				{
					svg:'ic_tedian_8',
					text:"根据隔离卡传输速率和接口形态可作为千兆网闸,万兆网闸,单向光闸使用"
				}]
			}
		},
		created() {

		},
		mounted() {
			this.wow = new WOW({
				boxClass: 'wow', // 添加动画的元素的标识
				animateClass: 'animate__animated', // 动画时长
				offset: 0, // 与可视区域底部的距离显示
				mobile: true, // 移动端是否可用
				live: true // 对后面添加的新元素是否有效
			}).init();
		},
		methods: {
			goBack() {
				this.$router.back()
			},
		}
	}
</script>

<style lang="scss">
	.NCE4 {
		.header {
			width: 1903px;
			margin: 0 auto;
			img {
				width: 100%;
			}

			position: relative;

			div {
				cursor: pointer;
				position: absolute;
				bottom: 4px;
				left: 360px;
				width: 120px;
				height: 40px;
				background: #4495FF;
				line-height: 40px;
				color: #fff;
				font-size: 16px;
			}
		}
		.detailTop{
			width: 1200px;
			margin: 0 auto;
			margin-bottom: 50px;
			.productName{
				font-size: 40px;
				font-weight: bold;
				color: #333;
				margin: 63px 0;
			}
			.productItems{
				display: flex;
				justify-content: space-between;
				.productItem{
					width: 580px;
					// height: 331px;
					background: #FFFFFF;
					box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
					border-radius: 16px 16px 16px 16px;
					.productImg{
						height: 229px;
						position: relative;
						img{
							width: 60%;
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
						}
					}
					.name{
						height: 107px;
						background: #2A61BF;
						border-radius: 0px 0px 16px 16px;
						div{
							&:first-child{
								font-size: 24px;
								color: #FFFFFF;
								padding: 21px 0 16px 0;
							}
							&:last-child{
								font-size: 20px;
								color: rgba(255, 255, 255, 0.8);
							}
						}
						
					}
				}
			}
			
			
		}
		.detailMiddle{
			background: url("../../../assets/image/productCenter/product/networkCommunicationEquipment/bg_tedian.png") no-repeat;
			background-size: 1920px 788px;
			>div{
				width: 1200px;
				margin: 0 auto;
			}

			.title{
				font-weight: bold;
				color: #333333;
				font-size: 32px;
				padding: 60px 0 53px 0;
			}
			.characteristicsList{
				width: 100%;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				.characteristicsItem{
					text-align: left;
					width: 50%;
					padding-bottom: 49px;
					.svg-icon{
						width: 52px;
						height: 52px;
						display: inline-block;
						vertical-align: middle;
					}
					span{
						margin-left: 9px;
						display: inline-block;
						vertical-align: middle;
						width: 498px;
						color: #333;
						font-size: 22px;
						font-weight: bold;
					}
				}
			}
			
		}
		.detailBottom{
			margin-bottom: 60px;
			>div{
				width: 1200px;
				margin: 0 auto;
			}
			.title{
				margin: 70px 0 50px 0;
				font-weight: bold;
				color: #333333;
				font-size: 32px;
			}
			.productIndex{
				.indexHeader{
					height: 69px;
					background: #0155C4;
					line-height: 69px;
					font-size: 26px;
					font-weight: 500;
					color: #FFF;
					>div{
						display: inline-block;
						width: 199px;
						border-left: 1px solid #C4C4C4;
						&:first-child{
							width: 100px;
							border-left: none;
						}
						&:nth-child(3){
							width: 697px;
						}
					}
				}
				.indexChildrow{
					&:nth-child(2n){
						background: #F4F5F8;
					}
				}
				.indexRow{
					line-height: 54px;
					border-left: 1px solid #C4C4C4;
					border-bottom: 1px solid #C4C4C4;
					>div{
						&:first-child{
							width: 100px;
						}
					}
					.indexItem{
						display: inline-block;
						vertical-align:middle;
						font-weight: 500;
						color: #555555;
						font-size: 0;
						&:first-child{
							font-size: 22px;
						}
						.indexChildrow{
							border: 1px solid #C4C4C4;
							border-top: none;
							line-height: 54px;
							&:last-child{
								border-bottom: none;
							}
							.indexChildItem{
								font-size: 22px;
								display: inline-block;
								vertical-align:middle;
								width: 199px;
								&:nth-child(2){
									width: 698px;
									border-left: 1px solid #C4C4C4;
									border-right: 1px solid #C4C4C4;
								}
							}
						}
						
					}
				}
				
			}

		}

		
	}
</style>