<template>
  <div class="aboutUs">
	  <div class="header wow animate__slideInLeft">
		  <img src="../../assets/image/aboutUs/bg_us_banner.png" alt="">
	  </div>
	  <div class="aboutUs_head wow animate__slideInUp">
		  <a id="introduction" style="position: relative;top: -80px;"></a>
	    <!-- <div class="dot"></div> -->
	    <h2 class="title">公司简介</h2>
	    <p class="modifier">ABOUT US</p>
	  </div>
	  <!-- 消防 -->
	  <div class="companyProfile wow animate__slideInUp">
	  		<div class="content">
	  			   <div class="image wow animate__slideInLeft">
	  					<img src="../../assets/image/aboutUs/bg_us_jianjie.png" alt="">
	  			   </div>
	  			   <div class="text wow animate__bounceIn">
	  					<div>中呈科技有限公司</div>
	  					<p style="line-height: 30px;">公司成立于2014年，注册资金5000万元，现有员工260余人，郑州市规上企业。公司主要从事计算机软硬件开发及技术服务、物联网及安防消防监控软硬件开发、计算机及安防消防项目系统集成、通信工程咨询设计、计算机软硬件设备研发等业务。公司成立以来业务发展迅速，在多年的业务实践和长期的工作经验积累的基础上，公司建立了一套较为完善的管理体系，取得多项软件著作权，具备电子与智能化专业承包二级资质。</p>
	  			   </div>
	  		</div>
			<div class="article wow animate__slideInUp">
				<p>我公司主要从事通信工程勘察设计、软件开发、计算机智能化系统集成、通信和计算机设备销售等，服务于运营商、设计院、政府机关以及各种企事业单位。公司主要经营业务包括智慧软件开发、智能硬件设计、行业解决方案和信息设计咨询四大板块。</p>
				<p>公司拥有技术实力强大的设计队伍，部门员工数量200人，在有线传输、数据、交换、无线、智能化、5G等专业拥有丰富的设计经验，服务对象包括中讯邮电咨询设计院、河南信息咨询设计研究有限公司、河南信息产业投资有限公司等。公司在河南、山东、湖北、福建、河北、重庆、云南均有设计案例和团队。</p>
			 <p> 公司在智能平台及相关软件开发方面具有丰富的经验，自主开发有安防及安全生产管理平台、能耗管理平台、动环监控系统、农村迁建智慧系统、电气火灾监控系统、车联网平台等软件，我公司联合开发的无线网、数据网、传输网三网规划系统，在中国联通全国范国内得到广泛应用。公司拥有软件开发人员30余人，在智能物联网、智能传感器和智能设备等方面具有长期的技术积累，自主软件产品成功应用于河南电网、河南省文物局等多个行业。</p>
			 <p>行业解决方案领域，我公司主要从事安防和弱电智能化系统集成，在智慧城市建设中具有广泛的案例。我公司依托于自有软件平台实现弱电和智能化系统的统一管理、数据集成，为全国文物保护、博物馆安防、智慧旅游、三维测绘等行业领域提供解决方案。</p>
			 <p>我公司具有强大的硬件开发能力，在工业物联网、智能安防、智能家居、智能传感器、汽车电子产品等方面拥有众多开发案例。我公司开发的多种文物安防专用传感器已在多个系统中成为标配。</p>
			</div>
	  </div>
	  <div class="aboutUs_head wow animate__slideInUp">
		  <a id="contact" style="position: relative;top: -80px;"></a>
	    <h2 class="title">联系我们</h2>
	    <p class="modifier">CONNECT US</p>
	  </div>
	  <div class="addressInfo">
		  <div id="container"></div>
		  <div class="addressBox">
			  <div>
				  <svg-icon icon-class="position" ></svg-icon>
				  <span style="font-size: 14px;color:rgba(255, 255, 255, 0.85);">地址</span>
			  </div>
			  <div style="margin-top: 22px; line-height: 28px;">河南省郑州市二七区嵩山路航海路东方大厦B座1905</div>
			  <div style="margin-top: 53px;">
				  <svg-icon icon-class="phone" ></svg-icon>
				  <span style="font-size: 14px;color:rgba(255, 255, 255, 0.85);">联系方式</span>
			  </div>
			  <div style="margin-top: 22px;">座机：0371-6711-9193</div>
			  <div style="margin-top: 17px;">
				  <span  style="vertical-align: middle;">微信二维码：</span>
				  <img  style="vertical-align: middle;"src="../../assets/image/aboutUs/bg_erweima.png" alt="">
			  </div>
		  </div>
	  </div>
  </div>
</template>

<script>

import { WOW } from "wowjs"
import AMapLoader from '@amap/amap-jsapi-loader';
export default {
  components: {
  },
  data(){
    return {
      wow: null,
	  map:null,
      activeProductNav: "安防产品",
    }
  },
  created(){
    
  },
  mounted(){
    this.wow = new WOW({
      boxClass: 'wow', // 添加动画的元素的标识
      animateClass: 'animate__animated', // 动画时长
      offset: 0, // 与可视区域底部的距离显示
      mobile: true, // 移动端是否可用
      live: true // 对后面添加的新元素是否有效
    }).init();
	this.initMap();
	if(this.$route.query.anchorPoint){
		/*锚链接跳转*/
		 /*找到锚点*/
		 let anchorElement = document.getElementById(this.$route.query.anchorPoint);
		 /*如果对应id的锚点存在，就跳转到锚点*/
		 if(anchorElement) {
		   anchorElement.scrollIntoView();
		 }
	}
  },
  watch: {
  	$route:{
  	  handler(val,oldval){
  	    // console.log(val);//新路由信息
  	    // console.log(oldval);//老路由信息
  		let anchorElement = document.getElementById(val.query.anchorPoint);
  		/*如果对应id的锚点存在，就跳转到锚点*/
  		if(anchorElement) {
  		  anchorElement.scrollIntoView();
  		}
  		// this.anchor(val.query.anchorPoint)
  	  },
  	  // 深度观察监听
  	  deep: true
  	}
  
  },
  methods: {
	   initMap(){
	      AMapLoader.load({
	        key:"5f976c5757e322cf0e0c34e5735a1414",             // 申请好的Web端开发者Key，首次调用 load 时必填
	        version:"2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
	        plugins:[''],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
	      }).then((AMap)=>{
	        this.map = new AMap.Map("container",{  //设置地图容器id
	          viewMode:"3D",    //是否为3D地图模式
	          zoom:17,           //初始化地图级别
	          center:[113.627546,34.72179], //初始化地图中心点位置
	        });
			setTimeout(()=>{
				this.addMaker()
			},300)
	      }).catch(e=>{
	        console.log(e);
	      })
	    },
		// 添加标记点
		 addMaker(){
			 var marker = new AMap.Marker({
			            icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png",
			            position: [113.627559,34.721423],
			            offset:  [-12, -63]
			        });
			        marker.setMap(this.map);
		 }
  },
  destroyed() {
  	this.map.destroy()
  }
}
</script>

<style lang="scss">
.aboutUs {
	margin-bottom: 60px;
	.header{
		width: 1903px;
		img{
			width: 100%;
		}
	}
  .aboutUs_head {
    .title {
      margin: 70px 0 15px 0;
      font-size: 40px;
      color: #333;
  		  
    }
    .modifier {
      font-size: 24px;
      color: #666;
    }
	margin-bottom: 60px;
  }
  .companyProfile{
	  .content{
		  margin: 0 auto;
		  width: 1200px;
		  display: flex;
		  .image{
			  height: 390px;
		  }
		  .text{
			  height: 390px;
			  padding: 65px 83px 0 59px;
			  width: 744px;
			  text-align: left;
			  color: #FFFFFF;
			  background-color: #295BAE;
			  div{
				  margin-bottom: 36px;
				  font-size: 38px;
				  font-weight: 600;
				  p{
					  font-size: 18px;
				  }
			  }
		  }
	  }
	  .article{
		  margin: 0 auto;
		  width: 1200px;
		  padding: 23px 45px;
		 background: #F3F3F3;
		 font-size: 18px;
		  p{
			  line-height: 30px;
			  text-align: left;
			  text-indent:2em
		  }
	  }
  }
  .addressInfo{
	  position: relative;
	  width: 1200px;
	  margin: 0 auto;
	  #container{
		  width: 887px;
	    padding:0px;
	    margin: 0px;
	    height: 412px;
		.amap-icon{
			
			width: 25px !important;
			height: 34px ;
			img{
				width: 100%;
				top: 0;
				left: 0;
			}
		}
	  }
	  .addressBox{
		  right: 0;
		  top: 0;
		  position: absolute;
		  width: 313px;
		  background-color: #295BAE;
		  height: 412px;
		  color: #fff;
		  padding: 58px 0 0 26px;
		  text-align: left;
		  font-size: 16px;
		  .svg-icon{
			  font-size: 10px;
		  }
	  }
  }
  
 }
 @media only screen and (max-width: 1200px) {
 	.aboutUs{
		width: 1200px;
 		.header{
 			width: 1200px;
 		}
 	}
 }
</style>