<template>
  <div class="qualification">
    <div class="depth">
      <div class="qualification_head wow animate__slideInUp">
        <!-- <div class="dot"></div> -->
        <h2 class="title">企业资质</h2>
        <p class="modifier">QUALIFICATION HONOR</p>
      </div>
      <div class="qualification_body wow animate__slideInUp">
        <div class="nav">
          <div
            class="nav_item"
            v-for="item in qualificationNav"
            :class="item.name == activeName ? 'active' : ''"
            :key="item.name"
            @click="isNav(item.name)"
          >
            <div class="name">{{ item.name }}</div>
          </div>
        </div>
        <div id="certify">
          <div class="swiper-container certify">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in certificationList"
                :key="index"
              >
                <!-- <div :style="`width:100%;height:100%;background-color:${item.color}`"></div> -->
                <img :src="item.img" alt="" style="height: 100%;" />
                <p>{{item.title}}</p>
              </div>
            </div>
			<div class="swiper-button-prev">
				<svg-icon icon-class="ic_left_white" ></svg-icon>
			</div>
			<div class="swiper-button-next">
				<svg-icon icon-class="ic_right_white" ></svg-icon>
			</div>
          </div>
          <div class="more">
			  <div class="bottom_btn" @click="skipPage('/enterpriseQualification')">
			    了解更多
			    <i class="el-icon-arrow-right"></i>
			  </div>
		  </div>
        </div>
        <!-- <p>{{activeTitle}}</p> -->
      </div>
    </div>
  </div>
</template>

<script>
// import Swiper from "@/utils/swiper.min.js";
import Swiper from "@/utils/swiper-bundle.min.js";
export default {
  data() {
    return {
      swiper: null,
      qualificationNav: [
        {
          name: "行业认证",
        },
        {
          name: "软件著作权",
        },
        {
          name: "发明专利",
        },
      ],
      activeName: "行业认证",
      certificationList: [
      ],
      activeTitle: '',
	  // 行业认知
	    industryCognitionList:[
	    	{
	    		img:require('../../../assets/image/enterpriseQualification/industryCognition/CMMI证书.jpg'),
	  		title:'CMMI证书'
	    	},
	    	{
	    		img:require('../../../assets/image/enterpriseQualification/industryCognition/安全生产许可证.jpg'),
	  		title:'安全生产许可证'
	    	},
	    	{
	    		img:require('../../../assets/image/enterpriseQualification/industryCognition/高新企业证书.jpg'),
	  		title:'高新企业证书'
	    	},
	    	{
	    		img:require('../../../assets/image/enterpriseQualification/industryCognition/建筑业企业资质一级.jpg'),
	  		title:'建筑业企业资质一级'
	    	},
	    	{
	    		img:require('../../../assets/image/enterpriseQualification/industryCognition/劳务派遣正本.jpg'),
	  		title:'劳务派遣正本'
	    	},
	    	{
	    		img:require('../../../assets/image/enterpriseQualification/industryCognition/软件企业证书.jpg'),
	  		title:'软件企业证书'
	    	},
	    ],
	    // 软件著作权
	    softwareCopyrightList:[
	    	{
	    		img:require('../../../assets/image/enterpriseQualification/softwareCopyright/PEM动力环境云监控系统.jpg'),
	  		title:'动力环境云监控系统'
	    	},
	    	{
	    		img:require('../../../assets/image/enterpriseQualification/softwareCopyright/SDWAN嵌入式终端CPE在线适配系统.jpg'),
	  		title:'SDWAN嵌入式终端CPE在线适配系统'
	    	},
	    	{
	    		img:require('../../../assets/image/enterpriseQualification/softwareCopyright/SDWAN嵌入式终端驱动管理系统.jpg'),
	  		title:'SDWAN嵌入式终端驱动管理系统'
	    	},
	    	{
	    		img:require('../../../assets/image/enterpriseQualification/softwareCopyright/安全管理视频联动管理系统.jpg'),
	  		title:'安全管理视频联动管理系统'
	    	},
	    	{
	    		img:require('../../../assets/image/enterpriseQualification/softwareCopyright/电气火灾安全监控系统.jpg'),
	  		title:'电气火灾安全监控系统'
	    	},
	    	{
	    		img:require('../../../assets/image/enterpriseQualification/softwareCopyright/黄河滩区迁建管理系统.jpg'),
	  		title:'黄河滩区迁建管理系统'
	    	},
	    ],
	  // 发明专利
	  inventionPatentList:[
	  	{
	  		img:require('../../../assets/image/enterpriseQualification/inventionPatent/多路直流电表.jpg'),
	  		title:'多路直流电表'
	  	},
	  	{
	  		img:require('../../../assets/image/enterpriseQualification/inventionPatent/物联网智能数据采集终端.jpg'),
	  		title:'物联网智能数据采集终端'
	  	},
	  	{
	  		img:require('../../../assets/image/enterpriseQualification/inventionPatent/巡更器专利证书.jpg'),
	  		title:'巡更器专利证书'
	  	},
	  	{
	  		img:require('../../../assets/image/enterpriseQualification/inventionPatent/一种具有现场决策的智能动态巡更系统.jpg'),
	  		title:'一种具有现场决策的智能动态巡更系统'
	  	},
	  	{
	  		img:require('../../../assets/image/enterpriseQualification/inventionPatent/智能电源监控器.jpg'),
	  		title:'智能电源监控器'
	  	},
	  	{
	  		img:require('../../../assets/image/enterpriseQualification/inventionPatent/智能温湿度传送器.jpg'),
	  		title:'智能温湿度传送器'
	  	},
	  ]
    };
  },
  mounted() {
	  this.certificationList = this.industryCognitionList
	  this.$nextTick(()=>{
		  this.initSwiper();
	  })
  },
  methods: {
    isNav(name) {
		this.certificationList =[]
		if(this.swiper){
			this.swiper.destroy(true,true)
		}
		if(name=='行业认证'){
			this.certificationList = this.industryCognitionList
		}
		if(name=='软件著作权'){
			this.certificationList = this.softwareCopyrightList
		}
		if(name=='发明专利'){
			this.certificationList = this.inventionPatentList
		}
      this.activeName = name;
	  this.$nextTick(()=>{
		  this.initSwiper()
	  })
    },
    initSwiper() {
		
      var that = this
      this.swiper = new Swiper(".certify", {
         effect: 'coverflow',
		 loop: true,
		 grabCursor: true,
		 centeredSlides: true,
		 slidesPerView: 'auto',
		 coverflowEffect: {
			rotate: 0,
			stretch: 0,
			depth: 400,
			modifier: 1,
			slideShadows: false,
		 },
		 navigation: {
			 nextEl: '.swiper-button-next',
			 prevEl: '.swiper-button-prev',
		 }
	 });
    },
	skipPage(path){
	  this.$router.push({path:path})
	},
  },
};
</script>

<style lang="scss" scoped>
.qualification {
  width: 100%;
  height: 703px;
  background: url("../../../assets/image/bg_honor.png") no-repeat;
  .depth {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    .qualification_head {
      margin-top: 65px;
      .dot {
        margin: 0 auto;
        height: 4px;
        width: 24px;
        background-color: #427bed;
        border-radius: 2px;
      }
      .title {
        margin-top: 12px;
        font-size: 40px;
        color: #FFFFFF;
		margin-bottom: 15px;
      }
      .modifier {
        font-size: 20px;
        color: rgba(255, 255, 255, 0.6);
      }
    }
    .qualification_body {
      .nav {
        margin-top: 45px;
        display: flex;
        justify-content: center;
        height: 50px;
        .nav_item {
			cursor: pointer;
          height: 50px;
          width: fit-content;
          margin: 0 20px;
          font-size: 20px;
          color: rgba(255, 255, 255, 0.5);
          transition: all 0.5s;
          .name {
            &::after {
              content: "";
              display: block;
              margin: 15px auto 0;
              width: 0%;
              height: 2px;
              background-color: #fff;
              transition: all 0.5s;
            }
          }
        }
        .active {
          color: #fff;
          .name::after {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
#certify {
  position: relative;
  width: 1200px;
  margin: 33px auto 0;
  .more{
	  width: 100%;
	  text-align: right;
	 .bottom_btn{
		 	background-color: #0155C4;
		 	// margin-top: 22px;
		 display: inline-block;
		   width: 140px;
		   height: 44px;
		   font-size: 14px;
		   line-height: 44px;
		   color: #fff;
		   border-radius: 22px;
		   cursor: pointer;
		   text-align: center;
	 } 
  }
}

#certify .swiper-container {
  padding-bottom: 60px;
}

#certify .swiper-slide {
  width: 400px !important;
  height: 286px;
  box-shadow: 0 0 30px -10px #ddd;
  background: linear-gradient(148deg, #BA976B 0%, #EBE1D5 52%, #BA976E 100%);
  border-radius: 4px 4px 4px 4px;
}
#certify .swiper-slide img {
  display: block;
  margin: 0 auto;
}
#certify .swiper-slide p {
  line-height: 54px;
  padding-top: 0;
  text-align: center;
  color: #fff;
  font-size: 20px;
  margin: 0;
  opacity: 0;
}

#certify .swiper-pagination {
  width: 100%;
  bottom: 20px;
}

#certify .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px;
  border: 3px solid #fff;
  background-color: #d5d5d5;
  width: 10px;
  height: 10px;
  opacity: 1;
}

#certify .swiper-slide,.swiper-slide-active p {
  
    opacity: 1 !important;
  
  
}

#certify .swiper-pagination-bullets .swiper-pagination-bullet-active {
  border: 3px solid #00aadc;
  background-color: #fff;
}

#certify {
	.swiper-button-prev,.swiper-button-next {
		font-size: 27px;
	  top: 42%;
	 color: rgba(80, 148, 238, 0.6);
	 &:hover{
	 			  color: rgba(80, 148, 238, 1);
	 }
	 &::after{
		 content: "";
	 }
	}

}
</style>
