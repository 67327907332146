<template>
  <div class="newsDynamic">
	  <div class="header wow animate__slideInLeft">
	  	<img src="../../assets/image/newsDynamic/bg_news_banner.png" alt="">
	  </div>
    <div class="newsDynamic_head wow animate__slideInUp">
      <h2 class="title">新闻动态</h2>
      <p class="modifier">PRODUCT CENTER</p>
    </div>
	<div class="newContent">
		<div class="newList">
			<div :class="`newItem wow animate__slideIn${(index+1)%2==1?'Left':'Right'}`" v-for="item,index in currentNewsList" :key="index" @click="goNewsDetail(item)">
				<div class="image">
					<img :src="item.img" alt="">
				</div>
				<div class="newsText">
					<div class="title">
						{{item.title}}
					</div>
					<div class="content">
						{{item.content}}
					</div>
				</div>
				<div class="date">
					<div>
						{{item.date.split('-')[2]}}
					</div>
					<div>
						{{item.date.slice(0,7)}}
					</div>
				</div>
			</div>
		</div>
		<el-pagination
		  @current-change="changePageSize"
		  background
		  layout="total,prev, pager, next,jumper"
		  :page-size="6"
		  :total="newList.length"
		  >
		</el-pagination>
	</div><p></p>
  </div>
</template>

<script>
import { WOW } from "wowjs"

export default {
  components: {
  },
  data(){
    return {
      wow: null,

      newList: [
        {
		  img:require('../../assets/image/newsDynamic/news1.png'),
		  contentImg:require('../../assets/image/newsDynamic/newsList/1.png'),
          title: '贺我司荣获郑州市“专精特新”企业称号',
          content: '祝贺我司获得郑州市“专精特新”企业称号按照《郑州市中小企业服务中心关于组织开展2021年度郑州市“专精特新”中小企业申报工作的通知》（郑中小企〔2021〕49号），经企业申报、各区县（市）推荐、专家评审...',
		  date:'2021-12-20',
		  contenDetail:"<p>祝贺我司获得郑州市“专精特新”企业称号</p><p>按照《郑州市中小企业服务中心关于组织开展2021年度郑州市“专精特新”中小企业申报工作的通知》（郑中小企〔2021〕49号），经企业申报、各区县（市）推荐、专家评审、评定指标统计分析等，我司凭借专业化、精细化、特色化和新颖化方面的独特优势，荣获郑州市“专精特新”企业称号。 </p><p>“专精特新”的资质评定，旨在帮助中小企业构建有效的发展，经营和管理模式，强调战略，绩效和特色，使各类中小企业易于在发展实践方面进行沟通和共享，建立资信、获得发展资源，造就搭建中小企业认证评估平台，成为投资者、客户、员工、供应商、合作伙伴和社会机构认识中小企业并与之展开合作的基础，对我公司的长足发展起着至关重要的作用，这标志着我公司发展将迎来新的机遇和进入一个新的发展阶段。</p><p>此次荣获“专精特新”企业称号，是长期坚持走专业化发展、精细化管理、特色化产品、新颖化创新之路所取得的成绩，也是对我公司在智慧软件开发、智能硬件设计、行业解决方案及信息设计咨询等技术攻坚方面的肯定。我们将用更精专的科创团队、完善的质量体系、优质的服务理念为公司进一步的创新发展提供保障和助力，做好产品创新、品质提升，不断提高产品核心竞争力！</p>"
        },
		{
		  img:require('../../assets/image/newsDynamic/news2.png'),
		  contentImg:require('../../assets/image/newsDynamic/newsList/2.png'),
		  title: '航空港智慧互联合作伙伴项目中标喜讯',
		  content: '热烈庆祝我公司成功中标郑州航空港智慧互联科技有限公司业务合作伙伴（第一批）招募项目标段一项目！我们将以最大的热情为客户提供最好的服务！',
		  date:'2021-08-02',
		  contenDetail:"<p>热烈庆祝我公司成功中标郑州航空港智慧互联科技有限公司业务合作伙伴（第一批）招募项目标段一项目！我们将以最大的热情为客户提供最好的服务！</p>"
		},
		{
		  img:require('../../assets/image/newsDynamic/news3.png'),
		  contentImg:require('../../assets/image/newsDynamic/newsList/3.jpg'),
		  title: '长垣文物库房安全技术防范系统工程中标喜讯',
		  content: '热烈庆祝我公司成功中标长垣市文物库房安全技术防范系统工程项目！我们将以最大的热情为客户提供最好的服务！',
		  date:'2021-08-02',
		  contenDetail:"<p>热烈庆祝我公司成功中标长垣市文物库房安全技术防范系统工程项目！我们将以最大的热情为客户提供最好的服务！</p>"
		},{
		  img:require('../../assets/image/newsDynamic/news4.png'),
		  contentImg:require('../../assets/image/newsDynamic/newsList/4.jpg'),
          title: '丹霞寺安全工程项目中标喜讯!',
          content: '热烈庆祝我公司成功中标丹霞寺安全工程项目！我们将以最大的热情为客户提供最好的服务！',
		  date:'2021-08-02',
		  contenDetail:"<p>热烈庆祝我公司成功中标丹霞寺安全工程项目！我们将以最大的热情为客户提供最好的服务！</p>"
        },{
		  img:require('../../assets/image/newsDynamic/news5.png'),
		  contentImg:require('../../assets/image/newsDynamic/newsList/5.jpg'),
		  title: '长垣中国烹饪文化博物馆改造工程中标喜讯',
		  content: '热烈庆祝我公司成功中标长垣县中国烹饪文化博物馆安全技术防范系统提升改造工程项目！我们将以最大的热情为客户提供最好的服务！',
		  date:'2021-08-02',
		  contenDetail:"<p>热烈庆祝我公司成功中标长垣县中国烹饪文化博物馆安全技术防范系统提升改造工程项目！我们将以最大的热情为客户提供最好的服务！</p>"
		},{
		  img:require('../../assets/image/newsDynamic/news6.png'),
		  contentImg:require('../../assets/image/newsDynamic/newsList/6.jpg'),
		  title: '庆祝公司通过CMMI3认证',
		  content: '中呈科技有限公司于2020年8月8日顺利通过CMMI三级认证！在最终报告会上，SEI授权主任评估师RobertSmith向中呈科技管理层代表和所有参与评估的成员正式宣布了评估结果。　　2020年2月初，在公司总经理张炜的倡导下，做为中呈科技有限公司的CMMI认证发起人，正式决定在中呈科技有限公司的研发体系引入CMMI的认证，正式开始了CMMI的认证过程。经历了CMMI学习、QMS体系建立····',
		  date:'2021-04-19',
		  contenDetail:"<p>中呈科技有限公司于2020年8月8日顺利通过CMMI 三级认证！在最终报告会上，SEI授权主任评估师Robert Smith向中呈科技管理层代表和所有参与评估的成员正式宣布了评估结果。</p><p>2020年2月初，在公司总经理张炜的倡导下，做为中呈科技有限公司的CMMI认证发起人，正式决定在中呈科技有限公司的研发体系引入CMMI的认证，正式开始了CMMI的认证过程。经历了CMMI学习、QMS体系建立、项目试点运行、评估师阶段性辅助检查、CMMI认证评估等多个关键阶段，历时6个月。通过主任评估师、ATM、高层经理、项目组和EPG 的共同努力，并顺利于2020年8月8日正式通过CMMI三级认证。</p><p>CMMI全称是Capability Maturity Model Integration, 即软件能力成熟度模型集成模型，是由美国国防部与卡内基-梅隆大学和美国国防工业协会共同开发和研制的。CMMI是一套融合多学科的、可扩充的产品集合，其研制的初步动机是为了利用两个或多个单一学科的模型实现一个组织的集成化过程改进。</p><p>CMMI认证是国际软件业在软件工程、系统工程和质量管理的国际标准，是目前世界公认的软件产品进入国际市场的通行证，它不仅仅是对产品质量的认证，更是一种软件过程改善的途径</p><p>通过CMMI3级评估，是对中呈科技有限公司软件过程改进工作的一种肯定，标志着公司的软件工程和质量管理进入了一个全新的阶段，对于提高公司综合实力和核心竞争力具有十分重要的作用。</p>"
		},{
		  img:require('../../assets/image/newsDynamic/news7.png'),
		  contentImg:'',
		  title: '我公司进入郑州市规上企业库',
		  content: '在公司全体同仁的努力下，中呈科技有限公司2019年营业额超过3000万元，研发实力也取得了长足的发展。以此为基础，公司于2020年3月成功入库郑州市规上企业，这为公司今后获取国家相关政策支持、进一步完善研发平台并加大研发投入打下了良好的基础，必将促进公司业务发展更上一层楼！',
		  date:'2020-03-15',
		  contenDetail:"<p>在公司全体同仁的努力下，中呈科技有限公司2019年营业额超过3000万元，研发实力也取得了长足的发展。以此为基础，公司于2020年3月成功入库郑州市规上企业，这为公司今后获取国家相关政策支持、进一步完善研发平台并加大研发投入打下了良好的基础，必将促进公司业务发展更上一层楼！</p>"
		},{
		  img:require('../../assets/image/newsDynamic/news8.png'),
		  contentImg:require('../../assets/image/newsDynamic/newsList/8.jpg'),
		  title: '我公司获电子与智能化工程专业承包贰级资质',
		  content: '经国家住房和城乡建设部审核批准，我公司顺利获得电子与智能化工程专业承包贰级资质，为公司信息系统集成业务发展再添基石。该资质的获得进一步提升我公司的市场竞争力，同时使公司的专业水平又上一个台阶，也标志着公司在电子与智能化相关专业工程设计、工程施工及技术服务领域方面有了更大的发展空间。按照《电子与智能化工程专业承包资质标准》要求，取得该项资质后，我公司可承揽单项合同额2500万元以下的电子工业制造设····',
		  		  date:'2019-11-25',
		  		  contenDetail:"<p>经国家住房和城乡建设部审核批准，我公司顺利获得电子与智能化工程专业承包贰级资质，为公司信息系统集成业务发展再添基石。</p><p>该资质的获得进一步提升我公司的市场竞争力，同时使公司的专业水平又上一个台阶，也标志着公司在电子与智能化相关专业工程设计、工程施工及技术服务领域方面有了更大的发展空间。按照《电子与智能化工程专业承包资质标准》要求，取得该项资质后，我公司可承揽单项合同额2500万元以下的电子工业制造设备安装工程和电子工业环境工程、单项合同额1500万元以下的电子系统工程和建筑智能化工程施工类项目，进一步拓展了公司的施工许可范围，今后也可为客户和合作伙伴提供更多更好的服务。</p><p>贰级资质的获得只是我们在集成领域做大做强的第一步，公司上下将齐心协力，作出更大的努力，将资质申办成功的喜讯化为工作的动力，推动公司的发展更上一层楼！</p>"
		},{
		  img:require('../../assets/image/newsDynamic/news9.png'),
		  contentImg:'',
		  title: '我公司通过质量环境职业健康等管理体系认证',
		  content: '2019年5月，来自北京国标联合认证有限公司的3位专家对我公司进行了质量、环境、职业健康安全管理体系现场审核。通过五天的现场核查，审核组一致认为,我公司的质量、环境、职业健康安全管理体系满足了包括新版标准等在内的审核准则要求，管理体系的过程控制对质量、环境、职业健康安全的持续改进起到了一定的保证作用，体系运行有效。在公司完成纠正措施并经审核组验证确认后，公司获得质量、环境、职业健康安全管理体系认····',
		  date:'2019-07-10',
		  contenDetail:"<p>2019年5月，来自北京国标联合认证有限公司的3位专家对我公司进行了质量、环境、职业健康安全管理体系现场审核。通过五天的现场核查，审核组一致认为,我公司的质量、环境、职业健康安全管理体系满足了包括新版标准等在内的审核准则要求，管理体系的过程控制对质量、环境、职业健康安全的持续改进起到了一定的保证作用，体系运行有效。在公司完成纠正措施并经审核组验证确认后，公司获得质量、环境、职业健康安全管理体系认证证书。 </p><p>公司自2018年底开始启动质量管理体系、环境管理体系和职业健康安全管理体系认证工作，在综合部、技术部等部门的全力工作下，通过体系策划、文件建立、标准培训、内审员培训、体系文件完善、体系试运行、内审、管理评审、认证申请、现场审核等一系列贯标活动，建立了符合质量、环境新版标准要求的管理体系。</p><p>此次，公司能够顺利通过认证审核，必将进一步推动企业管理，为公司管理体系的进一步提升奠定基础。</p>"
		},{
		  img:require('../../assets/image/newsDynamic/news10.png'),
		  contentImg:'',
		  title: '我公司成为河南联通阿里钉钉开发合作商',
		  content: '我公司成功入围河南联通阿里钉钉合作开发商招标，成为河南联通阿里钉钉开发合作伙伴。钉钉(DingTalk)是中国领先的智能移动办公平台,由阿里巴巴集团开发,免费提供给所有中国企业,用于商务沟通和工作协同。目前钉钉的企业用户数超过700万、注册个人用户过亿，并且不断上线新的功能，满足了企业移动办公的需要，我公司拥有成熟的钉钉开发团队，可以为企业提供个性化钉钉应用功能开发，尤其是在与现有系统数据接口、····',
		  date:'2019-05-15',
		  contenDetail:"<p>我公司成功入围河南联通阿里钉钉合作开发商招标，成为河南联通阿里钉钉开发合作伙伴。</p><p>钉钉(DingTalk)是中国领先的智能移动办公平台,由阿里巴巴集团开发,免费提供给所有中国企业,用于商务沟通和工作协同。目前钉钉的企业用户数超过700万、注册个人用户过亿，并且不断上线新的功能，满足了企业移动办公的需要，</p><p>我公司拥有成熟的钉钉开发团队，可以为企业提供个性化钉钉应用功能开发，尤其是在与现有系统数据接口、智能设备数据采集展现等方面具有强大的开发能力和多年项目经验，愿意与河南联通一起为广大企业提供优质的服务！</p>"
		},{
		  img:require('../../assets/image/newsDynamic/news11.png'),
		  contentImg:'',
		  title: '黄河滩区迁建管理系统上线运行',
		  content: '由我公司全自主开发的“黄河滩区迁建管理系统”在新乡封丘县上线运行，首期开通李庄、陈桥两个乡镇。该软件功能涵盖人口及财产数据采集、安置房房源管理、购房及赔补管理，并提供完善的统计分析和各种票据打印功能，将迁建管理人员从繁琐的手工数据工作中解放出来，提高工作效率、减少数据错误，获得了用户的一致好评。',
		  date:'2019-03-01',
		  contenDetail:"<p>由我公司全自主开发的“黄河滩区迁建管理系统”在新乡封丘县上线运行，首期开通李庄、陈桥两个乡镇。</p><p该软件功能涵盖人口及财产数据采集、安置房房源管理、购房及赔补管理，并提供完善的统计分析和各种票据打印功能，将迁建管理人员从繁琐的手工数据工作中解放出来，提高工作效率、减少数据错误，获得了用户的一致好评。></p>"
		},{
		  img:require('../../assets/image/newsDynamic/news12.png'),
		  contentImg:'',
		  title: '我公司中标中讯设计院合作单位招标',
		  content: '热烈庆祝我公司成功中标长垣县中国烹饪文化博物馆安全技术防范系统提升改造工程项目！我们将以最大的热情为客户提供最好的服务！',
		  date:'2018-07-15',
		  contenDetail:"<p>我公司在中讯邮电咨询设计院有限公司2018年勘察、咨询、设计合作单位技术服务集中采购中成功入围，成为中讯邮电咨询设计院有限公司核心网、数据网、信息化、建筑、传输、无线等专业设计合作单位。</p><p>中讯邮电咨询设计院有限公司是原邮电部设计院，是我国通信行业规模最大的综合性甲级咨询设计单位之一，自1992年以来一直位居“中国勘察设计综合实力百强”之列。此次入围多个专业勘察设计业务，表明我公司技术实力、服务理念得到了中讯邮电咨询设计院有限公司，我公司将全力做好服务，保证设计质量，使公司获得进一步发展！</p>"
		}
      ],
	  currentNewsList:[]
    }
  },
  created(){
    
  },
  mounted(){
    this.wow = new WOW({
      boxClass: 'wow', // 添加动画的元素的标识
      animateClass: 'animate__animated', // 动画时长
      offset: 0, // 与可视区域底部的距离显示
      mobile: true, // 移动端是否可用
      live: true // 对后面添加的新元素是否有效
    }).init();
	this.currentNewsList = this.newList.slice(0,6)
  },
  methods: {
	  goNewsDetail(item){
		  this.$router.push({name:'newsDetail',params:item})
	  },
	  changePageSize(val){
		  this.currentNewsList = this.newList.slice((val-1)*6,val*6)
	  },
  }
}
</script>

<style lang="scss">
.newsDynamic {
  margin: 0 auto;
  .header{
  		width: 1903px;
  		img{
  			width: 100%;
  		}
  	}
	.newsDynamic_head {
	  margin-bottom: 55px;
	  .title {
	    margin: 0 0 15px 0;
	    font-size: 40px;
	    color: #333;
		  padding-top: 70px;  
	  }
	  .modifier {
	    font-size: 24px;
	    color: #666;
	  }
	}
	.newContent{
		padding-bottom:20px;
		.newList{
			width: 1200px;
			margin: 0 auto;
			.newItem{
				cursor: pointer;
				text-align: left;
				border: 1px solid #E1E4E6;
				padding: 26px;
				margin-bottom: 40px;
				>div{
					display: inline-block;
					vertical-align: top;
				}
				.image{
					img{
						width: 252px;
						height: 180px;
					}
				}
				.newsText{
					width: 712px;
					margin: 19px 55px 0 22px;
					.title{
						margin-bottom: 22px;
						font-size: 26px;
					}
					.content{
						font-size: 18px;
						line-height: 26px;
					}
					
				}
				.date{
					margin-top: 26px;
					width: 105px;
					text-align: center;
					>div{
						&:first-child{
							font-size: 36px;
							border: 1px solid #DBE2EB;
							line-height: 75px;
						}
						&:last-child{
							line-height: 40px;
							background: #DBE2EB;
							font-size: 18px;
						}
					}
				}
				&:hover{
					box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.16);
				}
			}
		}
	}
}
@media only screen and (max-width: 1200px) {
	.newsDynamic{
		width: 1200px;
		.header{
			width: 1200px;
		}
	}
}
</style>