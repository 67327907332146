<template>
  <div class="footer">
    <div class="depth">
      <div class="footer_right">
        <ul class="menu">
          <li class="menu_item head" @click="skipPage('/')">首页</li>
          <li class="menu_item" @click="skipPage('/','productCenter')">产品中心</li>
          <li class="menu_item" @click="skipPage('/','solution')">解决方案</li>
        </ul>
        <ul class="menu">
          <li class="menu_item head" @click="skipPage('/productCenter')">产品中心</li>
          <li class="menu_item" @click="skipPage('/productCenter','网络通讯设备')">网络通讯设备</li>
          <li class="menu_item" @click="skipPage('/productCenter','汽车电子')">汽车电子</li>
          <li class="menu_item" @click="skipPage('/productCenter','智能养老设备')">智能养老设备</li>
        </ul>
        <ul class="menu">
          <li class="menu_item head" @click="skipPage('/solution')">解决方案</li>
          <li class="menu_item" @click="skipPage('/culturalRelics')">文物安全监督</li>
          <li class="menu_item" @click="skipPage('/fireProtection')">智慧消防</li>
          <li class="menu_item" @click="skipPage('/elderlyCare')">智慧养老</li>
          <li class="menu_item" @click="skipPage('/baseStation')">通讯机房/基站节能</li>
        </ul>
		<ul class="menu">
		  <li class="menu_item head" @click="skipPage('/newsDynamic')">新闻动态</li>
		</ul>
		<ul class="menu">
		  <li class="menu_item head" @click="skipPage('/enterpriseQualification')">企业资质</li>
		  <li class="menu_item" @click="skipPage('/enterpriseQualification','industryCognition')">行业认证</li>
		  <li class="menu_item" @click="skipPage('/enterpriseQualification','softwareCopyright')">软件著作权</li>
		  <li class="menu_item" @click="skipPage('/enterpriseQualification','inventionPatent')">发明专利</li>
		</ul>
        <ul class="menu">
          <li class="menu_item head" @click="skipPage('/aboutUs')">关于我们</li>
          <li class="menu_item" @click="skipPage('/aboutUs','introduction')">公司简介</li>
          <li class="menu_item" @click="skipPage('/aboutUs','contact')">联系我们</li>
        </ul>
      </div>
	   <div class="footer_left">
	      <div class="contact">
	        <div class="phone">
	          <div class="title">服务热线:</div>
	          0371-6711 9193
	        </div>
			<div class="title">地址：</div>
			<div class="address">郑州市二七区嵩山路航海路东方大厦B座1905</div>
			<img class="qrCode" src="../../assets/image/qrCode.png" alt="">
			<div class="info">中呈科技微信公众号</div>
	      </div>
	    </div>
    </div>
    <div class="put" @click="goPage">
      Copyright©2014-2023zhonghuicome.com 中呈科技有限公司豫ICP备19012833号-1
    </div>
  </div>
</template>

<script>
export default {
	methods:{
		// 跳转页面
		skipPage(path,anchorPoint){
		  this.$router.push({path:path,query:{
				anchorPoint
			}})
		},
		goPage(){
			window.location.href='https://beian.miit.gov.cn';
		},
	}

}
</script>

<style lang="scss">
.footer {
    position: relative;
    min-height: 470px;
    width: 100%;
    background-color:#011D41;
    .depth {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 1200px;
      margin: 0 auto;
      padding-top: 80px;
      .footer_left {
		text-align: left;
        width: 224px;
        .contact {
          .qrCode {
            display: block;
            width: 100px;
            height: 100px;
            margin-bottom: 4px;
          }
          .info {
            font-size: 12px;
            color:rgba(255, 255, 255, 0.7);
          }
          .phone {
            color: #FFFFFF;
            font-size: 20px;
			margin-bottom: 10px;
            
          }
		  .address{
			  font-size: 14px;
			  color: #fff;
			  margin-bottom: 10px;
		  }
		  .title {
		    color:rgba(255, 255, 255, 0.7);
		    font-size: 12px;
			margin-bottom: 5px;
		  }
        }
      }
      .footer_right {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 830px;
        color: #FFFFFF;
        font-size: 14px;
        .menu {
			// margin-right: 67px;
          .menu_item {
			  cursor: pointer;
			  text-align: left;
            margin-bottom: 22px;
			color:rgba(255, 255, 255, 0.7);
          }
          .head {
            font-size: 20px;
            margin-bottom: 25px;
			color:rgba(255, 255, 255, 1);
          }
        }
      }
	  padding-bottom: 56px;
	  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
    .put {
		cursor: pointer;
		margin-top: 26px;
		text-align: center;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.7);
    }
  }
  @media only screen and (max-width: 1200px) {
    .footer .depth {
      justify-content: center ;
      width: 100% ;
      .footer_right {
        margin: 20px 10px 80px;
        width: 100% ;
      }
    }
    .footer{
    	width: 1200px;
    }
  }
</style>