<template>
  <div class="top ">
    <div class="depth">
      <div class="logo" @click.stop="isNav({ name: '首页'},{path:'/'})">
        <img src="../../assets/image/logo/logo.png" alt="" >
      </div>
	  <!-- v-if="innerWidth > 1200" -->
      <div class="navMenu" >
        <div v-for="item in navList" :key="item.name" class="navItem" :class="activeNav == item.name ? 'activeNav' : ''" @click="isNav(item,item)">
          <div class="navItem_text">
            {{item.name}}
          </div>
          <div v-if="item.children" class="menu_children">
            <div class="menu_children_item" v-for="ele in item.children" :key="ele.name" @click.stop="isNav(item,ele)">{{ele.name}}</div>
          </div>
        </div>
      </div>
     <!-- <div v-else class="menuIcon" >
        <i class="icon" :class="isShowNav ? 'el-icon-close' : 'el-icon-s-unfold'" @click="isShowSideNav"></i>
      </div> -->
      <div class="sideNav" :style="{height: `${innerHeight}px`, transform: isShowNav ? 'translateY(-5px)' : 'translateY(-170%)' }">
        <div class="sideNav_item" v-for="item in navList" :key="item.name">
          <div class="sideNav_item_name" @click="goView(item,item)">
            <span>{{item.name}}</span>
            <i class="icon" v-if="item.children" :class="item.isShowChildren ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></i>
          </div>
          <div class="sideNav_item_list" :style="item.isShowChildren ? 'height: fit-content;' : 'height: 0px;'">
            <div @click="goView(item,ele)" class="sideNav_item_list_item" v-for="ele in item.children" :key="ele.name">
              {{ele.name}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      activeNav: "首页",
      innerWidth: (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth),
      innerHeight: (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) + 20,
      isShowNav: false,
      navList: [
        {
          name: "首页",
		  path:'/',
		  routerName:'home'
        },
        {
          name: "产品中心",
          isShowChildren: false,
		  path:'/productCenter',
		  routerName:'productCenter'
        },
        {
          name: '解决方案',
          isShowChildren: false,
		  path:'/solution',
		  routerName:'solution',
          children: [
            {
              name: '文物安全监管解决方案',
			  path:'/culturalRelics'
            },
            {
              name: '智慧消防解决方案',
			  path:'/fireProtection'
            }, {
              name: '通讯机房/基站节能解决方案',
			  path:'/baseStation'
            },
			{
			  name: '智慧养老解决方案',
			  path:'/elderlyCare'
			},
          ]
        },  
        {
          name: "新闻动态",
		  path:'/newsDynamic',
		  routerName:'newsDynamic'
        },
		{
		  name: '企业资质',
		  isShowChildren: false,
		  path:'/enterpriseQualification',
		  routerName:'enterpriseQualification'
		},
        {
          name: '关于我们',
          isShowChildren: false,
		  path:'/aboutUs',
		  routerName:'aboutUs'
        },
      ],
	  screenWidth:''
    }
  },
  watch: {
    isShowNav(nv, ov){
      if(nv){
        document.body.style.overflow = 'hidden';
      }else {
        document.body.style.overflow = '';
      }
    },
	$route:{
	  handler(val,oldval){
	    /* console.log(val);//新路由信息
	    console.log(oldval);//老路由信息 */
		this.navList.forEach(value=>{
		  if(val.name==value.routerName){
			  this.activeNav = value.name
		  }
		})
	  },
	  // 深度观察监听
	  deep: true
	}

  },
  created(){
    var that = this
    window.onresize = function(){
      that.innerWidth = document.body.clientWidth
	  console.log(that.innerWidth)
    }
  },
  mounted() {
	  this.navList.forEach(val=>{
		  if(val.routerName==this.$route.name){
			  this.activeNav = val.name
		  }
	  })
	  
  },
  methods: {
    // web端切换菜单,添加激活样式
    isNav(item,ele) {
      this.activeNav = item.name
	  this.$router.push({path:ele.path})
    },
    // 移动端点击弹出菜单栏
    isShowSideNav(){
      this.isShowNav = !this.isShowNav
    },
    // 菜单栏展开或跳转
    goView(item,ele){
      if(item.children){
        item.isShowChildren = !item.isShowChildren
      }else {
		  this.$router.push({path:ele.path})
        console.log('跳转');
      }
    }
  }
}
</script>

<style lang="scss" >
@media only screen and (max-width: 750px) {
  .top .depth {
    width: 100% !important;
    justify-content: space-between;
  }
  // .top{
	 //  .logo{
		//   width: 50% !important;
		//   img{
		// 	  width: 100% !important;
		//   }
	 //  }
  // }
}
  .top {
    position: fixed;
	width: 100%;
    top: 0;
    left: 0;
    height: 80px;
    z-index: 6;
    background-color: rgba(255, 255, 255, 0.9);
    .depth {
      position: relative;
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      z-index: 10;
      .logo {
		  cursor: pointer;
		  width: 200px;
        position: relative;
        // width: 280px;
        // height: 100%;
        // z-index: 6;
		margin-right: 100px;
		// vertical-align: middle;
		img{
			
			position: absolute;
			top: 50%;
			left: 0;
			margin-top: -25px; /* 高度的一半 */

		}
      }
      .menuIcon {
        position: relative;
        height: 100%;
        width: 50px;
        font-size: 32px;
        line-height: 80px;
        z-index: 6;
      }
      .sideNav {
        position: relative;
        padding: 20px 20px 100px;
        width: 100%;
        text-align: left;
        background-color: #fff;
        transition: all .3s;
        transform: translateY(-120%);
        overflow: auto;
        z-index: 5;
        .sideNav_item {
          font-size: 20px;
          line-height: 50px;
          border-bottom: 1px solid #ccc;
          .sideNav_item_name {
            display: flex;
            justify-content: space-between;
            .icon {
              margin-top: 15px;
              transition: all .3s;
            }
          }
          .sideNav_item_list {
            height: 0;
            overflow: hidden;
            transition: all .3s;
            .sideNav_item_list_item {
              padding-left: 20px;
              border-top: 1px solid #ccc;
              text-overflow: ellipsis; /* 溢出显示省略号 */
              overflow: hidden; /* 溢出隐藏 */
              white-space: nowrap;  /* 强制不换行 */
            }
          }
        }
      }
      .navMenu {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        width: fit-content;
        .navItem {
          margin-left: 24px;
          width: 92px;
          color: #333;
          font-size: 14px;
          line-height: 80px;
          cursor: pointer;
          .navItem_text {
            margin: 0 auto;
            width: fit-content;
            &::after {
              content: "";
              display: block;
              height: 2px;
              width: 0;
              margin: -20px auto 0;
              background-color: #0155C4;
            }
          }
          .menu_children {
            position: relative;
            display: none;
            margin-top: 30px;
            width: 240px;
            padding: 0 20px;
            color: #000;
            background-color: #fff;
            transform: translateX(-70px);
            transition: all .3s;
            border-radius: 10px;
            box-shadow: 0px 0px 5px 0px #ccc;
            .menu_children_item {
              line-height: 50px;
              border-bottom: 1px solid #ccc;
              // text-overflow: ellipsis; /* 溢出显示省略号 */
              // overflow: hidden; /* 溢出隐藏 */
              // white-space: nowrap;  /* 强制不换行 */
              &:last-child {
                border: 0px;
              }
            }
            &::after {
              content: '';
              position: absolute;
              top: -20px;
              left: 50%;
              transform: translateX(-50%);
              display: block;
              border-top: 10px solid transparent;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-bottom: 10px solid #fff;
            }
            &::before {
              content: '';
              position: absolute;
              top: -22px;
              left: 50%;
              transform: translateX(-50%);
              display: block;
              border-top: 11px solid transparent;
              border-left: 11px solid transparent;
              border-right: 11px solid transparent;
              border-bottom: 11px solid #eeeeee;
            }
          }
          &:hover {
            .menu_children {
              display: block;
            }
            .navItem_text {
              color: #0155C4;
            }
            .navItem_text::after {
              width: 100%;
              transition: all .3s;
            }
          }
        }
        .activeNav {
          transition: all .3s;
          color: #0155C4;
          .navItem_text::after {
            width: 100%;
            transition: all .3s;
          }
        }
      }
    }
  }
</style>