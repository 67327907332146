<template>
	<div class="EE3">
		<div class="header wow animate__slideInLeft">
			<img src="../../../assets/image/productCenter/product/networkCommunicationEquipment/bg_detail_banner.png" alt="">
			<div @click="goBack">返回上一页</div>
		</div>
		<div class="detailTop">
			<div class="productName">智能手环</div>
			<div class="productItems">
				<div class="productItem">
					<div class="productImg">
						<img src="../../../assets/image/productCenter/product/elderlyEquipment/3-A.png" style="width: 24%;" alt="">
					</div>
					<div class="name">
						<div>B2315+（触摸按键）</div>
					</div>
				</div>
				<div class="productItem">
					<div class="productImg">
						<img src="../../../assets/image/productCenter/product/elderlyEquipment/3-B.png" style="width: 22%;" alt="">
					</div>
					<div class="name">
						<div>B2315K（物理按键）</div>
					</div>
				</div>
			</div>
		</div>
		<div class="detailMiddle">
			<div>
				<div class="title">产品特点</div>
				<div class="characteristicsList">
					<div class="characteristicsItem" v-for="item,index in characteristicsList" :key="index">
						<svg-icon :icon-class="item.svg" ></svg-icon>
						<span>{{item.text}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="detailBottom">
			<div>
				<div class="title">配置清单</div>
				<div class="productIndex">
					<div>
						<div class="indexRow">
							<div class="indexItem">尺寸重量</div>
							<div class="indexItem">43 x 23 x 13 mm / 25g （超小尺寸，无感佩戴）</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">产品颜色</div>
							<div class="indexItem">缺省黑色（可以定制其他颜色）</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">通信网络</div>
							<div class="indexItem">B2315+/B2315K:  NBIoT：          B3/B5/B8（支持电信，移动或联通）；   可选B20/B28
B2315M:          CAT-M/NBIoT：  多个频段（内置移远 BG77模组）
B2315L:           LoRaWAN:       EU868 / US915 / AU915 / AS923/ KR920 / IN865 / CN470 </div>
						</div>
						<div class="indexRow">
							<div class="indexItem">SIM卡</div>
							<div class="indexItem">NBIoT  5*6mm贴片卡出货标配3年NBIoT卡） </div>
						</div>
						<div class="indexRow">
							<div class="indexItem">定位模式</div>
							<div class="indexItem" style="text-align: left;">
								<div>1、室内外GPS北斗、Wifi、基站和加速度传感器混合定位，算法对精度和功耗进行优化</div>
								<div>2、定位精度：5-20米（室内场景视Wifi密集度有所变化）</div>
								<div>3、支持精准蓝牙定位：1-3米（需要配合欧孚iBeacon蓝牙信标）</div>
							</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">蓝牙功能</div>
							<div class="indexItem">低功耗蓝牙BLE  （可通过蓝牙对手环进行在线升级；BLE可以扫描和广播）</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">健康数据</div>
							<div class="indexItem">心率、血压、血氧、体温、计步、睡眠检测（不同的配置有不一样）</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">显示屏</div>
							<div class="indexItem">0.49寸64*32点阵黑白OLED显示屏 </div>
						</div>
						<div class="indexRow">
							<div class="indexItem">LED</div>
							<div class="indexItem">2个LED灯（红色和蓝色）</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">触摸/按键</div>
							<div class="indexItem">
								<div>B2315+单点触摸按键         （开关机和SOS功能复用）</div>
								<div>B2315K防水物理按键         （开关机和SOS功能复用）</div>
</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">表带规格</div>
							<div class="indexItem">缺省黑色TPU时尚环扣表带；可选配：标准表扣表带、魔术贴表带、防拆螺丝、不同颜色的表带</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">电池/充电</div>
							<div class="indexItem">
								<div>B2315+/B2315K：    NBIoT版本：聚合物电池，160mAh， / 强力吸附式充电 </div>
								<div>B2315L / B2315M:    LoRaWAN和CATM版本：210mAh电池，/吸附式充电</div>
							</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">工作时长</div>
							<div class="indexItem">充电：1.5小时；待机：大于1个月；正常使用：4-10天（视定位频率） </div>
						</div>
						<div class="indexRow">
							<div class="indexItem">马达</div>
							<div class="indexItem">出货缺省不带（批量订单可以定制带；B2315L默认带马达）</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">电子标签</div>
							<div class="indexItem">出货缺省不带 可选NFC标签（M1 RFID卡），用于支付、公交、门禁等</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">防水等级</div>
							<div class="indexItem">IP67</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">SOS功能</div>
							<div class="indexItem">支持一键报警（上报到管理平台和手机APP上）</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">断点续传</div>
							<div class="indexItem">支持（在没有信号的位置，会保存定位和健康数据，等待有信号再集中传输）</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">佩戴检测</div>
							<div class="indexItem">支持（手环佩戴和摘除都有消息上报到平台和APP）</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">消息下发</div>
							<div class="indexItem">支持</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">电子围栏</div>
							<div class="indexItem">支持（圆形和自定义电子围栏）</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">历史轨迹</div>
							<div class="indexItem">支持长达100天以上历史数据查询</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">马达</div>
							<div class="indexItem">出货缺省不带（批量订单可以定制带；B2315L默认带马达）</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">告警功能</div>
							<div class="indexItem">SOS告警、出入电子围栏告警、低电告警、关机告警、佩戴检测报警等</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">包装配置</div>
							<div class="indexItem" style="text-align: left;">
								<div>标配：中性包装，手环X 1（时尚环扣表带），充电线X 1，合格证（保修卡）X 1, 手环说明书 X 1
								平台/手机客户端说明书 X1</div>
								<div>选配：标准表扣表带、魔术贴表带、防拆螺丝、不同颜色表带；信标OV02，OV03；RFID标签；充电器</div>
							</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">平台功能</div>
							<div class="indexItem">用户自定义公司名称、多用户管理、分级管理、代理商权限等</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">手机客户端</div>
							<div class="indexItem">微信小程序，安卓和IOS APP  </div>
						</div>
						<div class="indexRow">
							<div class="indexItem">国家认证</div>
							<div class="indexItem">电信设备进网许可证；无线电发射设备型号核准证 </div>
						</div>
						<div class="indexRow">
							<div class="indexItem">安全认证</div>
							<div class="indexItem">防水认证(IP67防水测试认证报告）；表带认证(表带抗拉认证报告)；危险认证(手环防燃认证报告)  </div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		WOW
	} from "wowjs"
	export default {
		data() {
			return {
				wow: null,
				characteristicsList:[{
					svg:'ic_tedian_1',
					text:"手环小巧轻便、防水、超长待机、操作简单，采集体温心率血压定位数据，合适于所有人群全天候无感佩戴。"
				},
				{
					svg:'ic_tedian_3',
					text:"手环支持SOS一键报警，GPS北斗+Wifi+蓝牙室内外无缝定位。体温、心率、血压、睡眠、计步健康数据一应俱全。"
				},
				{
					svg:'ic_tedian_2',
					text:"支持健康数据超标、出入电子围栏、低电和关机自动报警，历史轨迹查询等功能"
				},
				{
					svg:'ic_tedian_4',
					text:"配合蓝牙信标，可以实现低成本精准室内定位（可以精准定位到门洞、楼层、卫生间和房间等）"
				},
				{
					svg:'ic_tedian_5',
					text:"手环具有佩戴检测（手环佩戴和摘除都有消息上报到平台和APP）。不佩戴就进入超低功耗模式。"
				},
				{
					svg:'ic_tedian_7',
					text:"手环支持断点续传（在没有信号的位置，会保存定位和健康数据，等待有信号再集中传输）"
				},
				{
					svg:'ic_tedian_8',
					text:"手环支持后台和APP下发数据，屏幕上设置个人姓名，实现通知等消息下发。手环屏幕可以显示二维码"
				},
				{
					svg:'ic_tedian_9',
					text:"手环可选配13.56MHz NFC标签（RFID M1卡）；实现门禁、饭卡等小额支付"
				},
				{
					svg:'ic_tedian_10',
					text:"若是内网数据需要私有化，可以选择LoRaWAN版本-B2315L"
				}]
			}
		},
		created() {

		},
		mounted() {
			this.wow = new WOW({
				boxClass: 'wow', // 添加动画的元素的标识
				animateClass: 'animate__animated', // 动画时长
				offset: 0, // 与可视区域底部的距离显示
				mobile: true, // 移动端是否可用
				live: true // 对后面添加的新元素是否有效
			}).init();
		},
		methods: {
			goBack() {
				this.$router.back()
			},
		}
	}
</script>

<style lang="scss">
	.EE3 {
		.header {
			width: 1903px;
			margin: 0 auto;
			img {
				width: 100%;
			}

			position: relative;

			div {
				cursor: pointer;
				position: absolute;
				bottom: 4px;
				left: 360px;
				width: 120px;
				height: 40px;
				background: #4495FF;
				line-height: 40px;
				color: #fff;
				font-size: 16px;
			}
		}
		.detailTop{
			width: 1200px;
			margin: 0 auto;
			margin-bottom: 50px;
			.productName{
				font-size: 40px;
				font-weight: bold;
				color: #333;
				margin: 63px 0;
			}
			.productItems{
				display: flex;
				justify-content: space-around;
				.productItem{
					width: 580px;
					// height: 331px;
					background: #FFFFFF;
					box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
					border-radius: 16px 16px 16px 16px;
					.productImg{
						height: 229px;
						position: relative;
						img{
							width: 60%;
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
						}
					}
					.name{
						// height: 107px;
						background: #2A61BF;
						border-radius: 0px 0px 16px 16px;
						div{
							&:first-child{
								font-size: 24px;
								color: #FFFFFF;
								padding: 21px 0 16px 0;
							}
							&:last-child{
								font-size: 20px;
								color: rgba(255, 255, 255, 0.8);
							}
						}
						
					}
				}
			}
			
			
		}
		.detailMiddle{
			background: url("../../../assets/image/productCenter/product/networkCommunicationEquipment/bg_tedian.png") no-repeat;
			background-size: 1920px 743px;
			>div{
				width: 1200px;
				margin: 0 auto;
			}

			.title{
				font-weight: bold;
				color: #333333;
				font-size: 32px;
				padding: 60px 0 53px 0;
			}
			.characteristicsList{
				width: 100%;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				.characteristicsItem{
					text-align: left;
					width: 50%;
					padding-bottom: 49px;
					.svg-icon{
						width: 52px;
						height: 52px;
						display: inline-block;
						vertical-align: middle;
					}
					span{
						margin-left: 9px;
						display: inline-block;
						vertical-align: middle;
						width: 498px;
						color: #333;
						font-size: 22px;
						font-weight: bold;
					}
				}
			}
			
		}
		.detailBottom{
			margin-bottom: 60px;
			>div{
				width: 1200px;
				margin: 0 auto;
			}
			.title{
				margin: 70px 0 50px 0;
				font-weight: bold;
				color: #333333;
				font-size: 32px;
			}
			.productIndex{
				>div{
					&:last-child{
						>div{
							&:nth-child(2n){
								background: #F4F5F8;
							}
						}
					}
				}
				.indexRow{
					line-height: 54px;
					border: 1px solid #C4C4C4;
					border-top: none;
					.indexItem{
						display: inline-block;
						vertical-align:middle;
						font-weight: 500;
						color: #555555;
						font-size: 22px;
						width: 599px;
						&:first-child{
						}
						
						&:last-child{
							border-left: 1px solid #C4C4C4;
						}
					}
					&:first-child{
						border-top: 1px solid #C4C4C4;
					}
				}
				
			}

		}

		
	}
</style>