<template>
	<div class="AE4">
		<div class="header wow animate__slideInLeft">
			<img src="../../../assets/image/productCenter/product/networkCommunicationEquipment/bg_detail_banner.png" alt="">
			<div @click="goBack">返回上一页</div>
		</div>
		<div class="detailTop">
			<div class="productName">水暖控制器</div>
			<div class="productItems">
				<div class="productItem">
					<div class="productImg">
						<img src="../../../assets/image/productCenter/product/automotiveElectronics/4-A.png" alt="">
					</div>
					<!-- <div class="name">
						<div>NH03 11寸机箱</div>
						<div>PTC控制器</div>
					</div> -->
				</div>
				<div class="productItem">
					<div class="productImg">
						<img src="../../../assets/image/productCenter/product/automotiveElectronics/4-B.png" alt="">
					</div>
					<!-- <div class="name">
						<div>NH03P 19寸机箱</div>
						<div>风暖控制器</div>
					</div> -->
				</div>
			</div>
		</div>
		<div class="detailMiddle">
			<div>
				<div class="title">产品特点</div>
				<div class="characteristicsList">
					<div class="characteristicsItem" v-for="item,index in characteristicsList" :key="index">
						<svg-icon :icon-class="item.svg" ></svg-icon>
						<span>{{item.text}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="detailBottom">
			<div>
				<div class="title">产品参数</div>
				<div class="productIndex">
					<div class="indexHeader">
						<div>功能</div>
						<div>触发条件</div>
						<div>控制策略</div>
					</div>
					<div>
						<div class="indexRow">
								<div class="indexItem">软启动</div>
								<div class="indexItem">接收CAN启动信号</div>
								<div class="indexItem">指定时间达到要求</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">高压过压保护</div>
								<div class="indexItem">
									<div class="indexChildrow">
										<div class="indexChildItem">高于510V</div>
										<div class="indexChildItem">关闭</div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">降至500V</div>
										<div class="indexChildItem">恢复</div>
									</div>
								</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">高压欠压保护</div>
								<div class="indexItem">
									<div class="indexChildrow">
										<div class="indexChildItem">低于240V</div>
										<div class="indexChildItem">关闭</div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">升至250V</div>
										<div class="indexChildItem">恢复</div>
									</div>
								</div>
								
							</div>
							<div class="indexRow">
								<div class="indexItem">低压欠压保护</div>
								<div class="indexItem">
									<div class="indexChildrow">
										<div class="indexChildItem">低于8.5V</div>
										<div class="indexChildItem">关闭</div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">升至9.5V</div>
										<div class="indexChildItem">恢复</div>
									</div>
								</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">低压过压保护</div>
								<div class="indexItem">
									<div class="indexChildrow">
										<div class="indexChildItem">高于16.5V</div>
										<div class="indexChildItem">关闭</div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">降至15.5V</div>
										<div class="indexChildItem">恢复</div>
									</div>
								</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">过流保护</div>
								<div class="indexItem">高于35A</div>
								<div class="indexItem">关闭</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">出水口过温保护</div>
								<div class="indexItem">
									<div class="indexChildrow">
										<div class="indexChildItem">高于90℃</div>
										<div class="indexChildItem">关闭</div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">低于80℃</div>
										<div class="indexChildItem">恢复</div>
									</div>
								</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">IGBT 过温保护</div>
								<div class="indexItem">
									<div class="indexChildrow">
										<div class="indexChildItem">高于110℃</div>
										<div class="indexChildItem">关闭</div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">低于100℃</div>
										<div class="indexChildItem">恢复</div>
									</div>
								</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">PCB过温保护</div>
								<div class="indexItem">
									<div class="indexChildrow">
										<div class="indexChildItem">高于105℃</div>
										<div class="indexChildItem">关闭</div>
									</div>
									<div class="indexChildrow">
										<div class="indexChildItem">低于95℃</div>
										<div class="indexChildItem">恢复</div>
									</div>
								</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">温度传感器失效保护</div>
								<div class="indexItem">传感器开路或短路</div>
								<div class="indexItem">关闭</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">低压反接保护</div>
								<div class="indexItem">正负极反接</div>
								<div class="indexItem">不工作</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">高压反接保护</div>
								<div class="indexItem">正负极反接</div>
								<div class="indexItem">关闭</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">通讯故障保护</div>
								<div class="indexItem">检测通讯故障</div>
								<div class="indexItem">关闭</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">IGBT故障保护</div>
								<div class="indexItem">检测IGBT故障</div>
								<div class="indexItem">关闭</div>
							</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		WOW
	} from "wowjs"
	export default {
		data() {
			return {
				wow: null,
				characteristicsList:[{
					svg:'ic_tedian_1',
					text:"额定工作电压（高压）：350V "
				},
				{
					svg:'ic_tedian_8',
					text:"工作电压范围（高压）：250V-500V"
				},
				{
					svg:'ic_tedian_2',
					text:"额定功率：7000W "

				},
				{
					svg:'ic_tedian_9',
					text:"冲击电流：＜35A"
				},
				{
					svg:'ic_tedian_3',
					text:"额定电压（低压）：12V "
				},
				{
					svg:'ic_tedian_10',
					text:"工作电压范围（低压）：9V-16V"
				},
				{
					svg:'ic_tedian_4',
					text:"工作环境温度：-40℃-105℃ "
				},
				{
					svg:'ic_tedian_8',
					text:"存储温度：-40℃-125℃"
				},
				{
					svg:'ic_tedian_5',
					text:"在线刷新：支持 "
				},
				{
					svg:'ic_tedian_9',
					text:"诊断功能：支持"
				},
				{
					svg:'ic_tedian_7',
					text:"通讯方式：CAN通讯 "
				},
				{
					svg:'ic_tedian_10',
					text:"其它功能：预留PWM控制"
				},
				
				]
			}
		},
		created() {

		},
		mounted() {
			this.wow = new WOW({
				boxClass: 'wow', // 添加动画的元素的标识
				animateClass: 'animate__animated', // 动画时长
				offset: 0, // 与可视区域底部的距离显示
				mobile: true, // 移动端是否可用
				live: true // 对后面添加的新元素是否有效
			}).init();
		},
		methods: {
			goBack() {
				this.$router.back()
			},
		}
	}
</script>

<style lang="scss">
	.AE4{
		.header {
			width: 1903px;
			margin: 0 auto;
			img {
				width: 100%;
			}

			position: relative;

			div {
				cursor: pointer;
				position: absolute;
				bottom: 4px;
				left: 360px;
				width: 120px;
				height: 40px;
				background: #4495FF;
				line-height: 40px;
				color: #fff;
				font-size: 16px;
			}
		}
		.detailTop{
			width: 1200px;
			margin: 0 auto;
			margin-bottom: 50px;
			.productName{
				font-size: 40px;
				font-weight: bold;
				color: #333;
				margin: 63px 0;
			}
			.productItems{
				display: flex;
				justify-content: space-between;
				.productItem{
					width: 580px;
					// height: 331px;
					background: #FFFFFF;
					box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
					border-radius: 16px 16px 16px 16px;
					.productImg{
						height: 229px;
						position: relative;
						img{
							width: 30%;
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
						}
					}
					.name{
						height: 70px;
						background: #2A61BF;
						border-radius: 0px 0px 16px 16px;
						div{
							&:first-child{
								font-size: 24px;
								color: #FFFFFF;
								padding: 21px 0 16px 0;
							}
							&:last-child{
								font-size: 20px;
								color: rgba(255, 255, 255, 0.8);
							}
						}
						
					}
				}
			}
			
			
		}
		.detailMiddle{
			background: url("../../../assets/image/productCenter/product/networkCommunicationEquipment/bg_tedian.png") no-repeat;
			background-size: 1920px 761px;
			>div{
				width: 1200px;
				margin: 0 auto;
			}

			.title{
				font-weight: bold;
				color: #333333;
				font-size: 32px;
				padding: 60px 0 53px 0;
			}
			.characteristicsList{
				width: 100%;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				.characteristicsItem{
					text-align: left;
					width: 50%;
					padding-bottom: 49px;
					.svg-icon{
						width: 52px;
						height: 52px;
						display: inline-block;
						vertical-align: middle;
					}
					span{
						margin-left: 9px;
						display: inline-block;
						vertical-align: middle;
						width: 498px;
						color: #333;
						font-size: 22px;
						font-weight: bold;
					}
				}
			}
			
		}
		.detailBottom{
			margin-bottom: 60px;
			>div{
				width: 1200px;
				margin: 0 auto;
			}
			.title{
				margin: 70px 0 50px 0;
				font-weight: bold;
				color: #333333;
				font-size: 32px;
			}
			.productIndex{
				.indexHeader{
					height: 69px;
					background: #0155C4;
					line-height: 69px;
					font-size: 26px;
					font-weight: 500;
					color: #FFF;
					>div{
						display: inline-block;
						width: 400px;
						&:nth-child(2){
							border-right: 1px solid #C4C4C4;
							border-left: 1px solid #C4C4C4;
							width: 398px;
						}
					}
				}
				>div{
					&:last-child{
						>div{
							&:nth-child(2n){
								background: #F4F5F8;
							}
						}
					}
				}
				.indexRow{
					line-height: 54px;
					// border: 1px solid #C4C4C4;
					border: 1px solid #C4C4C4;
					border-top: none;
					.indexItem{
						// border-bottom: 1px solid #C4C4C4;
						display: inline-block;
						vertical-align:middle;
						font-weight: 500;
						color: #555555;
						font-size: 22px;
						min-width: 400px;
						border-left: 1px solid #C4C4C4;
						&:first-child{
							border-left: none;
						}
						&:nth-child(2){
							// border-right: 1px solid #C4C4C4;
							min-width: 397px;
						}
						.indexChildrow{
							font-size: 0;
							border-top: none;
							line-height: 54px;
							width:796px;
							&:first-child{
								border-bottom:  1px solid #C4C4C4;
							}
							.indexChildItem{
								font-size: 22px;
								display: inline-block;
								vertical-align:middle;
								width: 398px;
								&:first-child{
									width: 396px;
								}
								&:nth-child(2){
									width: 400px;
									border-left: 1px solid #C4C4C4;
									// border-right: 1px solid #C4C4C4;
								}
							}
						}
					}
				}
				
			}

		}

		
	}
</style>