<template>
	<div class="EE4">
		<div class="header wow animate__slideInLeft">
			<img src="../../../assets/image/productCenter/product/networkCommunicationEquipment/bg_detail_banner.png" alt="">
			<div @click="goBack">返回上一页</div>
		</div>
		<div class="detailTop">
			<div class="productName">燃气报警器</div>
			<div class="productItems">
				<div class="productItem">
					<div class="productImg">
						<img src="../../../assets/image/productCenter/product/elderlyEquipment/4-A.png" style="width: 32%;" alt="">
					</div>
					<!-- <div class="name">
						<div>NH03 11寸机箱</div>
						<div>尺寸：275*174*44mm</div>
					</div> -->
				</div>
				<!-- <div class="productItem">
					<div class="productImg">
						<img src="../../../assets/image/productCenter/product/elderlyEquipment/1-B.png" alt="">
					</div>
					<div class="name">
						<div>NH03P 19寸机箱</div>
						<div>尺寸：263*440*44mm</div>
					</div>
				</div> -->
			</div>
		</div>
		<div class="detailMiddle">
			<div>
				<div class="title">产品特点</div>
				<div class="characteristicsList">
					<div class="characteristicsItem" v-for="item,index in characteristicsList" :key="index">
						<svg-icon :icon-class="item.svg" ></svg-icon>
						<span>{{item.text}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="detailBottom">
			<div>
				<div class="title">技术参数</div>
				<div class="productIndex">
					<div>
						<div class="indexRow">
							<div class="indexItem">无线类型</div>
							<div class="indexItem">ZigBee</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">发射频率</div>
							<div class="indexItem">2.4GHz</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">供电方式</div>
							<div class="indexItem">电源适配器 Type-c</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">工作环境</div>
							<div class="indexItem">0℃~+55℃</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">探测气体</div>
							<div class="indexItem">甲烷（天然气）</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">报警浓度</div>
							<div class="indexItem">8%LEL甲烷（天然气）</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">报警声压</div>
							<div class="indexItem">≥70dB（正前方1m处）</div>
						</div>
						<div class="indexRow">
							<div class="indexItem">产品尺寸</div>
							<div class="indexItem">85*29mm</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		WOW
	} from "wowjs"
	export default {
		data() {
			return {
				wow: null,
				characteristicsList:[{
					svg:'ic_tedian_1',
					text:"当天然气泄漏浓度达到8%LEL告警阀值时，立即发出70dB高分贝报警声"
				},
				{
					svg:'ic_tedian_3',
					text:"低电量报警"
				},
				{
					svg:'ic_tedian_2',
					text:"优选ABS防火阻燃材质，耐热耐高温，抗冲击"
				},
				
				{
					svg:'ic_tedian_4',
					text:"远程app实时接收信息"
				}]
			}
		},
		created() {

		},
		mounted() {
			this.wow = new WOW({
				boxClass: 'wow', // 添加动画的元素的标识
				animateClass: 'animate__animated', // 动画时长
				offset: 0, // 与可视区域底部的距离显示
				mobile: true, // 移动端是否可用
				live: true // 对后面添加的新元素是否有效
			}).init();
		},
		methods: {
			goBack() {
				this.$router.back()
			},
		}
	}
</script>

<style lang="scss">
	.EE4 {
		.header {
			width: 1903px;
			margin: 0 auto;
			img {
				width: 100%;
			}

			position: relative;

			div {
				cursor: pointer;
				position: absolute;
				bottom: 4px;
				left: 360px;
				width: 120px;
				height: 40px;
				background: #4495FF;
				line-height: 40px;
				color: #fff;
				font-size: 16px;
			}
		}
		.detailTop{
			width: 1200px;
			margin: 0 auto;
			margin-bottom: 50px;
			.productName{
				font-size: 40px;
				font-weight: bold;
				color: #333;
				margin: 63px 0;
			}
			.productItems{
				display: flex;
				justify-content: space-around;
				.productItem{
					width: 580px;
					// height: 331px;
					background: #FFFFFF;
					box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
					border-radius: 16px 16px 16px 16px;
					.productImg{
						height: 229px;
						position: relative;
						img{
							width: 60%;
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
						}
					}
					.name{
						// height: 107px;
						background: #2A61BF;
						border-radius: 0px 0px 16px 16px;
						div{
							&:first-child{
								font-size: 24px;
								color: #FFFFFF;
								padding: 21px 0 16px 0;
							}
							&:last-child{
								font-size: 20px;
								color: rgba(255, 255, 255, 0.8);
							}
						}
						
					}
				}
			}
			
			
		}
		.detailMiddle{
			background: url("../../../assets/image/productCenter/product/networkCommunicationEquipment/bg_tedian.png") no-repeat;
			background-size: 1920px 718px;
			>div{
				width: 1200px;
				margin: 0 auto;
			}

			.title{
				font-weight: bold;
				color: #333333;
				font-size: 32px;
				padding: 60px 0 53px 0;
			}
			.characteristicsList{
				width: 100%;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				.characteristicsItem{
					text-align: left;
					width: 50%;
					padding-bottom: 49px;
					.svg-icon{
						width: 52px;
						height: 52px;
						display: inline-block;
						vertical-align: middle;
					}
					span{
						margin-left: 9px;
						display: inline-block;
						vertical-align: middle;
						width: 498px;
						color: #333;
						font-size: 22px;
						font-weight: bold;
					}
				}
			}
			
		}
		.detailBottom{
			margin-bottom: 60px;
			>div{
				width: 1200px;
				margin: 0 auto;
			}
			.title{
				margin: 70px 0 50px 0;
				font-weight: bold;
				color: #333333;
				font-size: 32px;
			}
			.productIndex{
				>div{
					&:last-child{
						>div{
							&:nth-child(2n){
								background: #F4F5F8;
							}
						}
					}
				}
				.indexRow{
					line-height: 54px;
					border: 1px solid #C4C4C4;
					border-top: none;
					.indexItem{
						display: inline-block;
						vertical-align:middle;
						font-weight: 500;
						color: #555555;
						font-size: 22px;
						width: 599px;
						&:first-child{
						}
						
						&:last-child{
							border-left: 1px solid #C4C4C4;
						}
					}
					&:first-child{
						border-top: 1px solid #C4C4C4;
					}
				}
				
			}

		}

		
	}
</style>