<template>
	<div class="NCE3">
		<div class="header wow animate__slideInLeft">
			<img src="../../../assets/image/productCenter/product/networkCommunicationEquipment/bg_detail_banner.png" alt="">
			<div @click="goBack">返回上一页</div>
		</div>
		<div class="detailTop">
			<div class="productName">能耗在线监测端设备</div>
			<div class="productItems">
				<div class="productItem">
					<div class="productImg">
						<img style="width: 47%;" src="../../../assets/image/productCenter/product/networkCommunicationEquipment/3-A.png" alt="">
					</div>
					<!-- <div class="name">
						<div>NM21NM1</div>
						<div>尺寸：275*44mm*174</div>
					</div> -->
				</div>
				<div class="productItem">
					<div class="productImg">
						<img src="../../../assets/image/productCenter/product/networkCommunicationEquipment/3-B.png" alt="">
					</div>
					<!-- <div class="name">
						<div>NM45</div>
						<div>尺寸：180*28*110mm</div>
					</div> -->
				</div>
			</div>
			<div class="productIntroduction">
				<p>能耗在线监测端设备(WDK-1000/V2.0)是为能耗在线监测系统定制的高安全性隔离设备。它支持工业网闸的基础访问控制，并提供针对主流工业协议和规约的摆渡支持，能阻断病毒传播和黑客攻击。</p>
				<p>能耗在线监测端设备支持多种网络通讯方式，人机交互界面友好。具备强大的EMC防护能力能够在恶劣电磁环境下高效稳定运行独立双主机“2+1”架构非IP物理隔离能有效保障网络和数据安全。</p>
				<p>它遵守 GB/T 20279《信息安全技术网络和终端设备隔离部件安全技术要求》相关规定，且通过国家认可的第三方检验检测机构的检测认证，并取得计算机信息系统安全专用产品销售许可证。</p>
				<p>同时也遵守 GB/T 17626《电磁兼容试验和测量技术系列标准》中相关规定，满足国家节能中心发布的《重点用能单位能耗在线监测端设备接口协议规范》及《重点用能单位能耗在线监测端设备检测规范》中对端设备应用软件的相关规定。</p>
			</div>
		</div>
		<div class="detailMiddle">
			<div>
				<div class="title">产品特点</div>
				<div class="characteristicsList">
					<div class="characteristicsItem" v-for="item,index in characteristicsList" :key="index">
						<svg-icon :icon-class="item.svg" ></svg-icon>
						<span>{{item.text}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		WOW
	} from "wowjs"
	export default {
		data() {
			return {
				wow: null,
				characteristicsList:[{
					svg:'ic_tedian_1',
					text:"稳定可靠的系统平台:工业级独立双主板架构;高速背板总线及电气隔离板卡设计，隔离电压2000Vrms;"
				},
				{
					svg:'ic_tedian_2',
					text:"稳定可靠的系统平台:CPU 低功耗无风扇设计，智能状态监测;2kV 隔离通讯设计，整机通过EMC III 级检验认证;"

				},
				{
					svg:'ic_tedian_3',
					text:"简洁高效的配置工具：以企业为单位，集成采集器仪表计量信息等参数快速构建数据采集与上传信息通道；支持批量复制参数，快速构建项目"
				},
				{
					svg:'ic_tedian_5',
					text:"丰富的软件功能：采集通讯协议；支持IEC 61850EC60870-5、ModbusDL/T 645、CJ/T 188OPC UA/DA等标准规范，每个接口独立可配置；"
				},
				{
					svg:'ic_tedian_8',
					text:"安全的信息摆渡：信息摆渡:专有自定义文件格式，单向传输；信息加密:文件加密摆渡转发，数据有效性验证与文件解析；信息交互:内网至外网的数据单向传输"
				},{
					svg:'ic_tedian_7',
					text:"最大可支持计量点数:100000 点；配置/维护接口:具有本地接口和远程维护功能；多数据中心转发:最多同时支持5个数据中心上传数据"
				}]
			}
		},
		created() {

		},
		mounted() {
			this.wow = new WOW({
				boxClass: 'wow', // 添加动画的元素的标识
				animateClass: 'animate__animated', // 动画时长
				offset: 0, // 与可视区域底部的距离显示
				mobile: true, // 移动端是否可用
				live: true // 对后面添加的新元素是否有效
			}).init();
		},
		methods: {
			goBack() {
				this.$router.back()
			},
		}
	}
</script>

<style lang="scss">
	.NCE3 {
		.header {
			width: 1903px;
			margin: 0 auto;
			img {
				width: 100%;
			}

			position: relative;

			div {
				cursor: pointer;
				position: absolute;
				bottom: 4px;
				left: 360px;
				width: 120px;
				height: 40px;
				background: #4495FF;
				line-height: 40px;
				color: #fff;
				font-size: 16px;
			}
		}
		.detailTop{
			width: 1200px;
			margin: 0 auto;
			margin-bottom: 50px;
			.productName{
				font-size: 40px;
				font-weight: bold;
				color: #333;
				margin: 63px 0;
			}
			.productItems{
				display: flex;
				justify-content: space-between;
				.productItem{
					width: 580px;
					// height: 331px;
					background: #FFFFFF;
					box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
					border-radius: 16px 16px 16px 16px;
					.productImg{
						height: 229px;
						position: relative;
						img{
							width: 40%;
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
						}
					}
					.name{
						height: 107px;
						background: #2A61BF;
						border-radius: 0px 0px 16px 16px;
						div{
							&:first-child{
								font-size: 24px;
								color: #FFFFFF;
								padding: 21px 0 16px 0;
							}
							&:last-child{
								font-size: 20px;
								color: rgba(255, 255, 255, 0.8);
							}
						}
						
					}
				}
			}
			.productIntroduction{
				margin-top: 40px;
				p{
					text-indent: 2em;
					font-size: 22px;
					color: #333333;
					text-align: left;
					line-height: 40px;
					letter-spacing: 1px;
					font-weight: 600;
				}
			}
			
		}
		.detailMiddle{
			margin-bottom: 11px;
			background: url("../../../assets/image/productCenter/product/networkCommunicationEquipment/bg_tedian.png") no-repeat;
			background-size: 1920px 718px;
			>div{
				width: 1200px;
				margin: 0 auto;
			}

			.title{
				font-weight: bold;
				color: #333333;
				font-size: 32px;
				padding: 60px 0 53px 0;
			}
			.characteristicsList{
				width: 100%;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				.characteristicsItem{
					text-align: left;
					width: 50%;
					padding-bottom: 49px;
					.svg-icon{
						width: 52px;
						height: 52px;
						display: inline-block;
						vertical-align: middle;
					}
					span{
						margin-left: 9px;
						display: inline-block;
						vertical-align: middle;
						width: 498px;
						color: #333;
						font-size: 22px;
						font-weight: bold;
					}
				}
			}
			
		}
	}
</style>