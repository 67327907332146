import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home/index.vue'
import solution from '../views/solution/index.vue'
import baseStation from '../views/solution/baseStation.vue'
import culturalRelics from '../views/solution/culturalRelics.vue'
import elderlyCare from '../views/solution/elderlyCare.vue'
import fireProtection from '../views/solution/fireProtection.vue'
import productCenter from '../views/productCenter/index.vue'
import NCE1 from '../views/productCenter/networkCommunicationEquipment/NCE1.vue'
import NCE2 from '../views/productCenter/networkCommunicationEquipment/NCE2.vue'
import NCE3 from '../views/productCenter/networkCommunicationEquipment/NCE3.vue'
import NCE4 from '../views/productCenter/networkCommunicationEquipment/NCE4.vue'
import NCE5 from '../views/productCenter/networkCommunicationEquipment/NCE5.vue'
import AE1 from '../views/productCenter/automotiveElectronics/AE1.vue'
import AE2 from '../views/productCenter/automotiveElectronics/AE2.vue'
import AE3 from '../views/productCenter/automotiveElectronics/AE3.vue'
import AE4 from '../views/productCenter/automotiveElectronics/AE4.vue'
import AE5 from '../views/productCenter/automotiveElectronics/AE5.vue'
import AE6 from '../views/productCenter/automotiveElectronics/AE6.vue'
import EE1 from '../views/productCenter/elderlyEquipment/EE1.vue'
import EE2 from '../views/productCenter/elderlyEquipment/EE2.vue'
import EE3 from '../views/productCenter/elderlyEquipment/EE3.vue'
import EE4 from '../views/productCenter/elderlyEquipment/EE4.vue'
import EE5 from '../views/productCenter/elderlyEquipment/EE5.vue'
import EE6 from '../views/productCenter/elderlyEquipment/EE6.vue'

import aboutUs from '../views/aboutUs/index.vue'
import enterpriseQualification from '../views/enterpriseQualification/index.vue'
import newsDynamic from '../views/newsDynamic/index.vue'
import newsDetail from '../views/newsDynamic/newsDetail.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/solution',//解决方案
    name: 'solution',
    component: solution
  },
  {
    path: '/baseStation',//通讯机房
    name: 'solution',
    component: baseStation
  },
  {
    path: '/culturalRelics',//文物安全
    name: 'solution',
    component: culturalRelics
  },
  {
    path: '/elderlyCare',//智慧养老
    name: 'solution',
    component: elderlyCare
  },
  {
    path: '/fireProtection',//智慧消防
    name: 'solution',
    component: fireProtection
  },
  {
    path: '/productCenter',//产品中心
    name: 'productCenter',
    component: productCenter
  },
  {
    path: '/NCE1',//网络通信设备产品1
    name: 'productCenter',
    component: NCE1
  },
  {
    path: '/NCE2',//网络通信设备产品2
    name: 'productCenter',
    component: NCE2
  },
  {
    path: '/NCE3',//网络通信设备产品3
    name: 'productCenter',
    component: NCE3
  },
  {
    path: '/NCE4',//网络通信设备产品4
    name: 'productCenter',
    component: NCE4
  },
  {
    path: '/NCE5',//网络通信设备产品5
    name: 'productCenter',
    component: NCE5
  },
  {
    path: '/AE1',//汽车电子产品1
    name: 'productCenter',
    component: AE1
  },
  {
    path: '/AE2',//汽车电子产品2
    name: 'productCenter',
    component: AE2
  },
  {
    path: '/AE3',//汽车电子产品3
    name: 'productCenter',
    component: AE3
  },
  {
    path: '/AE4',//汽车电子产品4
    name: 'productCenter',
    component: AE4
  },
  {
    path: '/AE5',//汽车电子产品5
    name: 'productCenter',
    component: AE5
  },
  {
    path: '/AE6',//汽车电子产品6
    name: 'productCenter',
    component: AE6
  },
  {
    path: '/EE1',//养老产品1
    name: 'productCenter',
    component: EE1
  },
  {
    path: '/EE2',//养老产品2
    name: 'productCenter',
    component: EE2
  },
  {
    path: '/EE3',//养老产品3
    name: 'productCenter',
    component: EE3
  },
  {
    path: '/EE4',//养老产品4
    name: 'productCenter',
    component: EE4
  },
  {
    path: '/EE5',//养老产品5
    name: 'productCenter',
    component: EE5
  },
  {
    path: '/EE6',//养老产品6
    name: 'productCenter',
    component: EE6
  },
  {
    path: '/aboutUs',//关于我们
    name: 'aboutUs',
    component: aboutUs
  },
  {
    path: '/enterpriseQualification',//企业资质
    name: 'enterpriseQualification',
    component: enterpriseQualification
  },
  {
    path: '/newsDynamic',//新闻动态
    name: 'newsDynamic',
    component: newsDynamic
  },
  {
    path: '/newsDetail',//新闻详情
    name: 'newsDetail',
    component: newsDetail
  },
  
]

const router = new VueRouter({
  routes
})
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0)
})
export default router
