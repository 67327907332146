<template>
	<div class="NCE1">
		<div class="header wow animate__slideInLeft">
			<img src="../../../assets/image/productCenter/product/networkCommunicationEquipment/bg_detail_banner.png" alt="">
			<div @click="goBack">返回上一页</div>
		</div>
		<div class="detailTop">
			<div class="productName">SD-WAN终端-NH03</div>
			<div class="productItems">
				<div class="productItem">
					<div class="productImg">
						<img src="../../../assets/image/productCenter/product/networkCommunicationEquipment/1-A.png" alt="">
					</div>
					<div class="name">
						<div>NH03 11寸机箱</div>
						<div>尺寸：275*174*44mm</div>
					</div>
				</div>
				<div class="productItem">
					<div class="productImg">
						<img src="../../../assets/image/productCenter/product/networkCommunicationEquipment/1-B.png" alt="">
					</div>
					<div class="name">
						<div>NH03P 19寸机箱</div>
						<div>尺寸：263*440*44mm</div>
					</div>
				</div>
			</div>
		</div>
		<div class="detailMiddle">
			<div>
				<div class="title">产品特点</div>
				<div class="characteristicsList">
					<div class="characteristicsItem" v-for="item,index in characteristicsList" :key="index">
						<svg-icon :icon-class="item.svg" ></svg-icon>
						<span>{{item.text}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="detailBottom">
			<div>
				<div class="title">产品指标</div>
				<div class="productIndex">
					<div class="indexHeader">
						<div>硬件平台</div>
						<div>NH03系列</div>
					</div>
					<div>
						<div class="indexRow">
								<div class="indexItem">CPU+4:34:31</div>
								<div class="indexItem">NXp LS1023/LS1043</div>
								<div class="indexItem">NXP LS1043商业级</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">CPU主频</div>
								<div class="indexItem">1.6Ghz</div>
								<div class="indexItem">1.6Ghz</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">CPU核数</div>
								<div class="indexItem">2/4核A53</div>
								<div class="indexItem">4核A53</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">内存</div>
								<div class="indexItem">落板,不支持ECC1/2/4/8GB32bit位宽DDR41600Mbps</div>
								<div class="indexItem">落板,不支持ECC1/2/4/8GB32bit位宽DDR4 1600Mbps</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">SPI FLASH</div>
								<div class="indexItem">8MB</div>
								<div class="indexItem">8MB</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">EMMC容量</div>
								<div class="indexItem">4/8/16GB</div>
								<div class="indexItem">16GB</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">固定业务接口</div>
								<div class="indexItem">4GE+2COMBO</div>
								<div class="indexItem">4GE+2COMBO</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">硬件Bypass</div>
								<div class="indexItem">无</div>
								<div class="indexItem">1组电口</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">USB口·双层</div>
								<div class="indexItem">1*USB 2.0</div>
								<div class="indexItem">2*USB 2.0</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">Console口</div>
								<div class="indexItem">1*(RS232 RJ45)</div>
								<div class="indexItem">1*(RS232 RJ45)</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">WIFI模块</div>
								<div class="indexItem">选配</div>
								<div class="indexItem">选配</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">4G模块</div>
								<div class="indexItem">选配</div>
								<div class="indexItem">选配</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">5G模块</div>
								<div class="indexItem">选配</div>
								<div class="indexItem">选配</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">SIM卡( 可选)</div>
								<div class="indexItem">弹片式*2</div>
								<div class="indexItem">弹片式*2</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">天线( 可选)</div>
								<div class="indexItem">
									11寸机箱最高支持7根
									19寸机箱支持6根
								</div>
								<div class="indexItem">
									11寸机箱最高支持7根
									19寸机箱支持6根
								</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">电源1+1备份</div>
								<div class="indexItem">19寸机箱支持双路冗余电源设计</div>
								<div class="indexItem">19寸机箱支持双路冗余电源设计</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">输入额定电压</div>
								<div class="indexItem">100-240V交流</div>
								<div class="indexItem">100-240V交流</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">最大功率供给(W)</div>
								<div class="indexItem">11寸机箱40W,19寸机箱40W</div>
								<div class="indexItem">11寸机箱40W,19寸机箱40W</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">平均无故障时间(MTBF)</div>
								<div class="indexItem">≥100,000小时</div>
								<div class="indexItem">≥100,000小时</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">风扇</div>
								<div class="indexItem">19寸机箱支持</div>
								<div class="indexItem">19寸机箱支持</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">温度</div>
								<div class="indexItem">存储温度-40~70°工作温度0~40°C</div>
								<div class="indexItem">存储温度-40~70°工作温度0~40°C</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">湿度</div>
								<div class="indexItem">工作5%-90%非凝露</div>
								<div class="indexItem">工作5%-90%非凝露</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">外形尺寸（长X宽X高/mm)</div>
								<div class="indexItem">19寸机箱:263*440*44mm</div>
								<div class="indexItem">19寸机箱:263*440*44mm</div>
							</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		WOW
	} from "wowjs"
	export default {
		data() {
			return {
				wow: null,
				characteristicsList:[{
					svg:'ic_tedian_1',
					text:"NXPLS10 3/LS1043通信级高性能ARM平台,2/4Cores"
				},
				{
					svg:'ic_tedian_2',
					text:"预留3个MINIPCIE接口，支持4G/WIFI板贴SIM卡槽"

				},
				{
					svg:'ic_tedian_3',
					text:"预留M.2接口，支持5G"
				},
				{
					svg:'ic_tedian_4',
					text:"LS1023/LS1043集成DPAA加速引擎提供更高网络转发性能和多种硬件加解密加速能力"
				},
				{
					svg:'ic_tedian_5',
					text:"适于防火墙VP网关上网行为管理应用交付SD-WAN等多种应用场景"
				},
				{
					svg:'ic_tedian_7',
					text:"板载DDR4颗粒，支持1GB/2GB/4GB/8GB"
				},
				{
					svg:'ic_tedian_8',
					text:"固化4个千兆电口和2个千兆COMBO口"
				},
				{
					svg:'ic_tedian_9',
					text:"支持桌面、1U，单电/双电等多种产品形态"
				},
				{
					svg:'ic_tedian_10',
					text:"NH03较传统低端X86产品，有更高的包转发性能，提供更多高可靠性设计(无风扇设计、板载内存及存储等)，并具有更优成本"
				},]
			}
		},
		created() {

		},
		mounted() {
			this.wow = new WOW({
				boxClass: 'wow', // 添加动画的元素的标识
				animateClass: 'animate__animated', // 动画时长
				offset: 0, // 与可视区域底部的距离显示
				mobile: true, // 移动端是否可用
				live: true // 对后面添加的新元素是否有效
			}).init();
		},
		methods: {
			goBack() {
				this.$router.back()
			},
		}
	}
</script>

<style lang="scss">
	.NCE1 {
		.header {
			width: 1903px;
			margin: 0 auto;
			img {
				width: 100%;
			}

			position: relative;

			div {
				cursor: pointer;
				position: absolute;
				bottom: 4px;
				left: 360px;
				width: 120px;
				height: 40px;
				background: #4495FF;
				line-height: 40px;
				color: #fff;
				font-size: 16px;
			}
		}
		.detailTop{
			width: 1200px;
			margin: 0 auto;
			margin-bottom: 50px;
			.productName{
				font-size: 40px;
				font-weight: bold;
				color: #333;
				margin: 63px 0;
			}
			.productItems{
				display: flex;
				justify-content: space-between;
				.productItem{
					width: 580px;
					height: 331px;
					background: #FFFFFF;
					box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
					border-radius: 16px 16px 16px 16px;
					.productImg{
						height: 229px;
						position: relative;
						img{
							width: 60%;
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
						}
					}
					.name{
						height: 107px;
						background: #2A61BF;
						border-radius: 0px 0px 16px 16px;
						div{
							&:first-child{
								font-size: 24px;
								color: #FFFFFF;
								padding: 21px 0 16px 0;
							}
							&:last-child{
								font-size: 20px;
								color: rgba(255, 255, 255, 0.8);
							}
						}
						
					}
				}
			}
			
			
		}
		.detailMiddle{
			background: url("../../../assets/image/productCenter/product/networkCommunicationEquipment/bg_tedian.png") no-repeat;
			background-size: 1920px 718px;
			>div{
				width: 1200px;
				margin: 0 auto;
			}

			.title{
				font-weight: bold;
				color: #333333;
				font-size: 32px;
				padding: 60px 0 53px 0;
			}
			.characteristicsList{
				width: 100%;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				.characteristicsItem{
					text-align: left;
					width: 50%;
					padding-bottom: 49px;
					.svg-icon{
						width: 52px;
						height: 52px;
						display: inline-block;
						vertical-align: middle;
					}
					span{
						margin-left: 9px;
						display: inline-block;
						vertical-align: middle;
						width: 498px;
						color: #333;
						font-size: 22px;
						font-weight: bold;
					}
				}
			}
			
		}
		.detailBottom{
			margin-bottom: 60px;
			>div{
				width: 1200px;
				margin: 0 auto;
			}
			.title{
				margin: 70px 0 50px 0;
				font-weight: bold;
				color: #333333;
				font-size: 32px;
			}
			.productIndex{
				.indexHeader{
					height: 69px;
					background: #0155C4;
					line-height: 69px;
					font-size: 26px;
					font-weight: 500;
					color: #FFF;
					>div{
						display: inline-block;
						&:first-child{
							width: 288px;
							border-right: 1px solid #C4C4C4;
						}
						&:last-child{
							width: 912px;
						}
					}
				}
				>div{
					&:last-child{
						>div{
							&:nth-child(2n){
								background: #F4F5F8;
							}
						}
					}
				}
				.indexRow{
					line-height: 54px;
					border: 1px solid #C4C4C4;
					border-top: none;
					.indexItem{
						// border-bottom: 1px solid #C4C4C4;
						display: inline-block;
						vertical-align:middle;
						font-weight: 500;
						color: #555555;
						font-size: 22px;
						&:first-child{
							width: 286px;
						}
						&:nth-child(2){
							width: 467px;
							border-left: 1px solid #C4C4C4;
							border-right: 1px solid #C4C4C4;
						}
						&:last-child{
							width: 445px;
						}
					}
				}
				
			}

		}

		
	}
</style>