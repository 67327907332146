<template>
  <div class="technical">
    <div class="depth">
      <div class="technical_head wow animate__slideInUp">
        <!-- <div class="dot"></div> -->
        <h2 class="title">软件与技术服务</h2>
        <p class="modifier">SOFTWARE AND TECHNICAL SERVICES</p>
      </div>
      <div class="technical_body wow animate__slideInUp">
        <div class="technical_item" v-for="item in technicalList" :key="item.title" :style="`background: url(${item.bgc}) no-repeat`">
          <div class="technical_conter">
			<svg-icon :icon-class="item.icon" ></svg-icon>
            <!-- <img class="icon" :src="item.icon" alt=""> -->
            <p class="title">{{item.title}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      technicalList: [
        {
          title: '基础服务',
          icon: 'ic_found',
          bgc: require('../../../assets/image/bg_found.png')
        },
        {
          title: '系统集成',
          icon: 'ic_system',
          bgc: require('../../../assets/image/bg_system.png')
        },
        {
          title: '运维运营',
          icon: 'ic_yunwei',
          bgc: require('../../../assets/image/bg_yunwei.png')
        },
        {
          title: '安全服务',
          icon: 'ic_safe',
          bgc: require('../../../assets/image/bg_safe.png')
        },
        {
          title: '适配测试',
          icon: 'ic_shipei',
          bgc: require('../../../assets/image/bg_shipei.png')
        },
        {
          title: '软件开发',
          icon: 'ic_software',
          bgc: require('../../../assets/image/bg_software.png')
        },
        {
          title: '技术支持',
          icon: 'ic_technologe',
          bgc: require('../../../assets/image/bg_technologe.png')
        },
        {
          title: '一致性服务',
          icon: 'ic_service',
          bgc: require('../../../assets/image/bg_service.png')
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .technical {
    width: 100%;
    height: 970px;
    background: url('../../../assets/image/bg_technical.png') no-repeat;
    overflow: hidden;
    .depth {
      width: 1200px;
      margin: 0 auto;
      .technical_head {
        margin-top: 65px;
        .dot {
          margin: 0 auto;
          height: 4px;
          width: 24px;
          background-color: #427BED;
          border-radius: 2px;
        }
        .title {
          margin: 12px 0 15px 0;
          font-size: 40px;
          color: #333;
        }
        .modifier {
          font-size: 24px;
          color: #666;
        }
      }
      .technical_body {
        margin-top: 55px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .technical_item {
			cursor: pointer;
          margin-bottom: 34px;
          width: 278px;
          height: 312px;
          border-radius: 15px;
          overflow: hidden;
          transition: all .5s;
          .technical_conter {
            width: 100%;
            height: 100%;
            transition: all .5s;
            overflow: hidden;
            .svg-icon {
              display: block;
              margin: 56px auto 32px;
              width: 98px;
              height: 98px;
            }
            .title {
              font-size: 24px;
              color: #fff;
            }
          }
          &:hover {
            transform: translateY(-10px);
            .technical_conter {
              background-color: rgba(8, 104, 232, 0.8);
            }
          }
        }
      }
    }
  }
</style>