import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'animate.css';
import 'wowjs/css/libs/animate.css'
import "./utils/swiper.min.css"
import "./utils/swiper-bundle.min.css"

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// //svg图标的设置
// import '@/assets/icon'
// import svgIcon from '@/assets/icon/Svg.vue'
// Vue.component('svg-icon', svgIcon)
import './icons'
Vue.config.productionTip = false

Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
