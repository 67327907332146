<template>
  <div class="banner">
    <ul class="swiper-wrapper">
      <li class="swiper-slide">
        <img
          src="../../../assets/image/bg_home_banner.png"
          alt=""
        />
      </li>
      <li class="swiper-slide">
        <img
          src="../../../assets/image/bg_home_banner2.png"
          alt=""
        />
      </li>
      <li class="swiper-slide">
        <img
          src="../../../assets/image/bg_home_banner3.png"
          alt=""
        />
      </li>
    </ul>
    <div class="swiper-pagination sorter"></div>
  </div>
</template>

<script>
import Swiper from "@/utils/swiper.min.js";
export default {
  data() {
    return {
      swiper: null,
    };
  },
  mounted() {
    this.initSwiper();
  },
  methods: {
    initSwiper() {
      this.swiper = new Swiper(".banner", {
        autoplay: {
          delay: 3000, // 时间间隔
          disableOnInteraction: false, // 用户操作之后是否停止轮播
        },
        pagination: {
          el: ".swiper-pagination",
		  clickable: true,
        },
        loop: true, // 循环播放
		// pagination: {
		// // el: '.swiper-banner-pagination',
		// // clickable: true,
		// }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .banner {
    position: relative;
    // width: 1903px;
	// width: 100%;
    // height: 850px;
    overflow: hidden;
    .swiper-slide {
      img {
        width: 100%;
        // height: 100%;
      }
    }
    .sorter {
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translateX(-20%);
    }
  }
</style>
<style>
  .swiper-pagination-bullet {
    width: 20px !important;
    height: 20px !important;
    margin: 0 10px !important;
    background-color: rgba(255, 255, 255, .6) !important;
  }
  .swiper-pagination-bullet-active {
    background-color: #fff !important;
  }
</style>
