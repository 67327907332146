<template>
  <div class="home">
    <!-- 轮播图 -->
    <banner />
	<template >
		<!-- 产品中心 -->
		<div class="product wow animate__slideInUp">
			<a id="productCenter" style="position: relative;top: -80px;"></a>
		  <div class="productTop">
			  <div class="product_head wow animate__slideInUp">
			    <h2 class="title">产品中心</h2>
			    <p class="modifier">PRODUCT CENTER</p>
			  </div>
			  <div class="product_body wow animate__slideInUp">
			    <div class="nav">
			      <div class="nav_item" :class="activeProductNav == item.name ? 'activeProductNav' : ''" v-for="item in productNavList" :key="item.name" @click="isProductNav(item.name)">
				   <svg-icon :icon-class="item.icon" ></svg-icon>
			       <div class="productItemName"> {{item.name}}</div>
			      </div>
			    </div>
			  </div>
		  </div>
		  <div class="productBottom" v-if="productList.length>0">
			  <div class="product_content">
				  <div class="swiper-container product-container">
					  <div class="swiper-wrapper" >
						<div :class="index==0?'swiper-slide swiper-slide-center none-effect':'swiper-slide'" v-for="item,index in productList" :key="item.name" v-if="productVisible">
							<div class="product_item" @click="goUrl(item.path)">
								<div class="product_img">
									<img :src="item.img" alt="">
								</div>
								<p class="product_name">
								  {{item.name}}
								</p>
							</div>
						</div>
					  </div>
					  <div class="button">
						 <div class="swiper-button-prev">
							 <svg-icon icon-class="ic_left" ></svg-icon>
							 
						 </div>
						 <div class="swiper-button-next">
							 <svg-icon icon-class="ic_right" ></svg-icon>
						 </div>
					  </div>
				   </div>
			  </div>
		  	<div class="product_bottom" @click="skipPage('/productCenter')">
		  	  <div class="bottom_btn">
		  	    了解更多
		  	    <i class="el-icon-arrow-right"></i>
		  	  </div>
		  	</div>
		  </div>
		</div>
		<!-- 解决方案 -->
		<div class="scheme">
			<a id="solution" style="position: relative;top: -80px;"></a>
		  <div class="depth">
		    <div class="scheme_head wow animate__slideInUp">
		      <!-- <div class="dot"></div> -->
		      <h2 class="title">解决方案</h2>
		      <p class="modifier">SOLUTION PROGRAME</p>
		    </div>
		    <div class="scheme_content wow animate__slideInUp">
		      <div :class="`scheme_item wow animate__slideIn${(index+1)%2==1?'Left':'Right'}`" v-for="item,index in schemeList" :key="item.title" @click="skipPage(item.path)">
				  <img :src="item.img" alt="">
				  <div class="scheme_item_content">
				  	<div class="title">
				  	  {{item.title}}
				  	</div>
				  	<div class="content">
				  	  {{item.content}}
				  	</div>
				  	<div class="bottom_btn">
				  	  了解更多
				  	  <i class="el-icon-arrow-right"></i>
				  	</div>
				  </div>
		      </div>
		    </div>
		  </div>
		</div>
		<!-- 技术服务 -->
		<technical />
		<!-- 企业资质 -->
		<qualification />
		<!-- 合作伙伴 -->
		<div class="partner wow animate__slideInUp">
		  <div class="partner_head wow animate__slideInUp">
		    <!-- <div class="dot"></div> -->
		    <h2 class="title">合作伙伴</h2>
		    <p class="modifier">Cooperative partner</p>
		  </div>
		  <div class="partner_content wow animate__slideInUp">
		    <div class="partner_item wow animate__shake" v-for="item,index in partnerList" :key="index">
		      <img :src="item.img" alt="">
		    </div>
		  </div>
		</div>
	</template>


  </div>
</template>

<script>
import banner from "./components/banner.vue"
import technical from "./components/technical.vue"
import qualification from "./components/qualification.vue"
import { WOW } from "wowjs"
import Swiper from "@/utils/swiper-bundle.min.js";

export default {
  components: {
    banner,
    technical,
    qualification
  },
  data(){
    return {
	  show:false,
	  swiper: null,
      wow: null,
      activeProductNav: "全部产品",
      productNavList: [
		{
		  name: '全部产品',
		  icon:'ic_product_select'
		},
		{
		  name: '网络通讯设备',
		  icon:'ic_tongxin'
		},
		{
		  name: '汽车电子',
		  icon:'ic_car'
		},
        {
          name: '智能养老设备',
		  icon:'ic_old'
        },
      ],
	  automotiveElectronicsList:[
	  		  {
	  		    img: require('../../assets/image/productCenter/product/automotiveElectronics/1-A.png'),
	  		    name: '车载tbox',
	  			path:'/AE1',
	  			width:'70%'
	  		  },
	  		  {
	  		    img: require('../../assets/image/productCenter/product/automotiveElectronics/2-A.png'),
	  		    name: '车载多合一中控',
	  			path:'/AE2',
	  			width:'70%'
	  		  },
	  		  {
	  		    img: require('../../assets/image/productCenter/product/automotiveElectronics/3-A.png'),
	  		    name: '风暖控制器',
	  			path:'/AE3',
	  			width:'70%'
	  		  },
	  		  {
	  		    img: require('../../assets/image/productCenter/product/automotiveElectronics/4-A.png'),
	  		    name: '水暖控制器',
	  			path:'/AE4',
	  			width:'70%'
	  		  },
	  		  {
	  		    img: require('../../assets/image/productCenter/product/automotiveElectronics/5-A.png'),
	  		    name: '车身控制器',
	  			path:'/AE5',
	  			width:'70%'
	  		  },
	  		  {
	  		    img: require('../../assets/image/productCenter/product/automotiveElectronics/6-A.png'),
	  		    name: '智能座舱域控制器',
	  			path:'/AE6',
	  			width:'70%'
	  		  },
	  ],
	  networkCommunicationEquipmentList:[
	  		  {
	  		    img: require('../../assets/image/productCenter/product/pic_sd.png'),
	  		    name: 'SD-WAN终端-NH03',
	  		  	path:'/NCE1'
	  		  },
	  		  {
	  		    img: require('../../assets/image/productCenter/product/pic_nm.png'),
	  		    name: 'SD-WAN终端-NM21',
	  		  	path:'/NCE2'
	  		  },
	  		  {
	  		    img: require('../../assets/image/productCenter/product/networkCommunicationEquipment/3-A.png'),
	  		    name: '能耗在线监测端设备',
	  		  	path:'/NCE3',
	  		  	width:'70%'
	  		  },
	  		  {
	  		    img: require('../../assets/image/productCenter/product/networkCommunicationEquipment/4-A.png'),
	  		    name: '网闸设备',
	  		  	path:'/NCE4',
	  		  	width:'75%'
	  		  },
	  		  {
	  		    img: require('../../assets/image/productCenter/product/networkCommunicationEquipment/5-A.png'),
	  		    name: 'CPE设备（NK06)',
	  		  	path:'/NCE5',
	  		  	width:'75%'
	  		  },
	  ],
	  elderlyEquipment:[
	  		  {
	  		     img: require('../../assets/image/productCenter/product/elderlyEquipment/1-A.png'),
	  		    name: '健康体检一体机',
	  		  	path:'/EE1',
	  			width:'55%'
	  		  },
	  		  {
	  		     img: require('../../assets/image/productCenter/product/elderlyEquipment/2-A.png'),
	  		    name: '小度养老智慧屏',
	  		  	path:'/EE2',
	  			width:'55%'
	  		  },
	  		  {
	  		     img: require('../../assets/image/productCenter/product/elderlyEquipment/3-A.png'),
	  		    name: '智能手环',
	  		  	path:'/EE3',
	  		  },
	  		  {
	  		     img: require('../../assets/image/productCenter/product/elderlyEquipment/4-A.png'),
	  		    name: '燃气报警器',
	  		  	path:'/EE4'
	  		  },
	  		  {
	  		     img: require('../../assets/image/productCenter/product/elderlyEquipment/5-A.png'),
	  		    name: '水浸传感器',
	  		  	path:'/EE5'
	  		  },
	  		  {
	  		     img: require('../../assets/image/productCenter/product/elderlyEquipment/6-A.png'),
	  		    name: '烟感报警器',
	  		  	path:'/EE6'
	  		  },
	  ],
      productList: [
      ],
      schemeList: [
        {
		  img:require('../../assets/image/index/scheme/bg_wenwu.png'),
          title: '文物安全监管解决方案',
          content: '文保单位安全综合管理云平台采用基于云端的移动化办公管理软件，利用互联网、云计算、物联网等技术，实现安全管理云平台、PC端、移动端（小程序、公众号）已经各安全子系统之间的互联互通，打造智慧化的文保单位安全管理方式。文物安全监管系统由文物安全监管云平台、智慧文保小程序组成。',
		  path:'/culturalRelics'
        },
        {
			img:require('../../assets/image/index/scheme/bg_xiaofnag.png'),
          title: '智慧消防解决方案',
          content: '智慧消防安全管理平台是针对智慧消防领域，借助先进的移动互联网、物联网、传感器、大数据、云计算、人工智能等技术，充分利用现有安防系统，基于运营商成熟网络，服务于政府主管部门、一线工作人员。社会单位以及社会群众。',
		  path:'/fireProtection'
        },
        {
			img:require('../../assets/image/index/scheme/bg_xiangzhen.png'),
          title: '智慧养老解决方案',
          content: '智慧养老服务平台提供社区养老、居家养老、机构养老、民政监管、智慧康养、医养结合等“互联网+养老”智慧应用。平台以数据为核心，通过数据共享使所有参与方全面掌握老人情况，形成规范且连续的养老大数据，通过服务机构、社区、养老机构、医疗资源与政府监管平台的有机结合，服务区域养老服务生态发展。',
		  path:'/elderlyCare'
        },
        {
			img:require('../../assets/image/index/scheme/bg_jifang.png'),
          title: '通信机房、基站节能解决方案',
          content: '通信系统基站、机房的能耗问题已经成为通信运营商发展的难题，基站、机房的节能减排工作是目前关注焦点。本方案将通过对基站机构进行优化，使用新技术降低基站的能源消耗，促进通信系统可持续发展。',
		  path:'/baseStation'
        },
      ],
	  partnerList:[
		  {
		  	img:require('../../assets/image/index/partner/zhongxun.png'),
		  },
		  {
		  	img:require('../../assets/image/index/partner/chelian.png'),
		  },
		  {
		  	img:require('../../assets/image/index/partner/unicom.png'),
		  },
		  {
		  	img:require('../../assets/image/index/partner/zixun.png'),
		  },
		  {
		  	img:require('../../assets/image/index/partner/diankehuawei.png'),
		  },
		  {
		  	img:require('../../assets/image/index/partner/ali.png'),
		  },
		  {
		  	img:require('../../assets/image/index/partner/hik.png'),
		  },
		  {
		  	img:require('../../assets/image/index/partner/hua.png'),
		  },
		  {
		  	img:require('../../assets/image/index/partner/tengxun.png'),
		  },
		  {
		  	img:require('../../assets/image/index/partner/huawei.png'),
		  },
	  ],
	  productVisible:true
    }
  },
  created(){
    this.productList = this.networkCommunicationEquipmentList.concat(this.automotiveElectronicsList).concat(this.elderlyEquipment)
  },
  watch: {
  	$route:{
  	  handler(val,oldval){
  	    // console.log(val);//新路由信息
  	    // console.log(oldval);//老路由信息
		let anchorElement = document.getElementById(val.query.anchorPoint);
		/*如果对应id的锚点存在，就跳转到锚点*/
		if(anchorElement) {
		  anchorElement.scrollIntoView();
		}
  		// this.anchor(val.query.anchorPoint)
  	  },
  	  // 深度观察监听
  	  deep: true
  	}
  
  },
  mounted(){
    this.wow = new WOW({
      boxClass: 'wow', // 添加动画的元素的标识
      animateClass: 'animate__animated', // 动画时长
      offset: 0, // 与可视区域底部的距离显示
      mobile: true, // 移动端是否可用
      live: true // 对后面添加的新元素是否有效
    }).init();

	if(!localStorage.getItem('show')){
		setTimeout(()=>{
			this.show= true
			localStorage.setItem('show',true)
			this.initSwiper()
			if(this.$route.query.anchorPoint){
				/*锚链接跳转*/
				 /*找到锚点*/
				 let anchorElement = document.getElementById(this.$route.query.anchorPoint);
				 /*如果对应id的锚点存在，就跳转到锚点*/
				 if(anchorElement) {
				   anchorElement.scrollIntoView();
				 }
			}
		},2000)
	}else{
		this.show= true
		this.initSwiper()
		if(this.$route.query.anchorPoint){
			/*锚链接跳转*/
			 /*找到锚点*/
			 let anchorElement = document.getElementById(this.$route.query.anchorPoint);
			 /*如果对应id的锚点存在，就跳转到锚点*/
			 if(anchorElement) {
			   anchorElement.scrollIntoView();
			 }
		}
	}
	
	
  },
  methods: {
    isProductNav(name){
      this.activeProductNav = name
	  this.productVisible = false
	  if(this.swiper){
	  	this.swiper.destroy(true,true)
	  }
	  if(name=='网络通讯设备'){
		  this.productList = this.networkCommunicationEquipmentList
	  }else if(name=='汽车电子'){
		  this.productList = this.automotiveElectronicsList
	  }else if(name=='全部产品'){
	  	  this.productList = this.networkCommunicationEquipmentList.concat(this.automotiveElectronicsList).concat(this.elderlyEquipment)
	  }else{
		  this.productList  = this.elderlyEquipment
	  }
	  console.log(this.productList)
	  this.productVisible = true
	  this.$nextTick(()=>{
		  if(this.productList.length==0){
			  return
		  }
	  	this.initSwiper()
	  })
    },
	initSwiper() {
	   this.swiper = new Swiper('.product-container', {
        watchSlidesProgress: true,
        slidesPerView: "3",
        centeredSlides: true,
        loop: true,
        loopedSlides: 5,
        autoplay: {
          delay: 3000, // 时间间隔
          disableOnInteraction: false, // 用户操作之后是否停止轮播
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
	},
	// 跳转页面
	skipPage(path){
	  this.$router.push({path:path})
	},
	goUrl(path){
		this.$router.push({path})
	},
  }
}
</script>

<style lang="scss">

.product {
  margin: 0 auto;
  margin-top: 70px;
  .productTop{
	  margin: 0 auto;
	  width: 1200px;
	  .product_head {
	    .title {
	      margin: 12px 0 15px 0;
	      font-size: 40px;
	      color: #333;
		  
	    }
	    .modifier {
	      font-size: 24px;
	      color: #666;
		  margin-bottom: 55px;
	    }
	  }
	  .product_body {
	    margin-top: 26px;
	    .nav {
	      display: flex;
	      justify-content: space-between;
	      flex-wrap: wrap;
	      // height: 88px;
	      // line-height: 88px;
	      font-size: 20px;
	      color: #333333;
	      // border-bottom: 1px solid #ccc;
	      .nav_item {
			  width: 200px;
			.productItemName{
				margin: 22px 0 22px 0;
			}
			.svg-icon{
				font-size: 21px;
			}
	        cursor: pointer;
	        &:hover {
	          color: #0155C4;
	          &::after {
	            width: 100%;
	          }
	        }
	      }
	      .nav_item::after {
	        content: '';
	        display: block;
	        margin: -2px auto 0;
	        height: 2px;
	        width: 0%;
	        background-color: #0155C4;
	        border-radius: 2px;
	        transition: all .3s;
	      }
	      .activeProductNav {
	        color: #0155C4;
	        &::after {
	          width: 100%;
	        }
	      }
	    }
	  
	  }
  }
  .productBottom{
	  // padding-top: 60px;
	  height: 638px;
	  background: url("../../assets/image/index/bg_product.png") no-repeat;
	  .product_content {
		margin: 0 auto;
		width: 1200px;
	    display: flex;
		height: 590px;
	    justify-content: space-between;
	    flex-wrap: wrap;
	    transition: all .3s;
		.swiper-slide{
			width: 33.33% !important;
			text-align: center;
		}
		
	    .product_item {
		  cursor: pointer;
		  background-color: #fff;
		  margin-top: 60px;
		  display: inline-block;
	      width: 330px !important;
	      height: 446px;
	      border: 1px solid #0155C4;
	      border-radius: 10px;
	      box-shadow: 0px 0px 20px -1px #ccc;
	      transition: all .3s;
	      overflow: hidden;
	      .product_img {
	        height: 383px;
			position: relative;
			img{
				width: 70%;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}
	      }
	      .product_name {
	        font-size: 14px;
	        color: #98A8C7;
	        line-height: 67px;
	        text-align: center;
	        transition: all .3s;
			background-color: #EBF3FB;
	      }
	      &:hover {
	        transform: translateY(-5px);
	        border: 3px solid #0155C4;
	        box-shadow: 0px 0px 10px 5px #ccc;
	        .product_name {
	          color: #FFFFFF;
	          background-color: #0155C4;
	        }
	      }
	    }
		.swiper-button-next{
			right: 0px;
		}
		.swiper-button-prev{
			left: 0px;
		}
		.swiper-button-next,.swiper-button-prev {
			font-size: 27px;
			color: rgba(80, 148, 238, 0.3);
		  // right: -30px;
		  // width: 45px;
		  // height: 45px;
		  // background: url(../images/wm_button_icon.png) no-repeat;
		  // background-position: 0 -93px;
		  // background-size: 100%;
		  &::after{
			  content: '';
		  }
		  &:hover{
			  color: rgba(80, 148, 238, 1);
		  }
		}
	  }
	  .product_bottom {
		  z-index: 10;
		  position: relative;
		  width: 1200px;
		margin: 0 auto;
	    height: 44px;
		text-align: right;
		padding-right: 35px;
		top: -55px;
	    .bottom_btn {
			background-color: #fff;
			// margin-top: 22px;
		display: inline-block;
	      width: 140px;
	      height: 44px;
	      font-size: 14px;
	      line-height: 44px;
	      color: #0155C4;
	      border: 1px solid #0155C4;
	      border-radius: 22px;
	      cursor: pointer;
		  text-align: center;
	    }
	  }
  }
  
}
.scheme {
	margin-top: 66px;
  width: 100%;
  min-height: 974px;
  background: url("../../assets/image/index/bg_scheme.png") no-repeat;
  overflow: hidden;
  .depth {
    width: 1200px;
    margin: 0 auto;
    .scheme_head {
      margin-top: 75px;
      // .dot {
      //   margin: 0 auto;
      //   height: 4px;
      //   width: 24px;
      //   background-color: #427BED;
      //   border-radius: 2px;
      // }
      .title {
        // margin-top: 12px;
        font-size: 40px;
        color: #fff;
		margin-bottom: 15px;
      }
      .modifier {
        font-size: 20px;
        color: rgba(255, 255, 255, 0.6);
      }
    }
    .scheme_content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 55px;
      .scheme_item {
        position: relative;
        margin-bottom: 50px;
        width: 580px;
        min-height: 312px;
        text-align: left;
        border-radius: 16px;
        transition: all .3s;
		img{
			position: absolute;
			top: 0;
			left: 0;
		}
		.scheme_item_content{
			cursor: pointer;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			padding: 48px 48px 0;
			.title {
			  font-size: 36px;
			  color: #fff;
			  margin-bottom: 16px;
			  transition: all .3s;
			}
			.content {
				display: none;
			  font-size: 14px;
			  color: #98A8C7;
			  line-height: 24px;
			  transition: all .3s;
			}
			.bottom_btn {
			  position: absolute;
			  bottom: 48px;
			  left: 48px;
			  width: 86px;
			  height: 28px;
			  font-size: 12px;
			  text-align: center;
			  line-height: 28px;
			  color: #fff;
			  background-color: #0155C4;
			  border-radius: 22px;
			  transition: all .3s;
			  cursor: pointer;
			}
			&:hover {
				border-radius: 16px;
				background-color:rgba(1, 85, 196, 0.9);
			  // transform: translateY(-5px);
			  // border: 1px solid #427BED;
			  // box-shadow: 0px 0px 10px 5px #ccc;
			  .title {
				  font-size: 22px;
			    color: #fff;
			  }
			  .content {
				display: block;
			    color: #fff;
			  }
			  .bottom_btn {
			    display: none;
			  }
			}
		}

		
      }
    }
  }
}
.partner {
  .partner_head {
    margin-top: 70px;
    .dot {
      margin: 0 auto;
      height: 4px;
      width: 24px;
      background-color: #427BED;
      border-radius: 2px;
    }
    .title {
      margin: 0 0 15px 0;
      font-size: 40px;
      color: #333;
    }
    .modifier {
      font-size: 24px;
      color: #666;
    }
  }
  .partner_content {
	margin: 50px auto 0;
	padding-bottom: 36px;
	width: 1200px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .partner_item {
      width: 224px;
      height: 132px;
	  border: 1px solid #CECECE;
      overflow: hidden;
      transition: all .3s;
	  margin-bottom: 24px;
	  display: table-cell;
	  position: relative;
	  img{
		  position: absolute; 
		  left: 50%; 
		  top: 50%;
		  transform: translate(-50%, -50%);    /* 50%为自身尺寸的一半 */
	  }
	  &:hover {
	    transform: translateY(-5px);
		box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
	  }
    }
  }
}
.bottom {
  height: 100px;
}
@media only screen and (max-width: 1200px) {
	.home{
		width: 1200px ;
	}
  .scheme .depth {
    width: 100% ;
    .scheme_content {
      justify-content: space-around;
      .scheme_item {
		  // text-align: center;
        width:580px ;
		
      }
    }
  }
  .product .product_body {
    .nav {
      .nav_item {
        transition: all .3s; 
        font-size: 12px ;
      }
    }
    .product_content {
      // justify-content: center !important;
      .product_item {
        margin-bottom: 20px;
      }
    }
  }
}
</style>