<template>
	<div class="NCE2">
		<div class="header wow animate__slideInLeft">
			<img src="../../../assets/image/productCenter/product/networkCommunicationEquipment/bg_detail_banner.png" alt="">
			<div @click="goBack">返回上一页</div>
		</div>
		<div class="detailTop">
			<div class="productName">SD-WAN终端-NM21</div>
			<div class="productItems">
				<div class="productItem">
					<div class="productImg">
						<img style="width: 38%;" src="../../../assets/image/productCenter/product/networkCommunicationEquipment/2-A.png" alt="">
					</div>
					<div class="name">
						<div>NM21NM1</div>
						<div>尺寸：275*44mm*174</div>
					</div>
				</div>
				<div class="productItem">
					<div class="productImg">
						<img src="../../../assets/image/productCenter/product/networkCommunicationEquipment/2-B.png" alt="">
					</div>
					<div class="name">
						<div>NM45</div>
						<div>尺寸：180*28*110mm</div>
					</div>
				</div>
			</div>
		</div>
		<div class="detailMiddle">
			<div>
				<div class="title">产品特点</div>
				<div class="characteristicsList">
					<div class="characteristicsItem" v-for="item,index in characteristicsList" :key="index">
						<svg-icon :icon-class="item.svg" ></svg-icon>
						<span>{{item.text}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="detailBottom">
			<div>
				<div class="title">产品指标</div>
				<div class="productIndex">
					<div class="indexHeader">
						<div>硬件平台</div>
						<div>NM21</div>
						<div>NM14</div>
						<div>NM11P</div>
					</div>
					<div>
						<div class="indexRow">
								<div class="indexItem">管理接口</div>
								<div class="indexItem">任一业务接口</div>
								<div class="indexItem">任一业务接口</div>
								<div class="indexItem">任一业务接口</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">固定业务接口</div>
								<div class="indexItem">5GE(电)</div>
								<div class="indexItem">5GE(电)</div>
								<div class="indexItem">5GE( 电1GE 光)</div>

							</div>
							<div class="indexRow">
								<div class="indexItem">console口</div>
								<div class="indexItem">1*(RS232 RJ45)</div>
								<div class="indexItem">1*(RS232 RJ45)</div>
								<div class="indexItem">1*(RS232 RJ45)</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">WIFI</div>
								<div class="indexItem">选配</div>
								<div class="indexItem">选配</div>
								<div class="indexItem">选配</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">4G</div>
								<div class="indexItem">选配</div>
								<div class="indexItem">选配</div>
								<div class="indexItem">选配</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">5G</div>
								<div class="indexItem">选配</div>
								<div class="indexItem">无</div>
								<div class="indexItem">无</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">CPU</div>
								<div class="indexItem">Marvell 88F3720</div>
								<div class="indexItem">Marvell 88F3720</div>
								<div class="indexItem">Marvell 88F3720</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">CPU主频</div>
								<div class="indexItem">1Ghz</div>
								<div class="indexItem">1Ghz</div>
								<div class="indexItem">1Ghz</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">CPU核数</div>
								<div class="indexItem">2</div>
								<div class="indexItem">2</div>
								<div class="indexItem">2</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">内存</div>
								<div class="indexItem">内存贴片DDR4 2GB</div>
								<div class="indexItem">内存贴片DDR3512M/1GB</div>
								<div class="indexItem">内存贴片DDR4 1GB/2GB</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">FLASH</div>
								<div class="indexItem">4GB</div>
								<div class="indexItem">4GB</div>
								<div class="indexItem">4GB</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">USB2.0</div>
								<div class="indexItem">1</div>
								<div class="indexItem">1</div>
								<div class="indexItem">1</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">平均无故障时间(MTBF)</div>
								<div class="indexItem">≥100,000小时</div>
								<div class="indexItem">≥100,000小时</div>
								<div class="indexItem">≥100,000小时</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">缺省配置重量</div>
								<div class="indexItem">1.5kg</div>
								<div class="indexItem">1.5kg</div>
								<div class="indexItem">1kg</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">外形尺寸(长X宽X高/mm)</div>
								<div class="indexItem">275x44x175mm</div>
								<div class="indexItem">275x44x175mm</div>
								<div class="indexItem">180x28x110mm</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">导轨</div>
								<div class="indexItem">无</div>
								<div class="indexItem">无</div>
								<div class="indexItem">无</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">温度</div>
								<div class="indexItem">存储温度-40~70C工作温度0~45°C</div>
								<div class="indexItem">存储温度-40~70C工作温度0~45°C</div>
								<div class="indexItem">存储温度-40~70C工作温度0~45°C</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">湿度</div>
								<div class="indexItem">工作5%-90%非凝露</div>
								<div class="indexItem">工作5%-90%非凝露</div>
								<div class="indexItem">工作5%-90%非凝露</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">电源类型</div>
								<div class="indexItem">外置适配器</div>
								<div class="indexItem">外置适配器</div>
								<div class="indexItem">外置适配器</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">电源1+1备份</div>
								<div class="indexItem">无</div>
								<div class="indexItem">无</div>
								<div class="indexItem">无</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">输入额定电压</div>
								<div class="indexItem">12V DC</div>
								<div class="indexItem">12V DC</div>
								<div class="indexItem">12V DC</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">最大输入电流</div>
								<div class="indexItem">2A</div>
								<div class="indexItem">2A</div>
								<div class="indexItem">2A</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">最大功率供给(W)</div>
								<div class="indexItem">24W</div>
								<div class="indexItem">24W</div>
								<div class="indexItem">24W</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">环保认证</div>
								<div class="indexItem">符合Rohs</div>
								<div class="indexItem">符合Rohs</div>
								<div class="indexItem">符合Rohs</div>
							</div>
							<div class="indexRow">
								<div class="indexItem">已有认证</div>
								<div class="indexItem">NA</div>
								<div class="indexItem">CCC</div>
								<div class="indexItem">NA</div>
							</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		WOW
	} from "wowjs"
	export default {
		data() {
			return {
				wow: null,
				characteristicsList:[{
					svg:'ic_tedian_1',
					text:"MARVELL88F3720通信级高性能ARM平台,2Cores"
				},
				{
					svg:'ic_tedian_2',
					text:"板载DDR颗粒，支持1GB/2GB"

				},
				{
					svg:'ic_tedian_3',
					text:"预留2个miniPCle接口，支持4G/WiFi板贴SIM卡槽"
				},
				{
					svg:'ic_tedian_4',
					text:"固化5个千兆电口和1个千兆光口"
				},
				{
					svg:'ic_tedian_5',
					text:"MARVELL88F3720集成硬件加速引擎，提供更高网络转发性能和多种硬件加密加速能力"
				},
				{
					svg:'ic_tedian_7',
					text:"较传统低端X86产品·提供更多高可靠性设计(无风扇设计板载内存及存储等)并具有更优成本"
				},
				{
					svg:'ic_tedian_8',
					text:"适 于防火墙、VPN网关SD-WAN等多种应用场景"
				}]
			}
		},
		created() {

		},
		mounted() {
			this.wow = new WOW({
				boxClass: 'wow', // 添加动画的元素的标识
				animateClass: 'animate__animated', // 动画时长
				offset: 0, // 与可视区域底部的距离显示
				mobile: true, // 移动端是否可用
				live: true // 对后面添加的新元素是否有效
			}).init();
		},
		methods: {
			goBack() {
				this.$router.back()
			},
		}
	}
</script>

<style lang="scss">
	.NCE2 {
		.header {
			width: 1903px;
			margin: 0 auto;
			img {
				width: 100%;
			}

			position: relative;

			div {
				cursor: pointer;
				position: absolute;
				bottom: 4px;
				left: 360px;
				width: 120px;
				height: 40px;
				background: #4495FF;
				line-height: 40px;
				color: #fff;
				font-size: 16px;
			}
		}
		.detailTop{
			width: 1200px;
			margin: 0 auto;
			margin-bottom: 50px;
			.productName{
				font-size: 40px;
				font-weight: bold;
				color: #333;
				margin: 63px 0;
			}
			.productItems{
				display: flex;
				justify-content: space-between;
				.productItem{
					width: 580px;
					height: 331px;
					background: #FFFFFF;
					box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
					border-radius: 16px 16px 16px 16px;
					.productImg{
						height: 229px;
						position: relative;
						img{
							width: 34%;
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
						}
					}
					.name{
						height: 107px;
						background: #2A61BF;
						border-radius: 0px 0px 16px 16px;
						div{
							&:first-child{
								font-size: 24px;
								color: #FFFFFF;
								padding: 21px 0 16px 0;
							}
							&:last-child{
								font-size: 20px;
								color: rgba(255, 255, 255, 0.8);
							}
						}
						
					}
				}
			}
			
			
		}
		.detailMiddle{
			background: url("../../../assets/image/productCenter/product/networkCommunicationEquipment/bg_tedian.png") no-repeat;
			background-size: 1920px 718px;
			>div{
				width: 1200px;
				margin: 0 auto;
			}

			.title{
				font-weight: bold;
				color: #333333;
				font-size: 32px;
				padding: 60px 0 53px 0;
			}
			.characteristicsList{
				width: 100%;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				.characteristicsItem{
					text-align: left;
					width: 50%;
					padding-bottom: 49px;
					.svg-icon{
						width: 52px;
						height: 52px;
						display: inline-block;
						vertical-align: middle;
					}
					span{
						margin-left: 9px;
						display: inline-block;
						vertical-align: middle;
						width: 498px;
						color: #333;
						font-size: 22px;
						font-weight: bold;
					}
				}
			}
			
		}
		.detailBottom{
			margin-bottom: 0px;
			>div{
				width: 1200px;
				margin: 0 auto;
			}
			.title{
				margin: 70px 0 50px 0;
				font-weight: bold;
				color: #333333;
				font-size: 32px;
			}
			.productIndex{
				.indexHeader{
					height: 69px;
					background: #0155C4;
					line-height: 69px;
					font-size: 26px;
					font-weight: 500;
					color: #FFF;
					>div{
						display: inline-block;
						width: 299px;
						border-left: 1px solid #C4C4C4;
						&:first-child{
							width: 300px;
							border-left: none;
						}
						// &:last-child{
						// 	width: 912px;
						// }
					}
				}
				>div{
					&:last-child{
						>div{
							&:nth-child(2n){
								background: #F4F5F8;
							}
						}
					}
				}
				.indexRow{
					line-height: 54px;
					border: 1px solid #C4C4C4;
					border-top: none;
					.indexItem{
						display: inline-block;
						vertical-align:middle;
						font-weight: 500;
						color: #555555;
						font-size: 22px;
						width: 299px;
						&:first-child{
							width: 300px;
						}
						&:nth-child(2){
							border-left: 1px solid #C4C4C4;
							border-right: 1px solid #C4C4C4;
						}
						&:last-child{
							border-left: 1px solid #C4C4C4;
						}
					}
				}
				
			}

		}

		
	}
</style>