<template>
  <div class="productCenter">
    <div class="header wow animate__slideInLeft">
    	<img src="../../assets/image/productCenter/bg_product_banner.png" alt="">
    </div>
    <div class="wow animate__slideInUp">
      <div class="productTop">
		  <div class="product_head wow animate__slideInUp">
		    <!-- <div class="dot"></div> -->
		    <h2 class="title">产品中心</h2>
		    <p class="modifier">PRODUCT CENTER</p>
		  </div>
		  <div class="product_body wow animate__slideInUp">
		    <div class="nav">
		      <div class="nav_item" :class="activeProductNav == item.name ? 'activeProductNav' : ''" v-for="item in productNavList" :key="item.name" @click="isProductNav(item.name)">
			   <svg-icon :icon-class="item.icon" ></svg-icon>
		       <div class="productItemName"> {{item.name}}</div>
		      </div>
		    </div>
		  </div>
	  </div>
	  <div class="productBottom">
		  <div class="product_content" >
			  <div class="product_item wow animate__bounceInUp" v-for="item,index in productList" :key="item.name" @click="goUrl(item.path)">
				 <div class="product_img">
					 <img  :src="item.img" alt="" :style="item.width?'width:'+item.width+';':''">
				 </div>
				 <p class="product_name">
				   {{item.name}}
				 </p>
			  </div>
		  </div>
	  </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs"
// import Swiper from "@/utils/swiper-bundle.min.js";

export default {
  components: {
  },
  data(){
    return {
	  swiper: null,
      wow: null,
      activeProductNav: "网络通讯设备",
      productNavList: [
		{
		  name: '网络通讯设备',
		  icon:'ic_tongxin'
		},
		{
		  name: '汽车电子',
		  icon:'ic_car'
		},
        {
          name: '智能养老设备',
		  icon:'ic_old'
        },
      ],
      productList: [
      ],
	  automotiveElectronicsList:[
		  {
		    img: require('../../assets/image/productCenter/product/automotiveElectronics/1-A.png'),
		    name: '车载tbox',
			path:'/AE1',
			width:'70%'
		  },
		  {
		    img: require('../../assets/image/productCenter/product/automotiveElectronics/2-B.png'),
		    name: '车载多合一中控',
			path:'/AE2',
			width:'70%'
		  },
		  {
		    img: require('../../assets/image/productCenter/product/automotiveElectronics/3-A.png'),
		    name: '风暖控制器',
			path:'/AE3',
			width:'70%'
		  },
		  {
		    img: require('../../assets/image/productCenter/product/automotiveElectronics/4-A.png'),
		    name: '水暖控制器',
			path:'/AE4',
			width:'70%'
		  },
		  {
		    img: require('../../assets/image/productCenter/product/automotiveElectronics/5-A.png'),
		    name: '车身控制器',
			path:'/AE5',
			width:'70%'
		  },
		  {
		    img: require('../../assets/image/productCenter/product/automotiveElectronics/6-A.png'),
		    name: '智能座舱域控制器',
			path:'/AE6',
			width:'70%'
		  },
	  ],
	  networkCommunicationEquipmentList:[
		  {
		    img: require('../../assets/image/productCenter/product/pic_sd.png'),
		    name: 'SD-WAN终端-NH03',
		  			path:'/NCE1'
		  },
		  {
		    img: require('../../assets/image/productCenter/product/pic_nm.png'),
		    name: 'SD-WAN终端-NM21',
		  			path:'/NCE2'
		  },
		  {
		    img: require('../../assets/image/productCenter/product/networkCommunicationEquipment/3-A.png'),
		    name: '能耗在线监测端设备',
		  	path:'/NCE3',
		  	width:'70%'
		  },
		  {
		    img: require('../../assets/image/productCenter/product/networkCommunicationEquipment/4-A.png'),
		    name: '网闸设备',
		  	path:'/NCE4',
		  	width:'75%'
		  },
		  {
		    img: require('../../assets/image/productCenter/product/networkCommunicationEquipment/5-A.png'),
		    name: 'CPE设备（NK06)',
		  	path:'/NCE5',
		  	width:'75%'
		  },
	  ],
	  elderlyEquipment:[
		  {
		     img: require('../../assets/image/productCenter/product/elderlyEquipment/1-A.png'),
		    name: '健康体检一体机',
		  	path:'/EE1',
			width:'55%'
		  },
		  {
		     img: require('../../assets/image/productCenter/product/elderlyEquipment/2-A.png'),
		    name: '小度养老智慧屏',
		  	path:'/EE2',
			width:'55%'
		  },
		  {
		     img: require('../../assets/image/productCenter/product/elderlyEquipment/3-A.png'),
		    name: '智能手环',
		  	path:'/EE3',
		  },
		  {
		     img: require('../../assets/image/productCenter/product/elderlyEquipment/4-A.png'),
		    name: '燃气报警器',
		  	path:'/EE4'
		  },
		  {
		     img: require('../../assets/image/productCenter/product/elderlyEquipment/5-A.png'),
		    name: '水浸传感器',
		  	path:'/EE5'
		  },
		  {
		     img: require('../../assets/image/productCenter/product/elderlyEquipment/6-A.png'),
		    name: '烟感报警器',
		  	path:'/EE6'
		  },
	  ]
    }
  },
  created(){
    
  },
  mounted(){
    this.wow = new WOW({
      boxClass: 'wow', // 添加动画的元素的标识
      animateClass: 'animate__animated', // 动画时长
      offset: 0, // 与可视区域底部的距离显示
      mobile: true, // 移动端是否可用
      live: true // 对后面添加的新元素是否有效
    }).init();
	this.productList = this.networkCommunicationEquipmentList
    if(this.$route.query.anchorPoint){
    	this.isProductNav(this.$route.query.anchorPoint)
    }
  },
  watch: {
  	$route:{
  	  handler(val,oldval){
  	    // console.log(val);//新路由信息
  	    // console.log(oldval);//老路由信息
		this.isProductNav(val.query.anchorPoint)
  	  },
  	  // 深度观察监听
  	  deep: true
  	}
  
  },
  methods: {
    isProductNav(name){
      this.activeProductNav = name
	  if(name=='网络通讯设备'){
		  this.productList = this.networkCommunicationEquipmentList
	  }else if(name=='汽车电子'){
		  this.productList = this.automotiveElectronicsList
	  }else{
		  this.productList = this.elderlyEquipment
	  }
    },
	goUrl(path){
		this.$router.push({path})
	},
  }
}
</script>

<style lang="scss">

.productCenter {
	margin-bottom: 60px;
	.header{
		width: 1903px;
		img{
			width: 100%;
		}
	}
  .productTop{
	  margin: 0 auto;
	  width: 1200px;
	  .product_head {
	    .dot {
	      
	      height: 4px;
	      width: 24px;
	      background-color: #427BED;
	      border-radius: 2px;
	    }
	    .title {
	      margin: 70px 0 15px 0;
	      font-size: 40px;
	      color: #333;
		  
	    }
	    .modifier {
	      font-size: 24px;
	      color: #666;
	    }
	  }
	  .product_body {
	    margin-top: 26px;
	    .nav {
	      display: flex;
	      justify-content: space-between;
	      flex-wrap: wrap;
	      font-size: 20px;
	      color: #333333;
	      .nav_item {
			  width: 200px;
			.productItemName{
				margin: 22px 0 22px 0;
			}
			.svg-icon{
				font-size: 21px;
			}
	        cursor: pointer;
	        &:hover {
	          color: #0155C4;
	          &::after {
	            width: 100%;
	          }
	        }
	      }
	      .nav_item::after {
	        content: '';
	        display: block;
	        margin: -2px auto 0;
	        height: 2px;
	        width: 0%;
	        background-color: #0155C4;
	        border-radius: 2px;
	        transition: all .3s;
	      }
	      .activeProductNav {
	        color: #0155C4;
	        &::after {
	          width: 100%;
	        }
	      }
	    }
	  
	  }
  }
  .productBottom{
	  background: url("../../assets/image/productCenter/bg_product.png") no-repeat;
	  background-position: 0px 190px;
	  .product_content {
		margin: 0 auto;
		width: 1200px;
	    display: flex;
	    // justify-content: space-between;
	    flex-wrap: wrap;
	    transition: all .3s;
	    .product_item {
		  background-color: #fff;
		  margin-top: 60px;
		  display: inline-block;
	      width: 360px ;
	      height: 394px;
	      border: 1px solid #0155C4;
	      border-radius: 10px;
	      box-shadow: 0px 0px 20px -1px #ccc;
	      transition: all .3s;
	      overflow: hidden;
		  margin-right: 60px;
		  cursor: pointer;
		  &:nth-child(3n){
			  margin-right: 0;
		  }
	      .product_img {
			position: relative;
	        height: 292px;
			width: 100%;
			img{
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}
	      }
	      .product_name {
	        font-size: 24px;
	        color: #333333;
	        line-height: 102px;
	        text-align: center;
	        transition: all .3s;
			background: rgba(1, 85, 196, 0.04);
	      }
	      &:hover {
	        transform: translateY(-5px);
	        border: 3px solid #0155C4;
	        box-shadow: 0px 0px 10px 5px #ccc;
	        .product_name {
	          color: #FFFFFF;
	          background-color: rgba(1, 85, 196, 0.9);
	        }
	      }
	    }
	  }
  }
  
}
@media only screen and (max-width: 1200px) {
	.productCenter {
		.header{
			width: 1200px;
		}
	}
	.header,.AE1,.AE2,.AE3,.AE4,.AE5,.AE6,.EE1,.EE2,.EE3,.EE4,.EE5,.EE6,.NCE1,.NCE2,.NCE3,.NCE4,.NCE5,.NCE6{
		width: 1200px !important;
	}
}
// @media only screen and (max-width: 780px) {
//   .partner .partner_content {
//     justify-content: center !important;
//   }
//   .partner .partner_content .partner_item {
//     // width: 98% !important;
//     margin-bottom: 20px;
//   }
//   .product .product_body {
//     .product_content {
//       justify-content: center !important;
//       .product_item {
//         width: 90% !important;
//         margin-bottom: 20px;
//       }
//     }
//   }
// }
</style>