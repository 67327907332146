<template>
  <div class="elderlyCare">
	  <div class="header wow animate__slideInLeft">
	  	<img src="../../assets/image/solution/baseStation/bg_jifang_banner.png" alt="">
		<div @click="goBack">返回上一页</div>
	  </div>
    <div class="elderlyCare wow animate__slideInUp">
      <div class="elderlyCareTop" id="elderlyCareTop">
		  <div class="elderlyCare_body wow animate__slideInUp">
		    <div class="nav">
		      <div class="nav_item" :class="activeProductNav == item.name ? 'activeProductNav' : ''" v-for="item in enterpriseQualificationNavList" :key="item.name" @click="isProductNav(item.name)">
		       <a  @click.prevent="anchor(item.position)" class="elderlyCareItemName"> {{item.name}}</a>
		      </div>
		    </div>
		  </div>
	  </div>
	  <div class="elderlyCareBottom">
		  <!-- 平台简介 -->
		  <div class="platformIntroduction">
			  <a id="PLATFORM" style="position: relative;top: -80px;"></a>
			  <div class="elderlyCare_head wow animate__slideInUp">
			    <h2 class="title">方案介绍</h2>
			    <p class="modifier">SCHEME INTRODUCTION</p>
			  </div>
			  <div class="platformIntroductionContent">
				  <p>
					  通信系统基站/机房的能耗问题已经成为通信运营商发展的难题，基站/机房的节能减排工作是目前的关注焦点。本方案将通过对基站机构进行优化，使用新技术降低基站的能源消耗，促进通信系统可持续发展。
				  </p>
			  </div>
			  
		  </div>
		  <!-- 解决方案 -->
		  <div class="solution">
			   <a id="NETWORK" style="position: relative;top: -80px;"></a>
			  <div class="elderlyCare_head wow animate__slideInUp">
				<h2 class="title" style="padding-top: 26px;">解决方案</h2>
				<p class="modifier">SOLUTION</p>
			  </div>
			  <div class="solutionContent">
				  <div >
					  <img src="../../assets/image/solution/baseStation/jifangSolution1.png" alt="">
				  </div>
			  </div>
		  </div>
		  <div class="solutionContent1">
			  <div >
				  <img src="../../assets/image/solution/baseStation/jifangSolution2.png" alt="">
			  </div>
		  </div>
		  <div class="solutionContent2">
			  <div >
				  <img src="../../assets/image/solution/baseStation/jifangSolution3.png" alt="">
				  <img src="../../assets/image/solution/baseStation/jifangSolution4.png" alt="">
			  </div>
		  </div>
		  <!-- 平台功能 -->
		  <div class="platformFunction">
			  <a id="FUNCTION" style="position: relative;top: -80px;"></a>
			  <div class="elderlyCare_head wow animate__slideInUp">
				<h2 class="title" style="padding-top: 26px;">平台功能</h2>
				<p class="modifier">PLATFORM FUNCTION</p>
			  </div>
			  <div class="platformFunctionContent">
				  <div >
				  	 <img src="../../assets/image/solution/baseStation/bg_jifang_function.png" alt="">
				  </div>
			  </div>
		  </div>
	  </div>
    </div>

  </div>
</template>

<script>
import { WOW } from "wowjs"
export default {
  data(){
    return {
      wow: null,
      activeProductNav: "方案介绍",
      enterpriseQualificationNavList: [
		{
		  name: '方案介绍',
		  position:'PLATFORM'
		},
		{
		  name: '解决方案',
		  position:'NETWORK'
		},
        {
          name: '平台功能',
		  position:'FUNCTION'
        }
      ]
    }
  },
  created(){
    
  },
  mounted(){
    this.wow = new WOW({
      boxClass: 'wow', // 添加动画的元素的标识
      animateClass: 'animate__animated', // 动画时长
      offset: 0, // 与可视区域底部的距离显示
      mobile: true, // 移动端是否可用
      live: true // 对后面添加的新元素是否有效
    }).init();
	window.addEventListener('scroll', () => {
	  // 页面滚动停止100毫秒后才会执行下面的函数。
	  this.scrollToTop();
	} , true);
  },
  destroy(){
  	  window.removeEventListener('scroll', this.scrollToTop)
  },
  methods: {
    isProductNav(name){
      this.activeProductNav = name
    },
	 /*锚链接跳转*/
	  anchor(anchorName) {
	    /*找到锚点*/
	    let anchorElement = document.getElementById(anchorName);
	    /*如果对应id的锚点存在，就跳转到锚点*/
	    if(anchorElement) {
	      anchorElement.scrollIntoView();
	    }
	  },
	  goBack(){
	  		  this.$router.back()
	  },
	  scrollToTop(){
	  		  if(!document.getElementById('elderlyCareTop')){
	  		  	return
	  		  }
	  		  var offsetTop = document.getElementById('elderlyCareTop').getBoundingClientRect().top;
	  		  // var scrollTop = document.getElementById('culturalRelics').scrollTop;
	  		  if(window.pageYOffset>=492){
	  			  document.getElementById('elderlyCareTop').style.position = 'fixed'
	  			  document.getElementById('elderlyCareTop').style.top = '67px'
	  			  document.getElementById('elderlyCareTop').style.backgroundColor = '#fff'
				  document.getElementById('PLATFORM').style.top = '-140px'
				  document.getElementById('NETWORK').style.top = '-140px'
				  document.getElementById('FUNCTION').style.top = '-140px'
	  		  }else{
	  			  document.getElementById('elderlyCareTop').style.position = 'static'
	  			  document.getElementById('PLATFORM').style.top = '-80px'
	  			  document.getElementById('NETWORK').style.top = '-80px'
	  			  document.getElementById('FUNCTION').style.top = '-80px'
	  		  }
	  		  // console.log(offsetTop,window.pageYOffset)
	  }
  }
}
</script>

<style lang="scss">
.elderlyCare {
.header{
		width: 1903px;
		img{
			width: 100%;
		}
		position: relative;
		div{
			cursor: pointer;
			position: absolute;
			bottom: 4px;
			left: 360px;
			width: 120px;
			height: 40px;
			background: #4495FF;
			line-height: 40px;
			color: #fff;
			font-size: 16px;
		}
	}
  margin: 0 auto;
  .elderlyCareTop{
	  width: 100%;
	  border-bottom: 1px solid #C2C2C2;
	  .elderlyCare_body {
		  margin: 0 auto;
		  width: 1200px;
		  padding: 0 289px;
	    .nav {
	      display: flex;
	      justify-content: space-between;
	      flex-wrap: wrap;
	      font-size: 22px;
	      color: #333333;
	      .nav_item {
			.elderlyCareItemName{
				display: inline-block;
				text-decoration: none;
				margin: 22px 0 22px 0;
				color: #333333;
			}
	        cursor: pointer;
	        &:hover {
	          color: #0155C4;
	          &::after {
	            width: 100%;
	          }
	        }
	      }
	      .nav_item::after {
	        content: '';
	        display: block;
	        margin: -2px auto 0;
	        height: 2px;
	        width: 0%;
	        background-color: #0155C4;
	        border-radius: 2px;
	        transition: all .3s;
	      }
	      .activeProductNav {
			  .elderlyCareItemName{
			  	 color: #0155C4;
			  }
	        color: #0155C4;
	        &::after {
	          width: 100%;
	        }
	      }
	    }
	  
	  }
  }

  .elderlyCareBottom{
	  padding-bottom: 60px;
	  background-color: #F5F6F6;
	  .elderlyCare_head {
		  margin-bottom: 45px;
	    .title {
	      margin: 0 0 15px 0;
	      font-size: 40px;
	      color: #333;
	  	  padding-top: 70px;  
	    }
	    .modifier {
	      font-size: 24px;
	      color: #666;
	    }
	  }
	 .platformIntroduction{
		  padding-bottom:70px;
		  .platformIntroductionContent{
			  width: 1200px;
			  margin: 0 auto;
			  text-align:left;
			  P{
				  color: #191F25;
				  font-size: 20px;
				  line-height: 28px;
				  margin-bottom: 31px;
				  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
				  letter-spacing: 1px;
			  }
		  }
			  
	 }
	 .solution{
		 background: url("../../assets/image/solution/baseStation/bg_jifang_solution.png") no-repeat;
	 	 padding-bottom:70px;
	 }
	 .solutionContent1{
		 margin: 50px 0;
	 }
	 .solutionContent2{
		 background: url("../../assets/image/solution/baseStation/bg_jifang_solution5.png") no-repeat;
		 >div{
			 padding: 50px 0;
			 img{
				 &:first-child{
					 margin-bottom: 50px;
				 }
			 }
		 }
	 }
	  .platformFunction{
		 .platformFunctionContent{
		 		width: 1200px;
		 		margin: 0 auto;  
				text-align:left;
				>div{
					div{
						text-align: center;
						font-size: 22px;
						margin: 23px 0 55px 0;
					}
				}
		 } 
	  }
	  
	  
  }
}
 @media only screen and (max-width: 1200px) {
 	.elderlyCare{
 		width: 1200px;
 		.header{
 			width: 1200px;
 		}
 	}
 }
</style>