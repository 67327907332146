<template>
	<div class="EE2">
		<div class="header wow animate__slideInLeft">
			<img src="../../../assets/image/productCenter/product/networkCommunicationEquipment/bg_detail_banner.png" alt="">
			<div @click="goBack">返回上一页</div>
		</div>
		<div class="detailTop">
			<div class="productName">小度养老智慧屏</div>
			<div class="productItems">
				<div class="productItem">
					<div class="productImg">
						<img src="../../../assets/image/productCenter/product/elderlyEquipment/2-A.png" style="width: 32%;" alt="">
					</div>
					
				</div>
				<div class="productItem">
					<div class="productImg">
						<img src="../../../assets/image/productCenter/product/elderlyEquipment/2-B.png" style="width: 32%;" alt="">
					</div>
				</div>
			</div>
			<div class="productIntroduction">
				<p>小度养老智慧屏适合老人居家使用，功能丰富、性价比高；小度智慧屏配有8寸触摸屏，方便老人观看和点击；200万像素高清摄像头，视频通话画面更清晰；内置大音量高保真扬声器，让老人听的更加清晰。</p>
				<p>小度养老智慧屏内部含有语音控制、养老管家视频通话、健康数据管理、用药提醒、在线预约服务、社区服务通知报名等多项功能，让老人在居家娱乐的同时，享受智能化养老服务。</p>
			</div>
		</div>
		<div class="detailMiddle">
			<div>
				<div class="title">产品功能</div>
				<div class="characteristicsList">
					<div class="characteristicsItem" v-for="item,index in characteristicsList" :key="index">
						<svg-icon :icon-class="item.svg" ></svg-icon>
						<span>{{item.text}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		WOW
	} from "wowjs"
	export default {
		data() {
			return {
				wow: null,
				characteristicsList:[{
					svg:'ic_tedian_1',
					text:"养老智慧屏首页：首页包含各种功能入口，如社区服务、活动计划、健康档案、呼叫管家，可根据自身需求选择需要的服务。"
				},
				{
					svg:'ic_tedian_3',
					text:"健康档案：展示出老人身体健康的信息，血压，血糖，听力，视力等"
				},
				{
					svg:'ic_tedian_2',
					text:"视频呼叫：老人可以通过语音主动呼叫后台，后台养老管家自动应答。"
				},
				{
					svg:'ic_tedian_4',
					text:"订单服务：老人可以根据需求选择需要的付费服务，方便快捷。"
				},
				{
					svg:'ic_tedian_5',
					text:"用药提醒：到了老人的吃药时间 小度会语音提醒老人吃药"
				},
				{
					svg:'ic_tedian_7',
					text:"社区活动：展示已举办的社区活动，丰富老人的生活，领略社区风采。"
				}]
			}
		},
		created() {

		},
		mounted() {
			this.wow = new WOW({
				boxClass: 'wow', // 添加动画的元素的标识
				animateClass: 'animate__animated', // 动画时长
				offset: 0, // 与可视区域底部的距离显示
				mobile: true, // 移动端是否可用
				live: true // 对后面添加的新元素是否有效
			}).init();
		},
		methods: {
			goBack() {
				this.$router.back()
			},
		}
	}
</script>

<style lang="scss">
	.EE2 {
		.header {
			width: 1903px;
			margin: 0 auto;
			img {
				width: 100%;
			}

			position: relative;

			div {
				cursor: pointer;
				position: absolute;
				bottom: 4px;
				left: 360px;
				width: 120px;
				height: 40px;
				background: #4495FF;
				line-height: 40px;
				color: #fff;
				font-size: 16px;
			}
		}
		.detailTop{
			width: 1200px;
			margin: 0 auto;
			margin-bottom: 50px;
			.productName{
				font-size: 40px;
				font-weight: bold;
				color: #333;
				margin: 63px 0;
			}
			.productItems{
				display: flex;
				justify-content: space-around;
				.productItem{
					width: 580px;
					// height: 331px;
					background: #FFFFFF;
					box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
					border-radius: 16px 16px 16px 16px;
					.productImg{
						height: 229px;
						position: relative;
						img{
							width: 60%;
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
						}
					}
					.name{
						// height: 107px;
						background: #2A61BF;
						border-radius: 0px 0px 16px 16px;
						div{
							&:first-child{
								font-size: 24px;
								color: #FFFFFF;
								padding: 21px 0 16px 0;
							}
							&:last-child{
								font-size: 20px;
								color: rgba(255, 255, 255, 0.8);
							}
						}
						
					}
				}
			}
			.productIntroduction{
				margin-top: 40px;
				p{
					text-indent: 2em;
					font-size: 22px;
					color: #333333;
					text-align: left;
					line-height: 40px;
					letter-spacing: 1px;
					font-weight: 600;
				}
			}
			
			
		}
		.detailMiddle{
			background: url("../../../assets/image/productCenter/product/networkCommunicationEquipment/bg_tedian.png") no-repeat;
			background-size: 1920px 718px;
			>div{
				width: 1200px;
				margin: 0 auto;
			}

			.title{
				font-weight: bold;
				color: #333333;
				font-size: 32px;
				padding: 60px 0 53px 0;
			}
			.characteristicsList{
				width: 100%;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				.characteristicsItem{
					text-align: left;
					width: 50%;
					padding-bottom: 49px;
					.svg-icon{
						width: 52px;
						height: 52px;
						display: inline-block;
						vertical-align: middle;
					}
					span{
						margin-left: 9px;
						display: inline-block;
						vertical-align: middle;
						width: 498px;
						color: #333;
						font-size: 22px;
						font-weight: bold;
					}
				}
			}
			
		}
		.detailBottom{
			margin-bottom: 60px;
			>div{
				width: 1200px;
				margin: 0 auto;
			}
			.title{
				margin: 70px 0 50px 0;
				font-weight: bold;
				color: #333333;
				font-size: 32px;
			}
			.productIndex{
				>div{
					&:last-child{
						>div{
							&:nth-child(2n){
								background: #F4F5F8;
							}
						}
					}
				}
				.indexRow{
					line-height: 54px;
					border: 1px solid #C4C4C4;
					border-top: none;
					.indexItem{
						display: inline-block;
						vertical-align:middle;
						font-weight: 500;
						color: #555555;
						font-size: 22px;
						width: 599px;
						&:first-child{
						}
						
						&:last-child{
							border-left: 1px solid #C4C4C4;
						}
					}
					&:first-child{
						border-top: 1px solid #C4C4C4;
					}
				}
				
			}

		}

		
	}
</style>