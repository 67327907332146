<template>
  <div class="enterpriseQualificationContainer">
	  <div class="header wow animate__slideInLeft">
	  	<img src="../../assets/image/enterpriseQualification/bg_zizhibanner.png" alt="">
	  </div>
    <div class="enterpriseQualification wow animate__slideInUp">
      <div class="enterpriseQualificationTop" id="enterpriseQualificationTop">
		  <div class="enterpriseQualification_body wow animate__slideInUp">
		    <div class="nav">
		      <div class="nav_item" :class="activeProductNav == item.name ? 'activeProductNav' : ''" v-for="item in enterpriseQualificationNavList" :key="item.name" @click="isProductNav(item.name)">
		       <a :href="`#${item.position}`" class="enterpriseQualificationItemName"> {{item.name}}</a>
		      </div>
		    </div>
		  </div>
	  </div>
	  <div class="enterpriseQualificationBottom">
		  <!-- 行业认知 -->
		  <div>
			  <a name="industryCognition" id="industryCognition" style="position: relative;top: -80px;"></a>
			  <div class="enterpriseQualification_head wow animate__slideInUp">
			    <h2 class="title">行业认证</h2>
			    <p class="modifier">INDUSTRY</p>
			  </div>
			  <div class="industryCognition">
				  <div v-for="item,index in industryCognitionList" :key="index" class="industryCognitionItem wow animate__bounce">
					  <div class="image">
						  <img :src="item.img" alt="">
					  </div>
					  <div class="imageName"> 
						  {{item.name}}
					  </div>
				  </div>
			  </div>
		  </div>
		  <!-- 软件著作权 -->
		  <div >
			   <a name="softwareCopyright" id="softwareCopyright" style="position: relative;top: -80px;"></a>
			  <div class="enterpriseQualification_head wow animate__slideInUp">
				<h2 class="title" style="padding-top: 26px;">软件著作权</h2>
				<p class="modifier">SOFTWARE COPYRIGHT</p>
			  </div>
			  <div class="industryCognition">
				  <div v-for="item,index in softwareCopyrightList" :key="index" class="industryCognitionItem wow animate__bounce">
					  <div class="image">
						  <img :src="item.img" alt="">
					  </div>
					  <div class="imageName"> 
						  {{item.name}}
					  </div>
				  </div>
			  </div>
		  </div>
		  <!-- 发明专利 -->
		  <div>
			  <a name="inventionPatent" id="inventionPatent" style="position: relative;top: -80px;"></a>
			  <div class="enterpriseQualification_head wow animate__slideInUp">
				<h2 class="title" style="padding-top: 26px;">发明专利</h2>
				<p class="modifier">SOFTWARE COPYRIGHT</p>
			  </div>
			  <div class="industryCognition">
				  <div v-for="item,index in inventionPatentList" :key="index" class="industryCognitionItem wow animate__bounce">
					  <div class="image">
						  <img :src="item.img" alt="">
					  </div>
					  <div class="imageName"> 
						  {{item.name}}
					  </div>
				  </div>
			  </div>
		  </div>
	  </div>
    </div>

  </div>
</template>

<script>
import { WOW } from "wowjs"
export default {
  data(){
    return {
      wow: null,
      activeProductNav: "行业认证",
      enterpriseQualificationNavList: [
		{
		  name: '行业认证',
		  position:'industryCognition'
		},
		{
		  name: '软件著作权',
		  position:'softwareCopyright'
		},
        {
          name: '发明专利',
		  position:'inventionPatent'
        }
      ],
	  // 行业认知
	  industryCognitionList:[
	  	{
	  		img:require('../../assets/image/enterpriseQualification/industryCognition/CMMI证书.jpg'),
			name:'CMMI证书'
	  	},
	  	{
	  		img:require('../../assets/image/enterpriseQualification/industryCognition/安全生产许可证.jpg'),
			name:'安全生产许可证'
	  	},
	  	{
	  		img:require('../../assets/image/enterpriseQualification/industryCognition/高新企业证书.jpg'),
			name:'高新企业证书'
	  	},
	  	{
	  		img:require('../../assets/image/enterpriseQualification/industryCognition/建筑业企业资质一级.jpg'),
			name:'建筑业企业资质一级'
	  	},
	  	{
	  		img:require('../../assets/image/enterpriseQualification/industryCognition/劳务派遣正本.jpg'),
			name:'劳务派遣正本'
	  	},
	  	{
	  		img:require('../../assets/image/enterpriseQualification/industryCognition/软件企业证书.jpg'),
			name:'软件企业证书'
	  	},
	  ],
	  // 软件著作权
	  softwareCopyrightList:[
	  	{
	  		img:require('../../assets/image/enterpriseQualification/softwareCopyright/PEM动力环境云监控系统.jpg'),
			name:'动力环境云监控系统'
	  	},
	  	{
	  		img:require('../../assets/image/enterpriseQualification/softwareCopyright/SDWAN嵌入式终端CPE在线适配系统.jpg'),
			name:'SDWAN嵌入式终端CPE在线适配系统'
	  	},
	  	{
	  		img:require('../../assets/image/enterpriseQualification/softwareCopyright/SDWAN嵌入式终端驱动管理系统.jpg'),
			name:'SDWAN嵌入式终端驱动管理系统'
	  	},
	  	{
	  		img:require('../../assets/image/enterpriseQualification/softwareCopyright/安全管理视频联动管理系统.jpg'),
			name:'安全管理视频联动管理系统'
	  	},
	  	{
	  		img:require('../../assets/image/enterpriseQualification/softwareCopyright/电气火灾安全监控系统.jpg'),
			name:'电气火灾安全监控系统'
	  	},
	  	{
	  		img:require('../../assets/image/enterpriseQualification/softwareCopyright/黄河滩区迁建管理系统.jpg'),
			name:'黄河滩区迁建管理系统'
	  	},
	  ],
	// 发明专利
	inventionPatentList:[
		{
			img:require('../../assets/image/enterpriseQualification/inventionPatent/多路直流电表.jpg'),
			name:'多路直流电表'
		},
		{
			img:require('../../assets/image/enterpriseQualification/inventionPatent/物联网智能数据采集终端.jpg'),
			name:'物联网智能数据采集终端'
		},
		{
			img:require('../../assets/image/enterpriseQualification/inventionPatent/巡更器专利证书.jpg'),
			name:'巡更器专利证书'
		},
		{
			img:require('../../assets/image/enterpriseQualification/inventionPatent/一种具有现场决策的智能动态巡更系统.jpg'),
			name:'一种具有现场决策的智能动态巡更系统'
		},
		{
			img:require('../../assets/image/enterpriseQualification/inventionPatent/智能电源监控器.jpg'),
			name:'智能电源监控器'
		},
		{
			img:require('../../assets/image/enterpriseQualification/inventionPatent/智能温湿度传送器.jpg'),
			name:'智能温湿度传送器'
		},
	]
    }
  },
  created(){
    
  },
  mounted(){
    this.wow = new WOW({
      boxClass: 'wow', // 添加动画的元素的标识
      animateClass: 'animate__animated', // 动画时长
      offset: 0, // 与可视区域底部的距离显示
      mobile: true, // 移动端是否可用
      live: true // 对后面添加的新元素是否有效
    }).init();
	if(this.$route.query.anchorPoint){
		this.anchor(this.$route.query.anchorPoint)
	}
  window.addEventListener('scroll', () => {
  	  // 页面滚动停止100毫秒后才会执行下面的函数。
  	  this.scrollToTop();
  	} , true);
  },
  destroy(){
  	  window.removeEventListener('scroll', this.scrollToTop)
  },
  watch: {
  	$route:{
  	  handler(val,oldval){
  	    // console.log(val);//新路由信息
  	    // console.log(oldval);//老路由信息
		this.anchor(val.query.anchorPoint)
  	  },
  	  // 深度观察监听
  	  deep: true
  	}
  
  },
  methods: {
    isProductNav(name){
      this.activeProductNav = name
    },
	/*锚链接跳转*/
	 anchor(anchorName) {
	   /*找到锚点*/
	   let anchorElement = document.getElementById(anchorName);
	   /*如果对应id的锚点存在，就跳转到锚点*/
	   if(anchorElement) {
	     anchorElement.scrollIntoView();
	   }
	 },
	 scrollToTop(){
	 		  if(!document.getElementById('enterpriseQualificationTop')){
	 		  	return
	 		  }
	 		  var offsetTop = document.getElementById('enterpriseQualificationTop').getBoundingClientRect().top;
	 		  console.log(document.getElementsByTagName('a'))
	 		  if(window.pageYOffset>=492){
	 			  document.getElementById('enterpriseQualificationTop').style.position = 'fixed'
	 			  document.getElementById('enterpriseQualificationTop').style.top = '67px'
	 			  document.getElementById('enterpriseQualificationTop').style.backgroundColor = '#fff'
	 			  document.getElementById('enterpriseQualificationTop').style.zIndex = '1'
	 			  document.getElementById('industryCognition').style.top = '-140px'
	 			  document.getElementById('softwareCopyright').style.top = '-140px'
	 			  document.getElementById('inventionPatent').style.top = '-140px'
	 		  }else{
	 			  document.getElementById('enterpriseQualificationTop').style.position = 'static'
				  document.getElementById('industryCognition').style.top = '-80px'
				  document.getElementById('softwareCopyright').style.top = '-80px'
				  document.getElementById('inventionPatent').style.top = '-80px'
	 			  // document.getElementById('culturalRelicsTop').style.top = '75px'
	 		  }
	 		  // console.log(offsetTop,window.pageYOffset)
	 }
  }
}
</script>

<style lang="scss">
.enterpriseQualificationContainer {
.header{
		width: 1903px;
		img{
			width: 100%;
		}
	}
  margin: 0 auto;
  .enterpriseQualificationTop{
	  width: 100%;
	  border-bottom: 1px solid #C2C2C2;
	  .enterpriseQualification_body {
		  margin: 0 auto;
		  width: 1200px;
		  padding: 0 367px;
	    .nav {
	      display: flex;
	      justify-content: space-between;
	      flex-wrap: wrap;
	      font-size: 22px;
	      color: #333333;
	      .nav_item {
			.enterpriseQualificationItemName{
				display: inline-block;
				text-decoration: none;
				margin: 22px 0 22px 0;
				color: #333333;
			}
	        cursor: pointer;
	        &:hover {
	          color: #0155C4;
	          &::after {
	            width: 100%;
	          }
	        }
	      }
	      .nav_item::after {
	        content: '';
	        display: block;
	        margin: -2px auto 0;
	        height: 2px;
	        width: 0%;
	        background-color: #0155C4;
	        border-radius: 2px;
	        transition: all .3s;
	      }
	      .activeProductNav {
			  .enterpriseQualificationItemName{
			  	 color: #0155C4;
			  }
			  
	        &::after {
	          width: 100%;
	        }
	      }
	    }
	  
	  }
  }

  .enterpriseQualificationBottom{
	  padding-bottom: 14px;
	  background-color: #F5F6F6;
	  .enterpriseQualification_head {
		  margin-bottom: 42px;
	    .title {
	      margin: 0 0 15px 0;
	      font-size: 40px;
	      color: #333;
	  	  padding-top: 70px;  
	    }
	    .modifier {
	      font-size: 24px;
	      color: #666;
	    }
	  }
	  .industryCognition{
		  width: 1200px;
		  margin: 0 auto;
		  display: flex;
		  justify-content: space-between;
		  flex-wrap: wrap;
		  .industryCognitionItem{
			background-color: #fff;
			  width: 376px;
			  .image{
				  background: linear-gradient(148deg, #BA976B 0%, #EBE1D5 52%, #BA976E 100%);
				  height: 267px;
				  img{
					  // width: 100%;
					  height: 267px;
				  }
			  }
			  .imageName{
				  color: #333333;
				  font-size: 19px;
				  text-align: center;
				  line-height: 74px;
			  }
			  transition: all .3s;
			  margin-bottom: 46px;
			  &:hover {
			    transform: translateY(-5px);
			  }
		  }
	  }
  }
}
@media only screen and (max-width: 1200px) {
	.enterpriseQualificationContainer{
		width: 1200px;
		.header{
			width: 1200px;
		}
		.enterpriseQualification{
			width: 100%;
		}
	}
}
</style>